import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getTipsApi,
   getLearnLangaugesApi,
   getActiveTripsApi,
   getTripsApi,
   planTripApi,
   deleteTripApi,
   updateTripApi,
   getEmergencyApi,
   addSpendingApi,
   getSpendingIDApi,
   getSpendingCategoryApi,
   getSpendingsApi,
   updateSpendingApi,
   addSpendingAmountApi,
   removeSpendingApi,
} from "../api/trips.api";
import toast from "react-hot-toast";

const initialState = {
   loading: false,
   tipsList: {},
   learLangaugesList: {},
   userActiveTripsList: {},
   userTrip: {},
   planTripRes: {},
   planTripLoading: false,
   updateTripRes: {},
   updateTripLoading: false,
   emergencyData: {},
   spendingRes: {},
   spendingId: {},
   spendingCategoryList: {},
   addSpendLoading: false,
   updateSpendLoading: false,
   spendingsList: {},
   updateSpendingRes: {},
   updateSpendingAmountRes: {},
   addSpendingAmountLoading: false,
   removeSpendingLoading: false,
   deletetripLoading: false,
   removeSpendingRes: {},
   deleteTripRes: {},
   error: null,
};

export const getTips = createAsyncThunk("get_tips", async (countryId) => {
   try {
      const res = await getTipsApi(countryId);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const addSpending = createAsyncThunk("add_spending", async (data) => {
   try {
      const res = await addSpendingApi(data);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const updateSpending = createAsyncThunk(
   "update_spending",
   async (data) => {
      try {
         const res = await updateSpendingApi(data);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getSpendingCategory = createAsyncThunk(
   "get_category",
   async (id) => {
      try {
         const res = await getSpendingCategoryApi(id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getSpendingId = createAsyncThunk("get_spending_id", async (id) => {
   try {
      const res = await getSpendingIDApi(id);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getSpendings = createAsyncThunk("get_spendings", async (id) => {
   try {
      const res = await getSpendingsApi(id);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const updateTripDate = createAsyncThunk(
   "update_trip_date",
   async (data) => {
      try {
         const res = await updateTripApi(data);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const addSpendingAmount = createAsyncThunk(
   "add-spending-amount",
   async (data) => {
      try {
         const res = await addSpendingAmountApi(data);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const removeSpending = createAsyncThunk(
   "remove-spending",
   async (id) => {
      try {
         const res = await removeSpendingApi(id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getLearnLangauges = createAsyncThunk(
   "get_learn_langauges",
   async (countryId) => {
      try {
         const res = await getLearnLangaugesApi(countryId);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getEmergency = createAsyncThunk(
   "get_emergency",
   async (countryId) => {
      try {
         const res = await getEmergencyApi(countryId);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getTrip = createAsyncThunk("get_trips", async (trip_id) => {
   try {
      const res = await getTripsApi(trip_id);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getActiveTrips = createAsyncThunk("get_active_trips", async () => {
   try {
      const res = await getActiveTripsApi();
      return res;
   } catch (err) {
      return err.response;
   }
});

export const deleteTrip = createAsyncThunk("delete_trip", async (id) => {
   try {
      const res = await deleteTripApi(id);
      toast.success(res?.data?.message);
      return id;
   } catch (err) {
      return err.response;
   }
});

export const planTrip = createAsyncThunk("plan_trip", async (data) => {
   try {
      const res = await planTripApi(data);
      return res;
   } catch (err) {
      return err.response;
   }
});

const tripSlices = createSlice({
   name: "trips",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getTips.pending, (state) => {
         state.loading = true;
         state.tipsList = {};
         state.error = null;
      });
      builder.addCase(getTips.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.tipsList = payload;
         state.error = null;
      });
      builder.addCase(getTips.rejected, (state, { payload }) => {
         state.loading = false;
         state.tipsList = {};
         state.error = payload;
      });

      builder.addCase(getEmergency.pending, (state) => {
         state.loading = true;
         state.emergencyData = {};
         state.error = null;
      });
      builder.addCase(getEmergency.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.emergencyData = payload;
         state.error = null;
      });
      builder.addCase(getEmergency.rejected, (state, { payload }) => {
         state.loading = false;
         state.emergencyData = {};
         state.error = payload;
      });

      builder.addCase(getLearnLangauges.pending, (state) => {
         state.loading = true;
         state.learLangaugesList = {};
         state.error = null;
      });
      builder.addCase(getLearnLangauges.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.learLangaugesList = payload;
         state.error = null;
      });
      builder.addCase(getLearnLangauges.rejected, (state, { payload }) => {
         state.loading = false;
         state.learLangaugesList = {};
         state.error = payload;
      });

      builder.addCase(getTrip.pending, (state) => {
         state.loading = true;
         state.userTrip = {};
         state.error = null;
      });
      builder.addCase(getTrip.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userTrip = payload;
         state.error = null;
      });
      builder.addCase(getTrip.rejected, (state, { payload }) => {
         state.loading = false;
         state.userTrip = {};
         state.error = payload;
      });

      builder.addCase(getActiveTrips.pending, (state) => {
         state.loading = true;
         state.userActiveTripsList = {};
         state.error = null;
      });
      builder.addCase(getActiveTrips.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userActiveTripsList = payload;
         state.error = null;
      });
      builder.addCase(getActiveTrips.rejected, (state, { payload }) => {
         state.loading = false;
         state.userActiveTripsList = {};
         state.error = payload;
      });

      builder.addCase(planTrip.pending, (state) => {
         state.planTripLoading = true;
         state.planTripRes = {};
         state.error = null;
      });
      builder.addCase(planTrip.fulfilled, (state, { payload }) => {
         state.planTripLoading = false;
         state.planTripRes = payload;
         state.error = null;
      });
      builder.addCase(planTrip.rejected, (state, { payload }) => {
         state.planTripLoading = false;
         state.planTripRes = {};
         state.error = payload;
      });

      builder.addCase(updateTripDate.pending, (state) => {
         state.updateTripLoading = true;
         state.updateTripRes = {};
         state.error = null;
      });
      builder.addCase(updateTripDate.fulfilled, (state, { payload }) => {
         state.updateTripLoading = false;
         state.updateTripRes = payload;
         state.error = null;
      });
      builder.addCase(updateTripDate.rejected, (state, { payload }) => {
         state.updateTripLoading = false;
         state.updateTripRes = {};
         state.error = payload;
      });

      builder.addCase(addSpending.pending, (state) => {
         state.addSpendLoading = true;
         state.spendingRes = {};
         state.error = null;
      });
      builder.addCase(addSpending.fulfilled, (state, { payload }) => {
         state.addSpendLoading = false;
         state.spendingRes = payload;
         state.error = null;
      });
      builder.addCase(addSpending.rejected, (state, { payload }) => {
         state.addSpendLoading = false;
         state.spendingRes = {};
         state.error = payload;
      });

      builder.addCase(removeSpending.pending, (state) => {
         state.removeSpendingLoading = true;
         state.removeSpendingRes = {};
         state.error = null;
      });
      builder.addCase(removeSpending.fulfilled, (state, { payload }) => {
         state.removeSpendingLoading = false;
         state.removeSpendingRes = payload;
         state.error = null;
      });
      builder.addCase(removeSpending.rejected, (state, { payload }) => {
         state.removeSpendingLoading = false;
         state.removeSpendingRes = {};
         state.error = payload;
      });

      builder.addCase(getSpendingId.pending, (state) => {
         state.addSpendLoading = true;
         state.spendingId = {};
         state.error = null;
      });
      builder.addCase(getSpendingId.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.spendingId = payload;
         state.error = null;
      });
      builder.addCase(getSpendingId.rejected, (state, { payload }) => {
         state.loading = false;
         state.spendingId = {};
         state.error = payload;
      });

      builder.addCase(getSpendingCategory.pending, (state) => {
         state.loading = true;
         state.spendingCategoryList = {};
         state.error = null;
      });
      builder.addCase(getSpendingCategory.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.spendingCategoryList = payload;
         state.error = null;
      });
      builder.addCase(getSpendingCategory.rejected, (state, { payload }) => {
         state.loading = false;
         state.spendingCategoryList = {};
         state.error = payload;
      });

      builder.addCase(getSpendings.pending, (state) => {
         state.loading = true;
         state.spendingsList = {};
         state.error = null;
      });
      builder.addCase(getSpendings.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.spendingsList = payload;
         state.error = null;
      });
      builder.addCase(getSpendings.rejected, (state, { payload }) => {
         state.loading = false;
         state.spendingsList = {};
         state.error = payload;
      });

      builder.addCase(updateSpending.pending, (state) => {
         state.updateSpendLoading = true;
         state.updateSpendingRes = {};
         state.error = null;
      });
      builder.addCase(updateSpending.fulfilled, (state, { payload }) => {
         state.updateSpendLoading = false;
         state.updateSpendingRes = payload;
         state.error = null;
      });
      builder.addCase(updateSpending.rejected, (state, { payload }) => {
         state.updateSpendLoading = false;
         state.updateSpendingRes = {};
         state.error = payload;
      });

      builder.addCase(addSpendingAmount.pending, (state) => {
         state.addSpendingAmountLoading = true;
         state.updateSpendingAmountRes = {};
         state.error = null;
      });
      builder.addCase(addSpendingAmount.fulfilled, (state, { payload }) => {
         state.addSpendingAmountLoading = false;
         state.updateSpendingAmountRes = payload;
         state.error = null;
      });
      builder.addCase(addSpendingAmount.rejected, (state, { payload }) => {
         state.addSpendingAmountLoading = false;
         state.updateSpendingAmountRes = {};
         state.error = payload;
      });

      builder.addCase(deleteTrip.pending, (state) => {
         state.deletetripLoading = true;
         state.deleteTripRes = {};
         state.error = null;
      });
      builder.addCase(deleteTrip.fulfilled, (state, { payload }) => {
         state.deletetripLoading = false;
         state.userActiveTripsList.data.data =
            state.userActiveTripsList.data.data.filter(
               (trip) => trip.id !== payload
            );
         state.error = null;
      });

      builder.addCase(deleteTrip.rejected, (state, { payload }) => {
         state.deletetripLoading = false;
         state.deleteTripRes = {};
         state.error = payload;
      });
   },
});

const { reducer } = tripSlices;
export default reducer;
