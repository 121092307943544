import { Modal } from "react-bootstrap";

export default function DeleteConfirmationModal({
   show,
   setShow,
   type,
   data,
   loading,
   setDeleteData,
   yesOnClick,
}) {
   return (
      <Modal
         show={show}
         onHide={() => {
            setShow(false);
            setDeleteData(null);
         }}
         keyboard={false}
         centered
         className="select-trip-modal delete-confirmation-modal"
      >
         <Modal.Body className="p-select-content p-0 gap-4 d-flex align-items-center justify-content-center flex-column">
            <hr className="modal-top-hr m-0" />
            <h3 className="m-0 py-3 w-75 fw-semibold">
               Are you sure want to delete this {type}?
            </h3>
            {type === "trip" && (
               <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                  <img src={data?.country?.flag} alt="" />
                  <h3 className="m-0 text-center fw-semibold">
                     Trip to {data?.country?.name}
                  </h3>
               </div>
            )}
            {(type === "bookmark" || type === "itinerary") && (
               <p className="fw-semibold">
                  {data?.name_of_location
                     ? data?.name_of_location
                     : data?.location?.name_of_location}
               </p>
            )}
            <div className="d-flex align-items-center justify-content-center gap-5">
               <button
                  className="no-btn py-2 px-3 rounded-5 border-0 text-primary fw-semibold"
                  onClick={() => {
                     setDeleteData(null);
                     setShow(false);
                  }}
               >
                  No
               </button>
               <button
                  className="yes-btn py-2 px-3 rounded-5 fw-semibold"
                  onClick={() => yesOnClick(data?.id)}
               >
                  Yes
                  {loading && (
                     <div
                        className="spinner-border ms-2 spinner-border-sm text-light"
                        role="status"
                     />
                  )}
               </button>
            </div>
         </Modal.Body>
      </Modal>
   );
}
