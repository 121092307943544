/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Icon from "../assets/Icons";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { MobileTopHeader } from "../components";

const _PrivacyPolicy = ({ privacyTitle, children }) => {
   return (
      <div className="container">
         <div className="rounded-3 box-shadow mb-3 p-3">
            <p className="privacy-title">{privacyTitle}</p>
            <p className="m-0 privacy-content text-muted">{children}</p>
         </div>
      </div>
   );
};

export default function PrivacyPolicy() {
   const params = useParams();

   const location = useLocation();

   const [activeTab, setActiveTab] = useState(params?.legal);

   const navigate = useNavigate();

   useEffect(() => {
      const path = location.pathname.slice(1);
      setActiveTab(path);
   }, [location]);

   return (
      <div>
         <MobileTopHeader title={"Legal"} />
         <Tabs
            activeKey={activeTab}
            onSelect={(eventKey) => {
               navigate(`/${eventKey}`);
               setActiveTab(eventKey);
            }}
            style={{
               backgroundColor: "rgb(250, 248, 255)",
            }}
            className="container-fluid activity-header-legal position-fixed d-flex align-items-center gap-3 justify-content-center mb-3 box-shadow border-0"
         >
            <Tab
               eventKey="privacy-policy"
               className="text-dark privacy-policy"
               title="Privacy Policy"
               style={{
                  paddingTop: "75px",
                  backgroundColor: "rgb(250, 248, 255)",
               }}
            >
               <_PrivacyPolicy privacyTitle={"Introduction"}>
                  Welcome to Trundle Technology LLP ("We", "Us", "Our"). We
                  respect your privacy and want to protect your personal
                  information. This Privacy Policy explains how we collect,
                  store, and use your personal information when you use our
                  website (
                  <a target="blank" href="https://trundle.world">
                     https://trundle.world
                  </a>
                  ) and our mobile application available on Google Play Store
                  and Apply Store collectively referred to as "Services". Please
                  read this Privacy Policy and understand it before using our
                  Services.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Information We Collect"}>
                  We may collect the following types of information:
                  <ul className="privacy-list">
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Personal Identification Information: Name, email
                        address, phone number, and address.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Travel Information: Your travel preferences, booking
                        details, passport details, dietary requirements and any
                        other information necessary for fulfilling your travel
                        arrangements.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Payment Information: Credit/debit card details and
                        billing address.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Technical Data: Information about your device, IP
                        address, operating system, and browser type.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Usage Data: Information about how you use our Services.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Marketing and Communications Data: Your preferences in
                        receiving marketing from us and your communication
                        preferences.
                     </li>
                  </ul>
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"How We Use Your Information"}>
                  We use the information we collect from you in various ways,
                  including:{" "}
                  <ul className="privacy-list">
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        To provide and manage your travel bookings.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        To process Transactions.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        To personalize your experience and to allow us to
                        deliver the type of content and product offerings in
                        which you are most interested.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        To send periodic emails regarding your travel plans or
                        other products and services.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        To follow up with you after correspondence (live chat,
                        email or phone inquiries).
                     </li>
                  </ul>
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Share your Information"}>
                  <p className="m-0 privacy-content text-muted pb-3">
                     We do not sell, trade, or otherwise transfer to outside
                     parties your Personal Identification Information unless we
                     provide users with advance notice. This does not include
                     website hosting partners and other parties who assist us in
                     operating our Services, conducting our business, or serving
                     our users, so long as those parties agree to keep this
                     information confidential.
                  </p>
                  <p className="m-0 privacy-content text-muted">
                     We may also release information when its release is
                     appropriate to comply with the law, enforce our site
                     policies, or protect ours or others' rights, property or
                     safety.
                  </p>
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Data Security"}>
                  We have implemented appropriate security measures to prevent
                  your personal data from being accidentally lost, used or
                  accessed in an unauthorized way, altered or disclosed. We
                  limit access to your personal data to those employees, agents,
                  contractors and other third parties who have a business need
                  to know
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Cookies"}>
                  Our Services use 'Cookies' to identify the areas of our
                  Services that you have visited. We use Cookies to personalize
                  the Content that you see on our Services. Most web browsers
                  can be set to disable the use of Cookies.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Your Rights"}>
                  You are entitled to request details of the information we
                  store about you and how we process it. You also have the right
                  to withdraw your consent at any time, ask us to rectify or
                  delete your information, or oppose the processing of your
                  personal data under certain circumstances.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Changes To This Privacy Policy"}>
                  We may update our Privacy Policy from time to time. Thus, you
                  are advised to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page. These changes are effective immediately
                  after they are posted on this page.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Contact Us"}>
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us at care@trundle.world.
               </_PrivacyPolicy>
            </Tab>
            <Tab
               eventKey="terms-conditions"
               title="Terms & Conditions"
               className={
                  activeTab === "activities/places"
                     ? "active-tab privacy-policy"
                     : "privacy-policy"
               }
               style={{
                  paddingTop: "75px",
                  backgroundColor: "rgb(250, 248, 255)",
               }}
            >
               <_PrivacyPolicy privacyTitle={"Introduction"}>
                  Welcome to Trundle Technology LLP ("Company", "We", "Us",
                  "Our"). These Terms and Conditions outline the rules and
                  regulations for the use of our Website and our Application,
                  collectively referred to as "Services". By using our Services,
                  you accept these terms and conditions in full. Do not continue
                  to use our Services if you do not accept all of the terms and
                  conditions stated on this page.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Privacy & Data Protection"}>
                  Your privacy is important to us. Please review our Privacy
                  Policy, which also governs your use of our Services, to
                  understand our practices. By using our Services, you consent
                  to the collection, use, and transfer of your information as
                  outlined therein.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Bookings & Payment"}>
                  When you make a booking through our Services, you confirm that
                  you accept these terms and conditions and that you have the
                  authority to accept these terms on behalf of yourself and all
                  members of your party. Full payment for any goods or services
                  ordered through our Services must be made at the time of
                  booking. You agree that all the information given to us is
                  true and accurate.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Cancellation & Refunds"}>
                  Cancellations and refunds are subject to the specific terms
                  and conditions of the travel service providers. It is your
                  responsibility to read and understand those terms before
                  making a booking.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={" Intellectual Property Rights"}>
                  Unless otherwise stated, we own the intellectual property
                  rights for all material on our Services. All intellectual
                  property rights are reserved.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Restrictions"}>
                  Users are specifically restricted from all of the following:{" "}
                  <ul className="privacy-list">
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Publishing any material from our Services in any other
                        media without our prior written consent.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Selling, sublicensing, and/or commercializing any
                        material from our Services.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Payment Information: Credit/debit card details and
                        billing address.
                     </li>
                     <li>
                        <Icon className="me-2 mt-2" name={"Vector"} />
                        Engaging in any data mining, data harvesting, data
                        extracting, or any other similar activity in relation to
                        our Services
                     </li>
                  </ul>
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Limitation of Liability"}>
                  You agree to indemnify and hold harmless us and our officers,
                  directors, employees, and agents, from and against any claims,
                  disputes, demands, liabilities, damages, losses, and costs and
                  expenses, including, without limitation, reasonable legal and
                  accounting fees, arising out of or in any way connected with
                  your access to or use of our Services or your violation of
                  these Terms.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Indemnification"}>
                  You agree to indemnify and hold harmless us and our officers,
                  directors, employees, and agents, from and against any claims,
                  disputes, demands, liabilities, damages, losses, and costs and
                  expenses, including, without limitation, reasonable legal and
                  accounting fees, arising out of or in any way connected with
                  your access to or use of our Services or your violation of
                  these Terms.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={" Changes to these Terms"}>
                  We reserve the right to amend these terms and conditions from
                  time to time by posting an updated version on our Services.
                  The updated version will take effect as soon as it is posted.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Governing Law & Jurisdiction"}>
                  These Terms will be governed by and interpreted in accordance
                  with the laws of the jurisdiction in which our Company is
                  registered, and you submit to the non-exclusive jurisdiction
                  of the state and federal courts located in our jurisdiction
                  for the resolution of any disputes.
               </_PrivacyPolicy>
               <_PrivacyPolicy privacyTitle={"Contact Us"}>
                  If you have any questions about these Terms, please contact us
                  at <a href="mailto:care@trundle.world">care@trundle.world</a>.
               </_PrivacyPolicy>
            </Tab>
         </Tabs>
         <div className="container mb-3">
            <p className="last-update-text">Last updated: June 13, 2023</p>
         </div>
      </div>
   );
}
