import moment from "moment";

export const getReminDays = (departureDate, returnDate) => {
   const departureMonth = moment(departureDate).format("MMM");
   const departureDay = moment(departureDate).format("D");
   const returnMonth = moment(returnDate).format("MMM");
   const returnDay = moment(returnDate).format("D");

   var formattedDate;
   if (departureMonth === returnMonth) {
      formattedDate = `${departureMonth} ${departureDay} - ${returnDay}`;
   } else {
      formattedDate = `${departureMonth} ${departureDay} - ${returnMonth} ${returnDay}`;
   }
   return formattedDate;
};
