import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import localforage from "localforage";

const persistConfig = {
   key: "root",
   storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
   reducer: persistedReducer,
   devTools: true,
   middleware: [thunk],
});

export const persistor = persistStore(store);
