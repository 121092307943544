import { Form, Modal } from "react-bootstrap";

export default function TripTypeModal({
   tripTypeModalShow,
   setTripTypeModalShow,
   setTripType,
   tripType,
   setYourLocationShow,
   setTripsModalShow,
   availableTrips,
   selectedCountryId,
   setIsItenerary,
}) {
   const isExistTrip = availableTrips?.data?.data?.some(
      (data) => data?.country.id == selectedCountryId
   );

   return (
      <Modal
         show={tripTypeModalShow}
         onHide={() => {
            setTripTypeModalShow(false);
            setIsItenerary(false);
         }}
         keyboard={false}
         centered
         className="select-trip-modal"
      >
         <Modal.Body className="p-select-content p-0">
            <h3 className="m-0 fw-semibold py-3">Please select</h3>
            <Form className="mx-4">
               <div
                  className={`${
                     tripType === "new_trip"
                        ? "active-m-content d-flex py-2 my-3 align-items-center justify-content-center px-2 cursor-pointer"
                        : "py-2 my-3 d-flex align-items-center justify-content-center px-2 cursor-pointer"
                  }`}
               >
                  <input
                     type="radio"
                     className="mb-2"
                     name="radio-button"
                     id="new_trip"
                     checked={tripType === "new_trip"}
                     onChange={(event) => {
                        setTripType(event?.target?.id);
                     }}
                  />
                  <label
                     htmlFor="new_trip"
                     className="b-c-content ms-3 cursor-pointer"
                  >
                     <p className="start-p-content">Start a new trip</p>
                     <p className="build-p-content cursor-pointer">
                        Build your itinerary from scratch
                     </p>
                  </label>
               </div>
               {isExistTrip && (
                  <div
                     className={`${
                        tripType === "existing_trip"
                           ? "active-m-content py-2 d-flex align-items-center justify-content-center px-2 cursor-pointer"
                           : "py-2 d-flex align-items-center justify-content-center mx-2 cursor-pointer"
                     }`}
                  >
                     <input
                        type="radio"
                        className={`mb-2 ${!isExistTrip && "cursor-none"}`}
                        name="radio-button"
                        id="existing_trip"
                        checked={tripType === "existing_trip"}
                        onChange={(event) => {
                           setTripType(event?.target?.id);
                        }}
                        disabled={!isExistTrip}
                     />
                     <label
                        htmlFor="existing_trip"
                        className="b-c-content ms-3"
                     >
                        <p
                           className={`start-p-content cursor-pointer ${
                              !isExistTrip && "text-muted cursor-pointer"
                           }`}
                        >
                           Add to existing trip
                        </p>
                        <p className="build-p-content cursor-pointer">
                           Add this item to your existing trips
                        </p>
                     </label>
                  </div>
               )}
            </Form>
         </Modal.Body>
         <Modal.Footer className="border-0 add-itinerary-button w-100">
            <button
               onClick={() => {
                  if (tripType === "new_trip") {
                     setTripTypeModalShow(false);
                     setYourLocationShow(true);
                  } else if (tripType === "existing_trip") {
                     setTripsModalShow(true);
                     setTripTypeModalShow(false);
                  }
               }}
            >
               Confirm
            </button>
         </Modal.Footer>
      </Modal>
   );
}
