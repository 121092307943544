import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import alert from "../../assets/images/icon/alert.png";
import { loginUser, signupUser } from "../../redux/slices/authSlices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";

function Login() {
   const dispatch = useDispatch();

   const navigate = useNavigate();

   const location = useLocation();

   const { otpLoading } = useSelector((state) => state.userData);

   const initialValues = {
      email: "",
   };

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email address")
         .required("Email is required"),
   });

   const handleSubmit = async (values) => {
      if (location?.pathname === "/login") {
         const response = await dispatch(loginUser(values));
         if (response?.payload?.data?.status) {
            navigate("/otp-verification", { state: { email: values?.email } });
         }
      } else {
         const response = await dispatch(signupUser(values));
         if (response?.payload?.data?.status) {
            navigate("/otp-verification", { state: { email: values?.email } });
         }
      }
   };

   return (
      <div>
         <div className="container-fluid trundle-header d-md-block d-none box-shadow">
            <div className="container">
               <div className="d-flex row align-items-center justify-content-between py-3">
                  <div
                     className="ellipse-image d-flex col-md-6 col-12  align-items-center justify-content-md-start justify-content-center"
                     onClick={() => navigate("/")}
                  >
                     <a className="text-decoration-none d-flex gap-2 align-items-center justify-content-center cursor-pointer">
                        <img src={logo} alt="" srcSet="" />
                        <p className="logotext m-0">trundle</p>
                     </a>
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-end">
                     <div>
                        {location?.pathname === "/login" ? (
                           <ul className="d-flex gap-2 m-0 align-items-center justify-content-center">
                              <li className="list-group">
                                 <p className="dont-account text-muted m-0 me-2">
                                    Don’t have an account?
                                 </p>
                              </li>
                              <li className="list-group">
                                 <button
                                    className="p-button"
                                    onClick={() => navigate("/sign-up")}
                                 >
                                    <a className="text-decoration-none fw-bold">
                                       Sign Up
                                    </a>
                                 </button>
                              </li>
                           </ul>
                        ) : (
                           <ul className="d-flex gap-2 m-0 align-items-center justify-content-center">
                              <li className="list-group">
                                 <p className="dont-account text-muted m-0 me-2">
                                    Already have an account?
                                 </p>
                              </li>
                              <li className="list-group">
                                 <button
                                    className="p-button"
                                    onClick={() => navigate("/login")}
                                 >
                                    <a className="text-decoration-none fw-bolder">
                                       Sign In
                                    </a>
                                 </button>
                              </li>
                           </ul>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="login-form-container">
            <div
               className="ellipse-image mt-3 d-md-none d-block"
               onClick={() => navigate("/")}
            >
               <a className="text-decoration-none d-flex gap-2 align-items-center justify-content-center cursor-pointer">
                  <img src={logo} alt="" srcSet="" />
                  <p className="logotext m-0">trundle</p>
               </a>
            </div>
            <div className="otp-v-header main box-shadow my-5">
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
               >
                  {({ isSubmitting }) => (
                     <Form>
                        <div className="otp-v-container">
                           <div className="otp-v-content text-md-start text-center px-sm-2">
                              {location?.pathname === "/login" ? (
                                 <h3>Sign In</h3>
                              ) : (
                                 <h3>Welcome</h3>
                              )}
                              <p className="mx-sm-2">
                                 Please enter your email address to proceed
                                 further
                              </p>
                           </div>
                           <div className="otp-input-header">
                              <div className="form-group login-form my-4">
                                 <div className="d-flex flex-column login-input">
                                    <label htmlFor="email">Email*</label>
                                    <Field
                                       className="bg-transparent form-control p-0"
                                       type="text"
                                       name="email"
                                       id="email"
                                       placeholder="Type your email address"
                                    />
                                 </div>
                                 <div className="m-2 ms-3 error-message">
                                    <ErrorMessage
                                       name="email"
                                       component="div"
                                       className="text-danger"
                                    />
                                 </div>
                                 {location?.pathname === "/sign-up" ? (
                                    <div className="dont-account text-muted m-0 me-2 my-3 ms-3">
                                       <p className="m-0">
                                          Already have an account?{" "}
                                          <span
                                             className="text-primary cursor-pointer"
                                             onClick={() => navigate("/login")}
                                          >
                                             Sign in
                                          </span>
                                       </p>
                                    </div>
                                 ) : (
                                    <div className="dont-account text-muted m-0 me-2 my-3 ms-3">
                                       <p className="m-0">
                                          Don’t have an account?{" "}
                                          <span
                                             className="text-primary cursor-pointer"
                                             onClick={() =>
                                                navigate("/sign-up")
                                             }
                                          >
                                             Sign up
                                          </span>
                                       </p>
                                    </div>
                                 )}
                              </div>
                           </div>
                           <div className="alert-container">
                              <div className="alert-content">
                                 <img src={alert} alt="" />
                                 <p className="mb-0">
                                    We’ll send you a verification code to your
                                    email address.
                                 </p>
                              </div>

                              <button className="sub-al-btn" type="submit">
                                 {otpLoading ? (
                                    <div>
                                       <span
                                          className="spinner-border spinner-border-sm me-2"
                                          role="status"
                                          aria-hidden="true"
                                       ></span>
                                       Loading...
                                    </div>
                                 ) : (
                                    "Send Code"
                                 )}
                              </button>
                           </div>
                        </div>
                     </Form>
                  )}
               </Formik>
            </div>
         </div>
      </div>
   );
}

export default Login;
