import Modal from "react-bootstrap/Modal";
import locationBg from "../assets/images/background/Rectangle 243.png";
import bookMarkIcon from "../assets/images/logo/bookmark.svg";
import noImage from "../assets/images/icon/no-image.png";
import { useDispatch } from "react-redux";
import { addBookmark } from "../redux/slices/bookmarSlices";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useAuth from "../constants/useAuth";
import Icon from "../assets/Icons";
import {
   updateIsBookmarkHome,
   updateIsBookmarkPlace,
} from "../redux/slices/placesSlices";
import { updateIsBookmarkFoodDrink } from "../redux/slices/foodAndDrinksSlices";
import {
   addItenery,
   getItenerys,
   updateIsBookmarkTrip,
} from "../redux/slices/itenerySlices";
import { useState } from "react";

function ProductDetails({
   productDetail,
   setProductDetailShow,
   prooductDetailShow,
   setTripTypeModalShow,
   isSavedItenerary,
   isBookmark,
   setIsItenerary,
   isFrom,
   haveTripDetails,
}) {
   const [authData] = useAuth();

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const [loading, setLoading] = useState(false);

   const addToItenery = async () => {
      setLoading(true);
      const response = await dispatch(getItenerys(haveTripDetails?.id));
      if (response?.payload?.status === 200) {
         const popupDataId = productDetail?.id;
         const previousTripIteneraries = response?.payload?.data?.data;
         const isIteneraryPresent = previousTripIteneraries?.some(
            (itinerary) => itinerary?.location?.id === popupDataId
         );
         if (isIteneraryPresent) {
            setLoading(false);
            setProductDetailShow(false);
            toast.error("This location is already present in itineraries");
         } else {
            const data = {
               tripId: haveTripDetails?.id,
               locationId: popupDataId,
            };
            const _addItenery = async () => {
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  setLoading(false);
                  navigate(`/trip-detail/${haveTripDetails?.id}`);
                  setProductDetailShow(false);
                  toast.success(response_?.payload?.data?.message);
                  // setCategory(null);
               }
            };
            _addItenery();
         }
      }
   };

   const bookMarkOnClick = async () => {
      const response = await dispatch(addBookmark(productDetail?.id));
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         setProductDetailShow(false);
         if (isFrom === "home") {
            dispatch(
               updateIsBookmarkHome({
                  location_id: productDetail?.id,
                  category: productDetail?.category,
               })
            );
         } else if (isFrom === "activity") {
            if (productDetail?.category === 1) {
               dispatch(
                  updateIsBookmarkPlace({
                     location_id: productDetail?.id,
                     category: productDetail?.category,
                  })
               );
            } else if (productDetail?.category === 2) {
               dispatch(
                  updateIsBookmarkFoodDrink({
                     location_id: productDetail?.id,
                     category: productDetail?.category,
                  })
               );
            }
         } else if (isFrom === "trips") {
            dispatch(
               updateIsBookmarkTrip({
                  location_id: productDetail?.id,
                  category: productDetail?.category,
               })
            );
         }
      }
      if (!response?.payload?.data?.status) {
         toast.error(response?.payload?.data?.message);
         setProductDetailShow(false);
      }
   };

   return (
      <>
         <Modal
            show={prooductDetailShow}
            onHide={() => {
               setProductDetailShow(false);
               setIsItenerary(false);
            }}
            centered
            className="view-details-modal"
         >
            <Modal.Body
               className={`p-0 position-relative ${
                  isSavedItenerary && "saved-itenerary-modal"
               }`}
            >
               <Icon
                  className="position-absolute modal-close-btn cursor-pointer d-md-none d-block"
                  onClick={() => {
                     setProductDetailShow(false);
                     setIsItenerary(false);
                  }}
                  name={"Close"}
               />
               {!isBookmark && (
                  <>
                     {!productDetail?.is_bookmarked ? (
                        <img
                           onClick={() => {
                              if (authData?.isAuthenticated) {
                                 bookMarkOnClick();
                              } else {
                                 navigate("/login");
                              }
                           }}
                           className="position-absolute bookmark-icon cursor-pointer"
                           src={bookMarkIcon}
                           alt=""
                        />
                     ) : (
                        <Icon
                           className="position-absolute bookmark-icon"
                           name={"Bookmarked"}
                        />
                     )}
                  </>
               )}

               <div className="position-relative">
                  <img
                     className="w-100 product-image"
                     src={
                        productDetail?.locationimage_set?.[0]
                           ? productDetail?.locationimage_set?.[0]?.image
                           : noImage
                     }
                     alt=""
                  />
                  {productDetail?.locationimage_set?.[0]
                     ?.is_representational && (
                     <p className="is-representational container text-center position-absolute text-light fst-italic">
                        For Representational Purpose
                     </p>
                  )}
               </div>
               <div className="d-flex flex-column gap-2 product-detail">
                  <div
                     className={`d-flex flex-column position-absolute w-100 px-3 ${
                        !productDetail?.locationimage_set?.[0]
                           ? "remove-backround-product-show"
                           : ""
                     }`}
                  >
                     <p className="product-name m-0 mt-3">
                        {productDetail?.name_of_location}
                     </p>
                     <p className="product-category text-muted m-0">
                        {productDetail.type?.name && productDetail.type?.name}
                        {productDetail?.type_of_food_drinks &&
                           productDetail?.type_of_food_drinks}
                     </p>
                  </div>
                  <div className="mt-5 px-3">
                     <p className="description m-0 text-muted">
                        {productDetail?.description}
                     </p>
                  </div>
               </div>
               <div className="px-3 mt-3 gap-3 d-flex flex-column align-items-center justify-content-center">
                  <div className="entry d-flex align-items-center justify-content-between p-4 ps-3 w-100">
                     <div className="d-flex align-items-center justify-content-center">
                        <Icon
                           name={"Entry"}
                           className="me-2 pass-icon"
                           width={24}
                           height={24}
                        />
                        <p className="m-0">Entry</p>
                     </div>
                     <p className="m-0 free">
                        {!productDetail?.entry_fee ? "Free" : "Paid"}
                     </p>
                  </div>
                  <div className="address d-flex w-100">
                     <Icon
                        name={"Location"}
                        className="pass-icon me-2"
                        width={24}
                        height={24}
                     />
                     <div>
                        <p className="address-title mb-1">Address</p>
                        <p className="text-muted">{productDetail?.address}</p>
                     </div>
                  </div>
                  <a
                     href={productDetail?.google_map}
                     className="location d-flex text-decoration-none align-items-center justify-content-center w-100 cursor-pointer"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        backgroundImage: `url('${locationBg}')`,
                        backgroundSize: "cover",
                        borderRadius: "15px",
                        height: "66px",
                     }}
                  >
                     <div className="d-flex align-items-center justify-content-center">
                        <p className="m-0 get-direction">
                           <Icon
                              name={"Map"}
                              className="me-2"
                              width={24}
                              height={24}
                           />
                           Get Direction
                        </p>
                     </div>
                  </a>
                  {productDetail?.access && (
                     <>
                        <div className="address d-flex w-100 align-content-start justify-content-start">
                           <Icon
                              name={"Access"}
                              className="me-2 mt-2"
                              width={24}
                              height={24}
                           />
                           <div className="mt-1">
                              <p className="address-title mb-1">Access</p>
                              <p className="address-text text-muted">
                                 {productDetail?.access}
                              </p>
                           </div>
                        </div>
                        <div className="divider w-100">
                           <hr className="m-0" />
                        </div>
                     </>
                  )}
                  <div className="address me-2 d-flex w-100 pb-3">
                     <Icon
                        name={"Timing"}
                        className="pass-icon me-2"
                        width={24}
                        height={24}
                     />
                     <div>
                        <p className="address-title">Timing</p>
                        <p className="address-text text-muted">
                           {productDetail?.open_timings}
                        </p>
                     </div>
                  </div>
               </div>
               {productDetail?.locationimage_set?.[0]?.credits && (
                  <div className="px-3 mt-2">
                     <div className="divider w-100 mb-2">
                        <hr className="m-0" />
                     </div>
                     <p className="description-copy-right m-0 text-muted">
                        {productDetail?.locationimage_set[0]?.credits}
                     </p>
                  </div>
               )}
            </Modal.Body>
            {!isSavedItenerary && (
               <Modal.Footer className="border-0 py-0 d-flex align-items-center justify-content-center shadow bg-transparent">
                  <button
                     disabled={loading}
                     onClick={() => {
                        if (haveTripDetails) {
                           addToItenery();
                        } else {
                           setProductDetailShow(false);
                           if (authData?.isAuthenticated) {
                              setTripTypeModalShow(true);
                           } else {
                              navigate("/login");
                           }
                        }
                     }}
                  >
                     Add to Itinerary
                     {loading && (
                        <span
                           className="spinner-border spinner-border-sm ms-2"
                           role="status"
                           aria-hidden="true"
                        />
                     )}
                  </button>
               </Modal.Footer>
            )}
         </Modal>
      </>
   );
}

export default ProductDetails;
