import { Routes, Route, Navigate } from "react-router-dom";
import {
   ActivitiesTab,
   Login,
   OtpVerification,
   Bookmarks,
   HelpMobile,
   Home,
   UpdateProfileForm,
   UpdateProfileMobile,
   MyTrips,
   TripDetail,
   TripPlan,
   PrivacyPolicy,
   About,
   ContactUs,
} from "../pages";
import ScrollToTopOnNavigation from "../components/ScrollToTopOnNavigation";

const routesConfig = [
   { path: "/:country_id/:city/:activity", component: ActivitiesTab },
   { path: "/login", component: Login },
   { path: "/sign-up", component: Login },
   { path: "/otp-verification", component: OtpVerification },
   { path: "/", component: Home },
   { path: "/about", component: About },
   { path: "/privacy-policy", component: PrivacyPolicy },
   { path: "/terms-conditions", component: PrivacyPolicy },
   { path: "/trip-plans", component: TripPlan },
   { path: "/contact-us", component: ContactUs },
   { path: "/my-trips", component: MyTrips, private: true },
   { path: "/bookmarks/:category", component: Bookmarks, private: true },
   { path: "/trip-detail/:id", component: TripDetail, private: true },
   {
      path: "/update-profile-form",
      component: UpdateProfileForm,
      private: true,
   },
   { path: "/update-profile", component: UpdateProfileMobile, private: true },
   { path: "/help", component: HelpMobile, private: true },
];

export default function Router() {
   const isAuthenticated = localStorage?.getItem("token");

   return (
      <>
         <ScrollToTopOnNavigation />
         <Routes>
            {routesConfig.map((route, index) => (
               <Route
                  key={index}
                  path={route.path}
                  element={
                     !route.private || isAuthenticated ? (
                        <route.component />
                     ) : (
                        <Navigate to="/login" />
                     )
                  }
               />
            ))}
         </Routes>
      </>
   );
}
