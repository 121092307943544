import { useParams } from "react-router-dom";
import Icon from "../assets/Icons";
import { useNavigate } from "react-router-dom";

export default function Tabs() {
   const params = useParams();

   const navigate = useNavigate();

   const isPlaces = params?.activity === "places";

   const isFoodDrink = params?.activity === "food-drink";

   const isEvent = params?.activity === "events";

   const tabOnClick = (activity) => {
      navigate(`/${params?.country_id}/all/${activity}`);
   };

   return (
      <div className="container-fluid activity-header box-shadow-sm d-flex align-items-center p-0  justify-content-lg-center justify-content-lg-center gap-lg-5 gap-md-3 justify-content-sm-around justify-content-around nav nav-tabs border-0 box-shadow">
         <div className="container  d-flex flex-wrap align-items-center justify-content-center gap-md-4 gap-sm-3 gap-3">
            <div className="d-flex align-items-center justify-content-center nav-item me-sm-3 me-2">
               <button
                  onClick={() => tabOnClick("places")}
                  className={`mb-0  cursor-pointer tab-item-fz text-decoration-none nav-link d-flex ${
                     isPlaces ? "button-active" : "text-muted"
                  }`}
               >
                  <Icon
                     name={`${isPlaces ? "hiking_active" : "hiking_light"}`}
                     className="me-2"
                     width={15}
                     height={22}
                  />
                  <p className="m-0">Places</p>
               </button>{" "}
            </div>
            <div className="d-flex align-items-center justify-content-center nav-item  me-sm-3 me-2">
               <button
                  onClick={() => tabOnClick("food-drink")}
                  className={`mb-0 cursor-pointer text-decoration-none tab-item-fz nav-link d-flex
                     ${isFoodDrink ? "button-active" : "text-muted"}
                     `}
               >
                  {" "}
                  <Icon
                     name={`${
                        isFoodDrink ? "local_dining_active" : "Food_Light"
                     }`}
                     className="me-2"
                     width={22}
                     height={22}
                  />
                  <p className="m-0">Food & Drink</p>
               </button>
            </div>
            <div className="d-flex align-items-center justify-content-center nav-item me-sm-3 me-2">
               <button
                  onClick={() => tabOnClick("events")}
                  className={`mb-0 cursor-pointer nav-link d-flex
                     ${isEvent ? "button-active" : "text-muted"}
                     `}
               >
                  <Icon
                     name={`${isEvent ? "event_active" : "event_light"}`}
                     className="me-2"
                     width={22}
                     height={22}
                  />
                  <p className="m-0">Events</p>
               </button>
            </div>
         </div>
      </div>
   );
}
