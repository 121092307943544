import ReactPaginate from "react-paginate";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const Pagination = ({ placesList, onHandleNextPage }) => {
   const PER_PAGE = 12;

   const pageCount = Math?.ceil(placesList?.data?.data?.count / PER_PAGE);

   const handlePageChange = ({ selected: selectedPage }) => {
      onHandleNextPage(selectedPage);
   };

   return (
      <div className="container py-lg-4 py-md-3 py-1">
         <div className="d-flex justify-content-center align-items-center">
            <ReactPaginate
               previousLabel={<GoChevronLeft />}
               nextLabel={<GoChevronRight />}
               pageCount={pageCount}
               key={`paginate${placesList?.data?.data?.current - 1}`}
               forcePage={placesList?.data?.data?.current - 1}
               marginPagesDisplayed={2}
               pageRangeDisplayed={5}
               onPageChange={handlePageChange}
               containerClassName={"pagination"}
               activeClassName={"active"}
               previousClassName={"page-item"}
               nextClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextLinkClassName={"page-link"}
               disabledClassName={"disabled"}
            />
         </div>
      </div>
   );
};

export default Pagination;
