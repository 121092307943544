import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getOtpApi,
   loginUserApi,
   signupUserApi,
   verifyUserApi,
} from "../api/auth.api";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";

const initialState = {
   otpLoading: false,
   loginData: {},
   error: null,
};

export const signupUser = createAsyncThunk("auth/signup", async (values) => {
   try {
      const res = await signupUserApi(values);
      if (res?.status) {
      }
      return res;
   } catch (err) {
      if (err?.response?.status === 400) {
         toast.error(err?.response?.data?.message?.email);
      }
      return err.response;
   }
});

export const getOtp = createAsyncThunk("auth/get_otp", async (values) => {
   try {
      const res = await getOtpApi(values);
      if (res?.status) {
         //  localStorage.setItem("token", res?.data?.access);
      }
      return res;
   } catch (err) {
      if (err?.response?.status === 401) {
         //  toast.error(err?.response?.data?.detail);
      }
      return err.response;
   }
});

export const loginUser = createAsyncThunk("auth/login", async (values) => {
   try {
      const res = await loginUserApi(values);
      if (res?.data?.status) {
         toast.success(res?.data?.message);
      }
      return res;
   } catch (err) {
      if (err?.response?.status === 404) {
         toast.error(err?.response?.data?.message);
      }
      return err.response;
   }
});

export const verifyUser = createAsyncThunk(
   "auth/verify_user",
   async (values) => {
      try {
         const res = await verifyUserApi(values);
         if (res?.data?.status) {
            toast.success(res?.data?.message);
            localStorage?.setItem("token", res?.data?.access_token);
         }
         return res;
      } catch (err) {
         if (err?.response?.status === 400) {
            toast.error(err?.response?.data?.message);
         }
         return err.response;
      }
   }
);

const loginSice = createSlice({
   name: "authenticate",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(verifyUser.pending, (state) => {
         state.loading = true;
         state.loginData = {};
         state.error = null;
      });
      builder.addCase(verifyUser.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.loginData = {};
         state.error = null;
      });
      builder.addCase(verifyUser.rejected, (state, { payload }) => {
         state.loading = false;
         state.loginData = {};
         state.error = payload;
      });

      builder.addCase(loginUser.pending, (state) => {
         state.otpLoading = true;
         state.loginData = {};
         state.error = null;
      });
      builder.addCase(loginUser.fulfilled, (state, { payload }) => {
         state.otpLoading = false;
         state.loginData = payload;
         state.error = null;
      });
      builder.addCase(loginUser.rejected, (state, { payload }) => {
         state.otpLoading = false;
         state.loginData = {};
         state.error = payload;
      });
   },
});

const { reducer } = loginSice;
export default reducer;
