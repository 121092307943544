import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getOtpApi = (values) => {
   return axiosConfig.post(Config.GET_OTP_API_URL, values);
};

export const loginUserApi = (values) => {
   return axiosConfig.post(Config.LOGIN_API_URL, values);
};

export const signupUserApi = (values) => {
   return axiosConfig.post(Config.SIGNUP_API_URL, values);
};

export const verifyUserApi = (values) => {
   return axiosConfig.post(Config.VERIFY_OTP_API_URL, values);
};
