import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import bookMarkIconActive from "../assets/images/icon/bookmark_added.svg";
import bookMarkIcon from "../assets/images/icon/bookmark.svg";
import accountIcon from "../assets/images/icon/account.svg";
import homeIcon from "../assets/images/icon/home.svg";
import Icon from "../assets/Icons";
import useAuth from "../constants/useAuth";

export default function VerifiedHeaderBottom() {
   const navigate = useNavigate();

   const location = useLocation();

   const [authData] = useAuth();

   return (
      <div className="header-auth-tab header-auth-tab-mobile-shadow  col-12 d-flex align-items-center justify-content-center bg-light fixed-bottom mt-5 pt-3 pb-2">
         <ul className="d-flex  p-0 m-0 w-100 align-items-center justify-content-center">
            <li className="d-flex  align-items-center justify-content-evenly w-100">
               <div
                  className="d-flex gap-2 flex-column align-items-center justify-content-center cursor-pointer"
                  onClick={() => {
                     navigate("/");
                  }}
               >
                  {location?.pathname === "/" ? (
                     <Icon
                        name={"CameraIndoor"}
                        className="gap-5"
                        width={19}
                        height={19}
                     />
                  ) : (
                     <img
                        className="auth-tab-opacity gap-5"
                        src={homeIcon}
                        alt=""
                     />
                  )}
                  <p
                     className={
                        location?.pathname === "/" ? "m-0 active-color" : "m-0"
                     }
                  >
                     Home
                  </p>
               </div>
               <div
                  className="d-flex gap-2 flex-column align-items-center justify-content-center"
                  onClick={() => {
                     if (authData?.isAuthenticated) {
                        navigate("/my-trips");
                     } else {
                        navigate("/login");
                     }
                  }}
               >
                  {location?.pathname === "/my-trips" ||
                  location?.pathname === "/trip-detail" ? (
                     <Icon
                        name={"My_trip_Filled"}
                        className="gap-5"
                        width={24}
                        height={24}
                     />
                  ) : (
                     <Icon
                        name={"My_Trip"}
                        className="auth-tab-opacity"
                        width={24}
                        height={24}
                     />
                  )}
                  <p
                     className={
                        location?.pathname === "/my-trips" ||
                        location?.pathname === "/trip-detail"
                           ? "m-0 active-color"
                           : "m-0"
                     }
                  >
                     My Trips
                  </p>
               </div>
               <div
                  className="d-flex gap-2 flex-column align-items-center justify-content-center"
                  onClick={() => {
                     if (authData?.isAuthenticated) {
                        navigate("/bookmarks/all");
                     } else {
                        navigate("/login");
                     }
                  }}
               >
                  {location?.pathname === "/bookmarks/all" ||
                  location?.pathname === "/bookmarks/places" ||
                  location?.pathname === "/bookmarks/events" ||
                  location?.pathname === "/bookmarks/food-drink" ? (
                     <img src={bookMarkIconActive} alt="" />
                  ) : (
                     <img
                        src={bookMarkIcon}
                        className="auth-tab-opacity"
                        alt=""
                     />
                  )}
                  <p
                     className={
                        location?.pathname === "/bookmarks/all" ||
                        location?.pathname === "/bookmarks/places" ||
                        location?.pathname === "/bookmarks/events" ||
                        location?.pathname === "/bookmarks/food-drink"
                           ? "m-0 active-color"
                           : "m-0"
                     }
                  >
                     Bookmarks
                  </p>
               </div>
               <div
                  className="d-flex gap-2 flex-column align-items-center justify-content-center"
                  onClick={() => {
                     if (authData?.isAuthenticated) {
                        navigate("/update-profile");
                     } else {
                        navigate("/login");
                     }
                  }}
               >
                  {location?.pathname === "/update-profile" ? (
                     <Icon
                        name={"Profile_Filled"}
                        className="gap-5"
                        width={24}
                        height={24}
                     />
                  ) : (
                     <img
                        src={accountIcon}
                        className="auth-tab-opacity"
                        alt=""
                     />
                  )}
                  <p
                     className={
                        location?.pathname === "/update-profile"
                           ? "m-0 active-color"
                           : "m-0"
                     }
                  >
                     Profile
                  </p>
               </div>
            </li>
         </ul>
      </div>
   );
}
