import React from "react";
import Spinner from "./Spinner";
import Pagination from "./Pagination";
import noFoodAndDrinksImage from "../assets/images/icon/food&drink.png";
import { useParams } from "react-router-dom";
import noPlaces from "../assets/images/icon/places.png";
import LocationCard from "./LocationCard";

export default function BookmarkActivity({
   bookmarksList,
   loading,
   _deleteBookmark,
   onHandleNextPage,
   viewOnClick,
}) {
   const params = useParams();

   const type = params?.category;

   return (
      <div className="container mb-md-0 mb-3 pb-md-0 pb-4">
         {loading ? (
            <Spinner height={40} />
         ) : (
            <div>
               {!bookmarksList?.data?.data?.results?.length > 0 ? (
                  <div className="d-flex align-items-center justify-content-between flex-column gap-3 mt-3">
                     {type !== "all" && (
                        <img
                           src={
                              type === "food-drink"
                                 ? noFoodAndDrinksImage
                                 : noPlaces
                           }
                           alt=""
                        />
                     )}
                     <p className="no-data-text">
                        No{" "}
                        {type === "food-drink"
                           ? "food & drink"
                           : type === "places"
                           ? "places"
                           : "bookmarks"}{" "}
                        found
                     </p>
                  </div>
               ) : (
                  <div className="row my-3 pb-3 places-card">
                     {bookmarksList?.data?.data?.results?.map((data, index) => (
                        <LocationCard
                           _deleteBookmark={_deleteBookmark}
                           locationDetails={data}
                           key={index}
                           viewOnClick={viewOnClick}
                           isBookmark={true}
                        />
                     ))}
                     {(bookmarksList?.data?.data?.next ||
                        bookmarksList?.data?.data?.previous) && (
                        <Pagination
                           placesList={bookmarksList}
                           onHandleNextPage={onHandleNextPage}
                        />
                     )}
                  </div>
               )}
            </div>
         )}
      </div>
   );
}
