import { Modal } from "react-bootstrap";
import moment from "moment";

export default function AvalailableTrips({
   show,
   setTripsModalShow,
   setTripId,
   tripId,
   addToItenery,
   selectedCountryId,
   userActiveTripsList,
}) {
   return (
      <Modal
         show={show}
         onHide={() => setTripsModalShow(false)}
         keyboard={false}
         centered
         className="view-details-modal"
      >
         <Modal.Body>
            <div className="d-flex align-items-center justify-content-center">
               <hr className="modal-top-hr_ m-0" />
            </div>
            <div className="p-content-select mt-2">
               <h3>Select your trip</h3>
            </div>
            <div className="mt-5">
               {userActiveTripsList?.data?.data?.map((data, index) => {
                  if (selectedCountryId == data?.country?.id) {
                     return (
                        <div
                           key={index}
                           onClick={() => setTripId(data?.id)}
                           className="cursor-pointer my-2 w-100"
                        >
                           <label
                              className={`px-4 py-2  mb-2 cursor-pointer shadow rounded-5 border-black ${
                                 tripId === data?.id && "selected-trip-border"
                              }`}
                           >
                              <div className="d-flex align-items-center gap-4">
                                 <input
                                    type="radio"
                                    name="country"
                                    className="country-radiobutton-c"
                                    value={data?.id}
                                    checked={tripId === data?.id}
                                    onChange={() => setTripId(data?.id)}
                                 />
                                 <img src={data?.country?.flag} alt="" />
                                 <p className="m-0 py-2">
                                    {data?.country?.name}
                                 </p>
                              </div>
                              <div className="moment-created-at d-flex gap-3">
                                 <p className="m-0 text-muted created-at">
                                    Created at :
                                 </p>
                                 <div className="d-flex gap-2">
                                    <p className="m-0 date">
                                       {moment(data?.created_at).format(
                                          "MMMM D, YYYY"
                                       )}
                                    </p>
                                    <p className="m-0 time text-muted">
                                       {moment(data?.created_at).format(
                                          "hh:mm A"
                                       )}
                                    </p>
                                 </div>
                              </div>
                           </label>
                        </div>
                     );
                  }
               })}
            </div>
         </Modal.Body>
         <Modal.Footer
            className={`border-0 d-flex align-items-center justify-content-center py-0 ${
               tripId === null && "disabled-btn"
            }`}
         >
            <button onClick={addToItenery} disabled={tripId === null}>
               Proceed
            </button>
         </Modal.Footer>
      </Modal>
   );
}
