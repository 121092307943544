import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getEventsApi, getEventsFiltersApi } from "../api/events.api";

const initialState = {
   eventsLoading: false,
   eventsList: {},
   eventsFilters: {},
   error: null,
};

export const getEvents = createAsyncThunk("get_events", async (data) => {
   try {
      const res = await getEventsApi(data);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const updateIsBookmarkEvent = createAsyncThunk(
   "update_is_bookmark_event",
   async (data) => {
      try {
         return data;
      } catch (err) {
         return err.response;
      }
   }
);

export const getEventsFilters = createAsyncThunk(
   "get_events_filters",
   async (country_id) => {
      try {
         const res = await getEventsFiltersApi(country_id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

const eventsSlices = createSlice({
   name: "events",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getEvents.pending, (state) => {
         state.eventsLoading = true;
         state.eventsList = {};
         state.error = null;
      });
      builder.addCase(getEvents.fulfilled, (state, { payload }) => {
         state.eventsLoading = false;
         state.eventsList = payload;
         state.error = null;
      });
      builder.addCase(getEvents.rejected, (state, { payload }) => {
         state.eventsLoading = false;
         state.eventsList = {};
         state.error = payload;
      });

      builder.addCase(getEventsFilters.pending, (state) => {
         state.loading = true;
         state.eventsFilters = {};
         state.error = null;
      });
      builder.addCase(getEventsFilters.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.eventsFilters = payload;
         state.error = null;
      });
      builder.addCase(getEventsFilters.rejected, (state, { payload }) => {
         state.loading = false;
         state.eventsFilters = {};
         state.error = payload;
      });

      builder.addCase(updateIsBookmarkEvent.fulfilled, (state, { payload }) => {
         state.loading = false;
         const indexToUpdate = state.eventsList?.data?.data?.results.findIndex(
            (item) => item.id === payload?.location_id
         );
         if (indexToUpdate !== -1) {
            state.eventsList.data.data.results[
               indexToUpdate
            ].is_bookmarked = true;
         }
         state.error = null;
      });
   },
});

const { reducer } = eventsSlices;
export default reducer;
