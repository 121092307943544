import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getIteneryApi,
   addIteneryApi,
   deleteIteneryApi,
} from "../api/itenery.api";
import toast from "react-hot-toast";

const initialState = {
   loading: false,
   itenerysList: {},
   deleteItineraryLoading: false,
   deleteItineraryRes: {},
   addItineraryRes: {},
   error: null,
};

export const getItenerys = createAsyncThunk("get_itenerys", async (data) => {
   try {
      return await getIteneryApi(data);
   } catch (err) {
      return err.response;
   }
});

export const deleteItenery = createAsyncThunk("delete_itenerys", async (id) => {
   try {
      const res = await deleteIteneryApi(id);
      toast.success(res?.data?.message);
      return id;
   } catch (err) {
      return err.response;
   }
});

export const addItenery = createAsyncThunk("add_itenerys", async (data) => {
   try {
      const response = await addIteneryApi(data);
      return response;
   } catch (err) {
      return err.response;
   }
});

export const updateIsBookmarkTrip = createAsyncThunk(
   "update_is_bookmark_trip",
   async (data) => {
      try {
         return data;
      } catch (err) {
         return err.response;
      }
   }
);

const itenerySlice = createSlice({
   name: "itineraries",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getItenerys.pending, (state) => {
         state.loading = true;
         state.itenerysList = {};
         state.error = null;
      });
      builder.addCase(getItenerys.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.itenerysList = payload;
         state.error = null;
      });
      builder.addCase(getItenerys.rejected, (state, { payload }) => {
         state.loading = false;
         state.itenerysList = {};
         state.error = payload;
      });

      builder.addCase(deleteItenery.pending, (state) => {
         state.deleteItineraryLoading = true;
         state.deleteItineraryRes = {};
         state.error = null;
      });
      builder.addCase(deleteItenery.fulfilled, (state, { payload }) => {
         state.deleteItineraryLoading = false;
         state.itenerysList.data.data = state.itenerysList.data.data.filter(
            (data) => data.id !== payload
         );
         state.error = null;
      });
      builder.addCase(deleteItenery.rejected, (state, { payload }) => {
         state.deleteItineraryLoading = false;
         state.deleteItineraryRes = {};
         state.error = payload;
      });

      builder.addCase(addItenery.pending, (state) => {
         state.loading = true;
         state.addItineraryRes = {};
         state.error = null;
      });
      builder.addCase(addItenery.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.addItineraryRes = payload;
         state.error = null;
      });
      builder.addCase(addItenery.rejected, (state, { payload }) => {
         state.loading = false;
         state.addItineraryRes = {};
         state.error = payload;
      });
      builder.addCase(updateIsBookmarkTrip.fulfilled, (state, { payload }) => {
         state.loading = false;
         const indexToUpdate = state.itenerysList?.data?.data?.findIndex(
            (item) => item.location.id === payload?.location_id
         );
         if (indexToUpdate !== -1) {
            state.itenerysList.data.data[
               indexToUpdate
            ].location.is_bookmarked = true;
         }
         state.error = null;
      });
   },
});

const { reducer } = itenerySlice;
export default reducer;
