/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import headerAction from "../../assets/images/icon/header-action.svg";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tab from "react-bootstrap/Tab";
import googleTranslation from "../../assets/images/icon/googleTranslation.png";
import Tabs from "react-bootstrap/Tabs";
import { deleteItenery, getItenerys } from "../../redux/slices/itenerySlices";
import noImage from "../../assets/images/icon/no-image.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FcCheckmark } from "react-icons/fc";
import {
   addSpending,
   addSpendingAmount,
   getEmergency,
   getLearnLangauges,
   getSpendingCategory,
   getSpendingId,
   getSpendings,
   getTips,
   getTrip,
   removeSpending,
   updateSpending,
   updateTripDate,
} from "../../redux/slices/tripSlices";
import Icon from "../../assets/Icons";
import { getReminDays } from "../../constants/common";
import {
   Spinner,
   ProductDetails,
   DeleteConfirmationModal,
} from "../../components";
import Image_ from "../../components/Image";

const TripCard = ({ data, viewOnClick, setDeleteModalShow, setDeleteData }) => {
   return (
      <div className="col-12 my-3 d-flex">
         <div className="status d-flex flex-column align-items-center justify-content-center me-2">
            <div className="dots"></div>
            <div className="line"></div>
         </div>
         <div className="card trip-card ms-2 w-100 me-lg-3 me-md-2 box-shadow border-0">
            <div className="media">
               <Image_
                  imageSrc={
                     data?.location?.locationimage_set?.[0]
                        ? data?.location?.locationimage_set?.[0]?.image
                        : noImage
                  }
                  styles={{
                     borderTopLeftRadius: "15px",
                     borderTopRightRadius: "15px",
                     height: "63px",
                     objectFit: "cover",
                     objectPosition: "center",
                     width: "100%",
                  }}
               />
               <div className="media-body p-4 row">
                  <div className="col-md-6 col-12 d-flex flex-column">
                     <h5 className="card-title">
                        {data?.location?.name_of_location}
                     </h5>
                     <p className="card-text text-muted m-0">
                        {data?.location?.address}
                     </p>
                     {data?.location?.cost_description && (
                        <p className="entry mb-0 mt-2">
                           {data?.location?.cost_description}
                        </p>
                     )}
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-center justify-content-between">
                     <button
                        className="m-1 p-md-4"
                        onClick={() => {
                           viewOnClick(data?.id);
                        }}
                     >
                        View Details
                     </button>
                     <a
                        href={data?.location?.google_map}
                        target="_blank"
                        className="text-decoration-none"
                        rel="noreferrer"
                     >
                        <Icon name="Map_Address" />
                     </a>
                     <div
                        onClick={() => {
                           setDeleteModalShow(true);
                           setDeleteData(data);
                        }}
                        className="cursor-pointer m-1 bookmark-delete-button d-flex align-items-center justify-content-center"
                     >
                        <MdDeleteOutline size={18} color="red" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default function TripDetail() {
   const navigate = useNavigate();

   const dispatch = useDispatch();

   const params = useParams();

   const location = useLocation();

   const [tipsModalShow, setTipsModalShow] = useState(false);

   const [helpModalShow, setHelpModalShow] = useState(false);

   const [openCalendar, setOpenCalendar] = useState(false);

   const [prooductDetailShow, setProductDetailShow] = useState(false);

   const [popupData, setPopupData] = useState({});

   const [isSavedItenerary] = useState(true);

   const { itenerysList, loading } = useSelector((state) => state.iteneries);

   const [spendingFormShow, setSpendingFormShow] = useState(false);

   const [spendingShow, setSpendingShow] = useState(false);

   const [_spendingId, setSpendingId] = useState(null);

   const [isEditAmount, setIsEditAmount] = useState(false);

   const [progressbarValue, setProgressbarValue] = useState();

   const [editSpendingData, setEditSpendingData] = useState({});

   const [isSpendingEdit, setIsSpendingEdit] = useState(false);

   const [deleteModalShow, setDeleteModalShow] = useState(false);

   const [deleteData, setDeleteData] = useState(null);

   const [activeKey, setActiveKey] = useState("home"); // Set the initial active tab key

   const [tipsTab, setTipsTab] = useState("general"); // Set the initial active tab key

   const [isItenerary, setIsItenerary] = useState(false);

   const {
      tipsList,
      learLangaugesList,
      spendingsList,
      updateTripLoading,
      emergencyData,
      userTrip,
      spendingCategoryList,
      spendingId,
   } = useSelector((state) => state?.trips);

   const { deleteItineraryLoading } = useSelector((state) => state?.iteneries);

   const [totalAmount, setTotalAmount] = useState(
      spendingId?.data?.data?.budget
   );

   const [returnDate, setReturnDate] = useState(
      userTrip?.data?.data?.return_date
         ? new Date(userTrip?.data?.data?.return_date)
         : null
   );

   function isDateLesserThan(dateString) {
      const currentDate = new Date();
      if (dateString < currentDate) {
         return true;
      } else {
         return false;
      }
   }

   const [departureDate, setDepartureDate] = useState(
      userTrip?.data?.data?.departure_date
         ? new Date(userTrip?.data?.data?.departure_date)
         : new Date()
   );

   const initialValues = {
      amount_spent: parseInt(editSpendingData?.amount_spent) || "",
      category_id: editSpendingData?.category?.id || "",
      payment_method: editSpendingData?.payment_method || "",
   };

   const validationSchema = Yup.object().shape({
      amount_spent: Yup.string()
         .required("Amount is required")
         .matches(/^[0-9]+$/, "Enter a valid number"),
      category_id: Yup.number()
         .notOneOf([0], "Select a category")
         .required("Category is required"),
      payment_method: Yup.string()
         .required("Payment method is required")
         .test("aboveOne", "Payment method is required", (value) => {
            const numericValue = Number(value);
            return numericValue > 0;
         }),
   });

   useEffect(() => {
      dispatch(getItenerys(params?.id));
      dispatch(getSpendingId(params?.id));
      const fetchData = async () => {
         const response = await dispatch(getTrip(params?.id));
         const tripData = response?.payload?.data?.data;
         if (response?.payload?.status === 200) {
            dispatch(getTips(tripData?.country?.id));
            dispatch(getEmergency(tripData?.country?.id));
            dispatch(getLearnLangauges(tripData?.country?.id));
            setDepartureDate(
               response?.payload?.data?.data?.departure_date
                  ? new Date(response?.payload?.data?.data?.departure_date)
                  : new Date()
            );
            setReturnDate(new Date(response?.payload?.data?.data?.return_date));
         }
      };
      fetchData();
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         const response = await dispatch(getSpendingId(params?.id));
         if (response?.payload?.status === 200) {
            setSpendingId(response?.payload?.data?.data?.id);
            setTotalAmount(parseInt(response?.payload?.data?.data?.budget));
            dispatch(getSpendingCategory(response?.payload?.data?.data?.id));
            dispatch(getSpendings(response?.payload?.data?.data?.id));
            calculateTotalSpentPercentage(
               response?.payload?.data?.data?.budget,
               response?.payload?.data?.data?.total_spent
            );
         }
      };
      fetchData();
   }, []);

   const addAmountOnClick = async () => {
      const formData = new FormData();
      formData.append("id", _spendingId);
      formData.append("budget", parseInt(totalAmount));
      const response = await dispatch(addSpendingAmount(formData));
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         const response_ = await dispatch(getSpendingId(_spendingId));
         if (response_?.payload?.data?.status) {
            calculateTotalSpentPercentage(
               parseInt(response_?.payload?.data?.data?.budget),
               parseInt(response_?.payload?.data?.data?.total_spent)
            );
         }
         setIsEditAmount(false);
      }
   };

   const updateTripDateFn = async () => {
      const formData = new FormData();
      formData.append("id", params?.id);
      formData.append(
         "departure_date",
         moment(departureDate).format("YYYY-MM-DD")
      );
      formData.append("return_date", moment(returnDate).format("YYYY-MM-DD"));
      formData.append("country_id", userTrip?.data?.data?.country?.id);
      formData.append(
         "departure_country_id",
         userTrip?.data?.data?.departure_country?.id
      );

      const response = await dispatch(updateTripDate(formData));

      if (response?.payload?.status === 200) {
         dispatch(getTrip(params?.id));
         toast.success(response?.payload?.data?.message);
         setOpenCalendar(false);
      } else if (response?.payload?.status === 400) {
         toast.error(response?.payload?.data?.message);
         setOpenCalendar(false);
      }
   };

   const handleSubmit = async (values, { setSubmitting }) => {
      const formData = new FormData();
      if (isSpendingEdit) {
         formData.append("spending_id", editSpendingData?.id);
      } else {
         formData.append("trip_spending_id", _spendingId);
      }
      formData.append("amount_spent", parseInt(values.amount_spent));
      formData.append("category_id", parseInt(values.category_id));
      formData.append("payment_method", parseInt(values.payment_method));

      const response = isSpendingEdit
         ? await dispatch(updateSpending(formData))
         : await dispatch(addSpending(formData));

      if (response?.payload?.status === 200) {
         toast.success(response?.payload?.data?.message);
         setSpendingFormShow(false);
         dispatch(getSpendings(_spendingId));
         const response_ = await dispatch(getSpendingId(_spendingId));
         if (response_?.payload?.data?.status) {
            calculateTotalSpentPercentage(
               parseInt(response_?.payload?.data?.data?.budget),
               parseInt(response_?.payload?.data?.data?.total_spent)
            );
         }
         setIsSpendingEdit(false);
         setEditSpendingData({});
      }
      setSubmitting(false);
   };

   const deleteSpendingOnClick = async (id) => {
      const response = await dispatch(removeSpending(id));
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         dispatch(getSpendings(_spendingId));
         const response_ = await dispatch(getSpendingId(_spendingId));
         if (response_?.payload?.data?.status) {
            calculateTotalSpentPercentage(
               parseInt(response_?.payload?.data?.data?.budget),
               parseInt(response_?.payload?.data?.data?.total_spent)
            );
         }
      }
   };

   const viewOnClick = (id) => {
      const filtered = itenerysList?.data?.data?.filter(
         (data) => data?.id === id
      );
      setPopupData(filtered[0]?.location);
      setProductDetailShow(true);
   };

   const handleDateChange = (date) => {
      const [start, end] = date;
      setDepartureDate(start);
      setReturnDate(end);
   };

   const getLeftToSpend = (totalAmount, spendAmount) => {
      let total = totalAmount - spendAmount;
      return total;
   };

   const calculateDaysDifference = (departureDate) => {
      const currentDate = new Date();
      const timeDifference = Math.abs(
         new Date(departureDate)?.getTime() - currentDate?.getTime()
      );
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return daysDifference;
   };

   const calculateTotalSpentPercentage = (totalAmount, totalSpent) => {
      const percentageSpent = (totalSpent / totalAmount) * 100;
      setProgressbarValue(percentageSpent);
   };

   const deleteOnClick = async (id) => {
      const response = await dispatch(deleteItenery(id));
      if (response?.payload) {
         setDeleteModalShow(false);
      }
   };

   const renderParagraph = (paragraph) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const parts = paragraph.split(urlRegex);
      return (
         <p key={paragraph.id}>
            {parts.map((part, index) =>
               urlRegex.test(part) ? (
                  <a
                     key={index}
                     href={part}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {part}
                  </a>
               ) : (
                  part
               )
            )}
         </p>
      );
   };

   return (
      <>
         <div>
            <DeleteConfirmationModal
               show={deleteModalShow}
               setShow={setDeleteModalShow}
               type={"itinerary"}
               yesOnClick={deleteOnClick}
               data={deleteData}
               setDeleteData={setDeleteData}
               loading={deleteItineraryLoading}
            />
            <div className="container-fluid trip-detail ">
               <div className="container">
                  <div className="row">
                     <div className="col-md-8 col-12 d-flex align-content-center justify-content-between">
                        <div className="d-flex gap-3 align-items-center justify-content-center">
                           <img
                              src={headerAction}
                              className="cursor-pointer"
                              alt=""
                              onClick={() => navigate(-1)}
                           />
                           <img
                              className="country-icon"
                              src={userTrip?.data?.data?.country?.flag}
                           />
                           {userTrip?.data?.data && (
                              <div>
                                 {userTrip?.data?.data?.departure_date ? (
                                    <div className="d-flex flex-column">
                                       <p className="m-0">
                                          {calculateDaysDifference(
                                             userTrip?.data?.data
                                                ?.departure_date
                                          )}{" "}
                                          Days to go{" "}
                                       </p>
                                       <p className="m-0 small-description">
                                          for your{" "}
                                          {userTrip?.data?.data?.country?.name}{" "}
                                          trip
                                       </p>{" "}
                                    </div>
                                 ) : (
                                    <p className="m-0 text-light">
                                       Trip to{" "}
                                       {userTrip?.data?.data?.country?.name}
                                    </p>
                                 )}
                              </div>
                           )}
                        </div>
                     </div>
                     <div className="col-md-4 col-12 d-flex align-items-center justify-content-sm-start justify-content-center mt-md-0 mt-3">
                        <div>
                           <button
                              className="bg-transparent border-0 text-light d-flex align-items-center justify-content-center gap-3"
                              onClick={() => setOpenCalendar(true)}
                           >
                              <Icon name={"Date_Time"} />
                              {loading ? (
                                 <div
                                    className="spinner-border"
                                    style={{ width: "20px", height: "20px" }}
                                    role="status"
                                 ></div>
                              ) : (
                                 <>
                                    {userTrip?.data?.data?.departure_date ? (
                                       <div>
                                          {getReminDays(
                                             userTrip?.data?.data
                                                ?.departure_date,
                                             userTrip?.data?.data?.return_date
                                          )}
                                       </div>
                                    ) : (
                                       "Set Date"
                                    )}
                                 </>
                              )}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container trip-detail-recommend d-md-block d-none">
               <div className="row my-md-5 pb-4">
                  <div className="col-md-8 col-12 position-relative">
                     <div className="container d-flex align-items-center justify-content-center">
                        <button className="bg-transparent add-plus-btn border-0 cursor-none">
                           <Icon
                              className="cursor-pointer"
                              onClick={() => {
                                 navigate(
                                    `/${userTrip?.data?.data?.country?.iso3}/all/places`,
                                    {
                                       state: {
                                          trip_details: userTrip?.data?.data,
                                       },
                                    }
                                 );
                              }}
                              name={"AddItinerary"}
                           />
                        </button>
                     </div>
                     {loading ? (
                        <Spinner height={50} />
                     ) : (
                        <div>
                           {itenerysList?.data?.data?.length > 0 ? (
                              <div>
                                 {itenerysList?.data?.data?.map(
                                    (data, index) => (
                                       <TripCard
                                          key={index}
                                          data={data}
                                          index={index}
                                          viewOnClick={viewOnClick}
                                          setDeleteModalShow={
                                             setDeleteModalShow
                                          }
                                          setDeleteData={setDeleteData}
                                       />
                                    )
                                 )}
                              </div>
                           ) : (
                              <div>
                                 <p className="no-data-text text-center">
                                    No itineraries
                                 </p>
                              </div>
                           )}
                        </div>
                     )}
                  </div>
                  <div className="col-md-4 col-12 more-detail-recomend">
                     <div className="row p-lg-4 p-md-2 box-shadow">
                        <div className="col-12">
                           <div className="d-flex align-items-center justify-content-start">
                              <Icon name={"Explore"} />
                              <p className="m-0 ms-2">More recommendations</p>
                           </div>
                           <p className="sub-head">
                              Don't miss out on these places in{" "}
                              {location?.state?.trip?.country?.name}
                           </p>
                        </div>
                     </div>
                     <div className="row box-shadow row-cols-lg-3 row-cols-md-2 mt-3 p-3">
                        <div
                           className="col p-1 cursor-pointer"
                           onClick={() =>
                              navigate(
                                 `/${userTrip?.data?.data?.country?.iso3}/all/places`,
                                 {
                                    state: {
                                       trip_details: userTrip?.data?.data,
                                    },
                                 }
                              )
                           }
                        >
                           <div className="card">
                              <Icon name={"Places"} />
                              <p>Places</p>
                           </div>
                        </div>
                        <div
                           className="col p-1 cursor-pointer"
                           onClick={() =>
                              navigate(
                                 `/${userTrip?.data?.data?.country?.iso3}/all/events`,
                                 {
                                    state: {
                                       trip_details: userTrip?.data?.data,
                                    },
                                 }
                              )
                           }
                        >
                           <div className="card">
                              <Icon name={"Events"} />
                              <p>Events</p>
                           </div>
                        </div>
                        <div
                           className="col p-1 cursor-pointer"
                           onClick={() =>
                              navigate(
                                 `/${userTrip?.data?.data?.country?.iso3}/all/food-drink`,
                                 {
                                    state: {
                                       trip_details: userTrip?.data?.data,
                                    },
                                 }
                              )
                           }
                        >
                           <div className="card">
                              <Icon name={"Food_Drinks"} />
                              <p>Food & Drink</p>
                           </div>
                        </div>
                        <div
                           className="col p-1 cursor-pointer"
                           onClick={() => setTipsModalShow(true)}
                        >
                           <div className="card">
                              <Icon name={"Tips"} />
                              <p>Tips</p>
                           </div>
                        </div>
                        <div
                           className="col p-1 cursor-pointer"
                           onClick={() => setHelpModalShow(true)}
                        >
                           <div className="card">
                              <Icon name={"Emergency"} />
                              <p>Emergency</p>
                           </div>
                        </div>
                     </div>
                     <div className="row p-4 mt-md-4 my-3 more-detail-recomend-two box-shadow">
                        <div className="d-flex align-items-start justify-content-center flex-column">
                           <div
                              className="col-12 cursor-pointer d-flex align-items-center justify-content-between my-3"
                              onClick={() => setSpendingShow(true)}
                           >
                              <div className="d-flex align-items-center gap-3 justify-content-start">
                                 <Icon name={"Spendings"} />
                                 <p className="m-0">Your spendings</p>
                              </div>
                              <Icon
                                 name={"arrow_forward"}
                                 height={20}
                                 width={20}
                              />
                           </div>
                           <h2 className="total_spending">
                              ${spendingId?.data?.data?.total_spent}
                           </h2>
                           <ProgressBar
                              className="p-0 w-100"
                              now={progressbarValue}
                              variant={`${progressbarValue > 100 && "danger"}`}
                           />
                           <p className="my-4 m-0">
                              <span className="left-spending me-3">
                                 Left to spend
                              </span>
                              <span className="ruppee">
                                 $
                                 {getLeftToSpend(
                                    totalAmount,
                                    spendingId?.data?.data?.total_spent
                                 )}
                              </span>
                           </p>
                        </div>
                        <p className="text-end text-muted m-0">
                           ${totalAmount}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <Modal
               show={tipsModalShow}
               onHide={() => setTipsModalShow(false)}
               keyboard={false}
               centered
               className="tips-modal"
            >
               <div className="modal-header">
                  <div className="modal-dialog trip-m-header">
                     <div className="modal-content trip-m-container w-100">
                        <div className="trip-modal-header d-inline">
                           <div className="trip-modal-container">
                              <div className="trip-popup-modal position-sticky fixed-top">
                                 <div className="d-flex align-items-center justify-content-center">
                                    <p className="text-light fw-semibold text-center">
                                       Tips
                                    </p>
                                    <Icon
                                       className="modal-close-btn position-absolute cursor-pointer d-md-none d-block"
                                       onClick={() => {
                                          setTipsModalShow(false);
                                       }}
                                       name={"Close"}
                                    />
                                 </div>
                              </div>
                              <Tabs
                                 className="mb-3 tab-gl-header pb-2"
                                 transition={false}
                                 activeKey={tipsTab}
                                 onSelect={(eventKey) => {
                                    setTipsTab(eventKey);
                                 }}
                              >
                                 <Tab
                                    eventKey="general"
                                    className="tab-gl-container"
                                    title={
                                       <span
                                          className={`tab-general-content ${
                                             tipsTab !== "general" &&
                                             "disabled-btn"
                                          }`}
                                       >
                                          General
                                       </span>
                                    }
                                 >
                                    {tipsList?.data?.data?.length > 0 ? (
                                       <div>
                                          {tipsList?.data?.data?.map(
                                             (data, index) => (
                                                <div
                                                   className="tips-s-header"
                                                   key={index}
                                                >
                                                   <div className="tips-s-container px-3">
                                                      <div className="tip-start-content">
                                                         <div className=" tip-note-start-content d-flex align-items-center justify-content-between">
                                                            <div className="bg-tips-title rounded-5 px-2 mb-2 col-form-label-sm d-flex">
                                                               <Icon
                                                                  className="me-2"
                                                                  name={
                                                                     "Magic_button"
                                                                  }
                                                               />
                                                               <p className="m-0 fs-6 text-dark">
                                                                  {data?.title}
                                                               </p>
                                                            </div>
                                                         </div>
                                                         <div className="trip-note-content">
                                                            {renderParagraph(
                                                               data?.description
                                                            )}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          )}
                                       </div>
                                    ) : (
                                       <div className="text-center">
                                          No records found
                                       </div>
                                    )}
                                 </Tab>
                                 <Tab
                                    eventKey="langauge"
                                    title={
                                       <span
                                          className={`tab-ll-content ${
                                             tipsTab !== "langauge" &&
                                             "disabled-btn"
                                          }`}
                                       >
                                          Learn Language
                                       </span>
                                    }
                                 >
                                    {learLangaugesList?.data?.data?.length >
                                    0 ? (
                                       <div className="learn-l-header px-3 mt-3">
                                          <div className="learn-l-container">
                                             <div className="google-translation-imag">
                                                <img
                                                   src={googleTranslation}
                                                   alt=""
                                                />
                                             </div>
                                             <div className="lear-korean-content">
                                                <h3>
                                                   Learn{" "}
                                                   {
                                                      learLangaugesList?.data
                                                         ?.data?.[0]?.language
                                                   }
                                                </h3>
                                                <p>
                                                   Memorize these basic{" "}
                                                   {
                                                      learLangaugesList?.data
                                                         ?.data?.[0]?.language
                                                   }{" "}
                                                   words to communicate with
                                                   locals and to surprise them
                                                   when they least expect.
                                                </p>
                                             </div>
                                             <div className="learn-blk-lan-hearder">
                                                {learLangaugesList?.data?.data?.map(
                                                   (data, index) => (
                                                      <div
                                                         key={index}
                                                         className="learn-blk-lan-content d-flex align-items-center justify-content-between"
                                                      >
                                                         <div className="learn-blk-lan d-flex gap-2 w-100">
                                                            <p className="m-0 text-muted w-50">
                                                               {
                                                                  data?.in_english
                                                               }
                                                            </p>
                                                            <p className="m-0 w-50">
                                                               {
                                                                  data?.in_language
                                                               }
                                                            </p>
                                                         </div>
                                                      </div>
                                                   )
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    ) : (
                                       <div className="text-center">
                                          <p>No records found</p>
                                       </div>
                                    )}
                                 </Tab>
                              </Tabs>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal>
            <Modal
               show={helpModalShow}
               onHide={() => setHelpModalShow(false)}
               keyboard={false}
               centered
               className="view-details-modal help-modal"
            >
               <Modal.Body>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                     <hr className="modal-top-hr_ m-0" />
                  </div>
                  <div className="mb-3 d-flex align-items-center justify-content-md-center justify-content-between me-2">
                     <p className="text-center modal-head mt-2">Emergency</p>
                     <Icon
                        className="modal-close-btn cursor-pointer d-md-none d-block"
                        onClick={() => {
                           setHelpModalShow(false);
                        }}
                        name={"Close"}
                     />
                  </div>
                  <p>For emergency, call</p>
                  {loading ? (
                     <Spinner height={30} />
                  ) : (
                     <div>
                        {!emergencyData?.data?.data?.length > 0 && (
                           <>
                              <p className="text-center my-4">
                                 No record found
                              </p>
                           </>
                        )}
                        <div className="shadow p-3 rounded-4">
                           {emergencyData?.data?.data?.map(
                              (data, index, array) => {
                                 if (data.phone_no === null) {
                                    return null; // Skip mapping this object
                                 }
                                 return (
                                    <div className="my-3" key={index}>
                                       <div className="d-flex align-items-center justify-content-between">
                                          <div>
                                             <p className="mb-0 help-head">
                                                {data?.purpose}
                                             </p>
                                             <p className="mb-0 text-muted">
                                                {data?.phone_no}
                                             </p>
                                          </div>
                                          <a href={`tel:${data?.phone_no}`}>
                                             <Icon
                                                className="shadow rounded-circle"
                                                name={"Call"}
                                             />
                                          </a>
                                       </div>
                                       {index !== array.length - 1 && (
                                          <hr
                                             className="m-0 mt-3"
                                             style={{ opacity: ".1" }}
                                          />
                                       )}
                                    </div>
                                 );
                              }
                           )}
                        </div>
                     </div>
                  )}
               </Modal.Body>
            </Modal>
            <Modal
               show={openCalendar}
               onHide={() => setOpenCalendar(false)}
               keyboard={false}
               className="calendar-modal"
               centered
            >
               <Modal.Body>
                  <div className="calendar-body">
                     <div className="modal-header mb-4 pt-5 mt-3 pb-3">
                        <div className="my-3 d-flex gap-md-0 gap-4 align-items-center justify-content-between">
                           <h3 className="title me-md-0 me-5 py-3">
                              Select your departure and return date
                           </h3>
                           <Icon
                              className="modal-close-btn cursor-pointer d-md-none d-block"
                              onClick={() => {
                                 setOpenCalendar(false);
                              }}
                              name={"Close"}
                           />
                        </div>
                     </div>
                     <DatePicker
                        selected={departureDate}
                        onChange={handleDateChange}
                        startDate={departureDate}
                        endDate={returnDate}
                        selectsRange
                        selectsDisabledDaysInRange
                        inline
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date()} // Set the minimum selectable date to the current date
                     />
                  </div>
               </Modal.Body>
               <Modal.Footer className="border-0">
                  <button
                     className={`${
                        returnDate === null || isDateLesserThan(returnDate)
                           ? "disabled-btn"
                           : ""
                     }`}
                     onClick={updateTripDateFn}
                     disabled={
                        returnDate === null || isDateLesserThan(returnDate)
                     }
                  >
                     Save Dates
                     {updateTripLoading && (
                        <span
                           className="spinner-border spinner-border-sm ms-3"
                           role="status"
                           aria-hidden="true"
                        ></span>
                     )}
                  </button>
               </Modal.Footer>
            </Modal>
            <Modal
               show={spendingFormShow}
               onHide={() => {
                  setSpendingFormShow(false);
                  setEditSpendingData({});
                  setIsSpendingEdit(false);
               }}
               keyboard={false}
               className="spending-modal"
               centered
            >
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
               >
                  {({ isSubmitting }) => (
                     <Form className="spending-form">
                        <Modal.Body>
                           <div className="d-flex flex-column justify-content-center align-items-center">
                              <hr className="modal-top-hr_ m-0" />
                           </div>

                           <div className="my-3 d-flex align-items-center justify-content-md-center justify-content-between me-2">
                              <p className="title text-center mt-2">
                                 Add Spending
                              </p>
                              <Icon
                                 className="modal-close-btn cursor-pointer  d-md-none d-block"
                                 onClick={() => {
                                    setSpendingFormShow(false);
                                    setEditSpendingData({});
                                    setIsSpendingEdit(false);
                                 }}
                                 name={"Close"}
                              />
                           </div>
                           <div className="form-group my-4">
                              <div className="d-flex flex-column spending-input bg-light">
                                 <label
                                    htmlFor="amount_spent"
                                    className="text-muted"
                                 >
                                    Enter Amount *
                                 </label>
                                 <Field
                                    className="bg-transparent form-control border-0 p-0"
                                    type="text"
                                    name="amount_spent"
                                    id="amount_spent"
                                    placeholder="Enter your spending amount"
                                 />
                              </div>
                              <div className="m-2 error-message">
                                 <ErrorMessage
                                    name="amount_spent"
                                    component="div"
                                    className="text-danger"
                                 />
                              </div>
                           </div>
                           <div className="form-group my-4">
                              <div className="d-flex flex-column spending-input bg-light">
                                 <label
                                    htmlFor="category_id"
                                    className="text-muted"
                                 >
                                    Select Category *
                                 </label>
                                 <Field
                                    as="select"
                                    className="bg-transparent form-control border-0 p-0"
                                    name="category_id"
                                    id="category_id"
                                 >
                                    <option value={0}>Select a category</option>
                                    {spendingCategoryList?.data?.data?.map(
                                       (data, index) => (
                                          <option key={index} value={data?.id}>
                                             {data?.name}
                                          </option>
                                       )
                                    )}
                                 </Field>
                              </div>
                              <div className="m-2 error-message">
                                 <ErrorMessage
                                    name="category_id"
                                    component="div"
                                    className="text-danger"
                                 />
                              </div>
                           </div>
                           <div className="form-group my-4">
                              <div className="d-flex flex-column spending-input bg-light">
                                 <label
                                    htmlFor="payment_method"
                                    className="text-muted"
                                 >
                                    Select Payment Method *
                                 </label>
                                 <Field
                                    as="select"
                                    className="bg-transparent form-control border-0 p-0"
                                    name="payment_method"
                                    id="payment_method"
                                 >
                                    <option value={0}>
                                       Select a payment method
                                    </option>
                                    <option value={1}>Cash</option>
                                    <option value={2}>Card</option>
                                 </Field>
                              </div>
                              <div className="m-2 error-message">
                                 <ErrorMessage
                                    name="payment_method"
                                    component="div"
                                    className="text-danger"
                                 />
                              </div>
                           </div>
                        </Modal.Body>
                        <Modal.Footer className="border-0 shadow bg-transparent">
                           <Button type="submit" disabled={isSubmitting}>
                              {isSubmitting
                                 ? `${
                                      isSpendingEdit
                                         ? "Updating..."
                                         : "Adding..."
                                   }`
                                 : `${isSpendingEdit ? "Update" : "Add"}`}
                           </Button>
                        </Modal.Footer>
                     </Form>
                  )}
               </Formik>
            </Modal>
            <Modal
               show={spendingShow}
               onHide={() => {
                  setSpendingShow(false);
                  setIsSpendingEdit(false);
               }}
               keyboard={false}
               className="spending-modal-one"
               centered
            >
               <Modal.Body className="p-0">
                  <div className="spending-head-bg pb-5">
                     <p className="title text-center text-light pt-3 pb-2">
                        Spendings
                     </p>{" "}
                     <Icon
                        className="modal-close-btn cursor-pointer position-absolute d-md-none d-block"
                        onClick={() => {
                           setSpendingShow(false);
                           setIsSpendingEdit(false);
                        }}
                        name={"Close"}
                     />
                     <div className="spending-head-inner bg-light mx-4 px-4 py-3">
                        <div className="d-flex align-items-center  justify-content-between">
                           <p className="m-0 message-text">
                              Set your budget for your trip
                           </p>
                           <h6 className="mb-0">$</h6>
                           <input
                              className="m-0 amount-text border-0 spending-total-input"
                              type="text"
                              id="total-amount"
                              value={totalAmount}
                              onChange={(event) => {
                                 const numericValue =
                                    event.target.value.replace(/[^0-9]/g, "");
                                 setTotalAmount(numericValue);
                              }}
                              style={{
                                 MozAppearance: "textfield",
                                 WebkitAppearance: "textfield",
                                 appearance: "textfield",
                                 paddingRight: "1px",
                              }}
                           />
                           <label htmlFor="total-amount">
                              {!isEditAmount && (
                                 <Icon
                                    className="cursor-pointer"
                                    name={"Edit"}
                                    height={20}
                                    width={20}
                                    onClick={() => setIsEditAmount(true)}
                                 />
                              )}
                           </label>
                           {isEditAmount && (
                              <FcCheckmark
                                 className="cursor-pointer"
                                 size={20}
                                 onClick={addAmountOnClick}
                              />
                           )}
                           <hr />
                        </div>
                        <div className="item-two">
                           <p className="m-0 so-far">Spending so far</p>
                           <p className="m-0 amount">
                              ${spendingId?.data?.data?.total_spent}
                           </p>
                           <ProgressBar
                              className="p-0 mt-2 w-100"
                              now={progressbarValue}
                              variant={`${progressbarValue > 100 && "danger"}`}
                           />
                        </div>
                        <div className="item-three d-flex align-items-center justify-content-between my-3">
                           <div className="d-flex align-items-center justify-content-center">
                              <p className="text m-0 me-1">Left to spend</p>
                              <p className="amount m-0">
                                 $
                                 {getLeftToSpend(
                                    totalAmount,
                                    spendingId?.data?.data?.total_spent
                                 )}
                              </p>
                           </div>
                           <p className="amount-two m-0">${totalAmount}</p>
                        </div>
                     </div>
                  </div>
                  <div className="m-3 map-item">
                     <p className="spendings my-2 mb-3 mb-0">Your Spendings</p>
                     {spendingsList?.data?.data?.map((data, index) => (
                        <div
                           className="box-shadow p-4 rounded-4 mb-3"
                           key={index}
                        >
                           <div className="d-flex align-items-center justify-content-between">
                              <p className="m-0">{data?.category?.name}</p>
                              <div className="d-flex align-items-center justify-content-center">
                                 <p className="amount me-2 mb-0">
                                    ${parseInt(data?.amount_spent)}
                                 </p>
                                 <Icon
                                    className="cursor-pointer"
                                    name={"Edit"}
                                    height={20}
                                    width={20}
                                    onClick={() => {
                                       setEditSpendingData(data);
                                       setSpendingFormShow(true);
                                       setIsSpendingEdit(true);
                                    }}
                                 />
                                 <div
                                    className="cursor-pointer ms-2 bg-transparent shadow d-flex align-items-center justify-content-center"
                                    onClick={() =>
                                       deleteSpendingOnClick(data?.id)
                                    }
                                 >
                                    <MdDeleteOutline size={18} color="red" />
                                 </div>
                              </div>
                           </div>
                           <div className="d-flex align-items-center justify-content-between">
                              <p className="category m-0">
                                 {data?.payment_method === 1 ? "Cash" : "Card"}
                              </p>
                              <div className="d-flex align-items-center justify-content-center">
                                 <p className="date_ mb-0 me-2">
                                    {moment(data?.created_at).format("MMM-YY")}
                                 </p>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </Modal.Body>
               <Modal.Footer className="p-0 mx-2 border-0">
                  <div className="d-flex align-items-center justify-content-center w-100">
                     <button
                        onClick={() => setSpendingFormShow(true)}
                        className="d-flex my-3 border-0 add-spending-button d-flex align-items-center justify-content-center px-4 w-100"
                     >
                        <Icon className="me-2" name={"AddCircle"} />
                        <p className="m-0">Add Spending</p>
                     </button>
                  </div>
               </Modal.Footer>
            </Modal>
            <ProductDetails
               prooductDetailShow={prooductDetailShow}
               setProductDetailShow={setProductDetailShow}
               productDetail={popupData}
               isSavedItenerary={isSavedItenerary}
               setIsItenerary={setIsItenerary}
               isFrom={"trips"}
            />
            <div className="d-md-none d-block tab-gl">
               <div>
                  <Tabs
                     className="d-flex tab-gllll-container p-2 gap-2"
                     activeKey={activeKey}
                     onSelect={(eventKey) => setActiveKey(eventKey)}
                  >
                     <Tab
                        eventKey="home"
                        title={
                           <span
                              className={`locationtab-img-btn bg-light ${
                                 activeKey !== "home" &&
                                 "transparent-opacity bg-transparent"
                              }`}
                           >
                              <Icon name={"Location_on"} />
                           </span>
                        }
                     >
                        <div className="col-12 my-3 d-md-none d-block">
                           {loading ? (
                              <Spinner height={50} />
                           ) : (
                              <div className="mx-sm-3 mx-2">
                                 {itenerysList?.data?.data?.length > 0 ? (
                                    <div>
                                       {itenerysList?.data?.data?.map(
                                          (data, index) => (
                                             <TripCard
                                                key={index}
                                                data={data}
                                                index={index}
                                                viewOnClick={viewOnClick}
                                                setDeleteModalShow={
                                                   setDeleteModalShow
                                                }
                                                setDeleteData={setDeleteData}
                                             />
                                          )
                                       )}
                                    </div>
                                 ) : (
                                    <div>
                                       <p className="no-data-text text-center">
                                          No itineraries
                                       </p>
                                    </div>
                                 )}
                              </div>
                           )}
                        </div>
                     </Tab>
                     <Tab
                        eventKey="profile"
                        title={
                           <span
                              className={`grid-view-img-btn bg-light ${
                                 activeKey !== "profile" &&
                                 "transparent-opacity bg-transparent"
                              }`}
                           >
                              <Icon name={"Grid_view"} />
                           </span>
                        }
                     >
                        <div className="col-md-4 p-3 col-12 more-detail-recomend d-md-none d-block">
                           <div className="row align-items-center justify-content-center box-shadow p-3 m-0">
                              <div className="col-12">
                                 <div className="d-flex align-items-center justify-content-start">
                                    <Icon name={"Explore"} />
                                    <p className="m-0 ms-2">
                                       More recommendations
                                    </p>
                                 </div>
                                 <p className="sub-head">
                                    Don't miss out on these places in{" "}
                                    {location?.state?.trip?.country?.name}
                                 </p>
                              </div>
                              <div className="row more-reccomend-container row-cols-sm-3 row-cols-2 p-0">
                                 <div
                                    className="col mb-3 p-1"
                                    onClick={() =>
                                       navigate(
                                          `/${userTrip?.data?.data?.country?.iso3}/all/places`,
                                          {
                                             state: {
                                                trip_details:
                                                   userTrip?.data?.data,
                                             },
                                          }
                                       )
                                    }
                                 >
                                    <div className="card h-100 w-100 p-3">
                                       <Icon name={"Places"} />
                                       <p>Places</p>
                                    </div>
                                 </div>
                                 <div
                                    className="col mb-3 p-1"
                                    onClick={() =>
                                       navigate(
                                          `/${userTrip?.data?.data?.country?.iso3}/all/events`,
                                          {
                                             state: {
                                                trip_details:
                                                   userTrip?.data?.data,
                                             },
                                          }
                                       )
                                    }
                                 >
                                    <div className="card h-100 w-100 p-3">
                                       <Icon name={"Events"} />
                                       <p>Events</p>
                                    </div>
                                 </div>
                                 <div
                                    className="col mb-3 p-1"
                                    onClick={() =>
                                       navigate(
                                          `/${userTrip?.data?.data?.country?.iso3}/all/food-drink`,
                                          {
                                             state: {
                                                trip_details:
                                                   userTrip?.data?.data,
                                             },
                                          }
                                       )
                                    }
                                 >
                                    <div className="card h-100 w-100 p-3">
                                       <Icon name={"Food_Drinks"} />
                                       <p>Food & Drink</p>
                                    </div>
                                 </div>
                                 <div
                                    className="col mb-3 p-1"
                                    onClick={() => setTipsModalShow(true)}
                                 >
                                    <div className="card h-100 w-100 p-3">
                                       <Icon name={"Tips"} />
                                       <p>Tips</p>
                                    </div>
                                 </div>
                                 <div
                                    className="col mb-3 p-1"
                                    onClick={() => setHelpModalShow(true)}
                                 >
                                    <div className="card h-100 w-100 p-3">
                                       <Icon name={"Emergency"} />
                                       <p>Emergency</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row m-0 mt-3">
                              <div className="box-shadow mt-md-4 m-0 more-detail-recomend-two p-sm-4 p-3">
                                 <div className="d-flex align-items-start justify-content-center flex-column">
                                    <div
                                       className="col-12 cursor-pointer d-flex align-items-center justify-content-between my-3"
                                       onClick={() => setSpendingShow(true)}
                                    >
                                       <div className="d-flex align-items-center gap-3 justify-content-start">
                                          <Icon name={"Spendings"} />
                                          <p className="m-0">Your spendings</p>
                                       </div>
                                       <Icon
                                          name={"arrow_forward"}
                                          height={20}
                                          width={20}
                                       />
                                    </div>
                                    <h2 className="total_spending">
                                       ${spendingId?.data?.data?.total_spent}
                                    </h2>
                                    <ProgressBar
                                       className="p-0 w-100"
                                       now={progressbarValue}
                                       variant={`${
                                          progressbarValue > 100 && "danger"
                                       }`}
                                    />
                                    <p className="my-4 m-0">
                                       <span className="left-spending me-3">
                                          Left to spend
                                       </span>
                                       <span className="ruppee">
                                          $
                                          {getLeftToSpend(
                                             totalAmount,
                                             spendingId?.data?.data?.total_spent
                                          )}
                                       </span>
                                    </p>
                                 </div>
                                 <p className="text-end text-muted m-0">
                                    $ {totalAmount}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </Tab>
                  </Tabs>
               </div>
            </div>
         </div>
      </>
   );
}
