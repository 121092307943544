import React from "react";
import MobileTopHeader from "../../components/MobileTopHeader";

export default function HelpMobile() {
   return (
      <div>
         <MobileTopHeader title={"Help Center"} />
         <div className="help-modal-home px-4">
            <div>
               <div className="cancellation-policy pt-4 pb-2 mt-4 cancellation-policy-mobile box-shadow">
                  <p className="content">
                     Please email your feedback, complaints and suggestions to{" "}
                     <a
                        href="http://care@trundle.world"
                        target="_blank"
                        rel="noreferrer"
                     >
                        care@trundle.world
                     </a>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}
