import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import home from "../../assets/images/background/Banner_Web.svg";
import eventImage from "../../assets/images/icons/home-events.svg";
import {
   getAvailableCountries,
   getCountries,
   getHomeData,
} from "../../redux/slices/placesSlices";
import { getActiveTrips, planTrip } from "../../redux/slices/tripSlices";
import { addItenery, getItenerys } from "../../redux/slices/itenerySlices";
import useAuth from "../../constants/useAuth";
import SliderComponent from "../slider/Slider";
import Icon from "../../assets/Icons";
import {
   VerifiedHeaderBottom,
   ProductDetails,
   TripTypeModal,
   DesinationModal,
   YourLocationModal,
   AvalailableTrips,
   EventPopup,
   HomeSection,
} from "../../components";
import Skeleton from "react-loading-skeleton";

const PlaceCardSkeleton = () => (
   <div className="place-card box-shadow ms-1">
      <Skeleton height={128} width={192} borderRadius={15} />
      <div className="p-3">
         <Skeleton height={20} width={150} borderRadius={5} />
         <Skeleton height={15} width={120} borderRadius={5} />
         <Skeleton height={15} width={100} borderRadius={5} className="mt-3" />
      </div>
   </div>
);

const EventCardSkeleton = () => (
   <div
      className="ms-1"
      style={{
         boxShadow: "0 6px 11px 0 rgba(0, 0, 0, 0.1)",
         borderRadius: "15px",
      }}
   >
      <Skeleton height={136} width={332} borderRadius={15} />
   </div>
);

export default function Home() {
   const navigate = useNavigate();

   const [authData] = useAuth();

   const dispatch = useDispatch();

   const [prooductDetailShow, setProductDetailShow] = useState(false);

   const [tripTypeModalShow, setTripTypeModalShow] = useState(false);

   const [tripType, setTripType] = useState("new_trip");

   const [desinationModalShow, setDesinationModalShow] = useState(false);

   const { planTripLoading } = useSelector((state) => state.trips);

   const [desitinationCountry, setDesinationCountry] = useState(null);

   const [departureCountry, setDepartureCountry] = useState(null);

   const [selectedValue, setSelectedValue] = useState(null);

   const [selectedCountryId, setSelectedCountryId] = useState(null);

   const [yourLocationShow, setYourLocationShow] = useState(false);

   const [locationId, setLocationId] = useState(null);

   const [popupData, setPopupData] = useState({});

   const [tripsModalShow, setTripsModalShow] = useState(false);

   const [tripId, setTripId] = useState(null);

   const [isItenerary, setIsItenerary] = useState(false);

   const [eventPopup, setEventPopup] = useState({});

   const [eventShow, setEventShow] = useState(false);

   const [category, setCategory] = useState(null);

   const { availableCountriesList, homeDataList, countriesList } = useSelector(
      (state) => state.places
   );

   const { userActiveTripsList } = useSelector((state) => state.trips);

   const [iso3, setIso3] = useState("");

   const [countryLoading, setCountriesLoading] = useState();

   const [pageLoading, setPageLoading] = useState();

   useEffect(() => {
      setCountriesLoading(true);
      setPageLoading(true);
      const fetchData = async () => {
         const response = await dispatch(getAvailableCountries());
         if (response?.payload?.status === 200) {
            setCountriesLoading(false);
            const countryIdFromLocalStorage =
               localStorage.getItem("country_id");
            const countryId =
               countryIdFromLocalStorage ||
               response?.payload?.data?.data?.[0]?.id;

            const iso3FromLocalStorage = localStorage.getItem("iso3");
            const iso3 =
               iso3FromLocalStorage || response?.payload?.data?.data?.[0]?.iso3;

            const res = await dispatch(getHomeData(iso3));
            if (res?.payload?.status === 200) {
               setPageLoading(false);
            }
            localStorage.setItem("country_id", countryId);
            localStorage.setItem("iso3", iso3);
            setSelectedCountryId(countryId);
            setIso3(iso3);
         }
      };
      fetchData();
      if (authData?.isAuthenticated) {
         dispatch(getActiveTrips());
         dispatch(getCountries());
      }
   }, [authData?.isAuthenticated, dispatch]);

   const hadlePlaceOnClick = (id, category) => {
      if (category === 1) {
         const filteredLocation = homeDataList?.data?.data?.places?.filter(
            (data) => data?.id === id
         );
         setPopupData(filteredLocation?.[0]);
         setProductDetailShow(true);
      } else if (category === 2) {
         const filteredLocation =
            homeDataList?.data?.data?.foods_and_drinks?.filter(
               (data) => data?.id === id
            );
         setPopupData(filteredLocation?.[0]);
         setProductDetailShow(true);
      } else if (category === 3) {
         const filteredLocation = homeDataList?.data?.data?.events?.filter(
            (data) => data?.id === id
         );
         setEventShow(true);
         setEventPopup(filteredLocation?.[0]);
      }
      setLocationId(id);
      setIsItenerary(true);
   };

   const _planTrip = async () => {
      const data = {
         country_id: isItenerary ? selectedCountryId : desitinationCountry,
         departure_country_id: departureCountry,
      };
      const response = await dispatch(planTrip(data));
      if (response?.payload?.data?.status) {
         if (isItenerary) {
            const addToItenery = async () => {
               const popupDataId =
                  category === 3 ? eventPopup?.id : popupData?.id;
               const data = {
                  tripId: response?.payload?.data?.trip_id,
                  locationId: popupDataId,
               };
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  navigate(`/trip-detail/${response?.payload?.data?.trip_id}`);
               }
               setTripsModalShow(false);
               toast.success("Location added successfully");
               setCategory(null);
            };
            addToItenery();
         } else {
            navigate(`trip-detail/${response?.payload?.data?.trip_id}`);
         }
         toast.success(response?.payload?.data?.message);
         setDesinationModalShow(false);
         setDesinationCountry(null);
         setSelectedValue(null);
         dispatch(getActiveTrips());
      } else if (response?.payload?.status === 400) {
         toast.error(response?.payload?.data?.message);
         setDesinationModalShow(false);
         setDesinationCountry(null);
         setIsItenerary(false);
         setSelectedValue(null);
      }
   };

   const addToItenery = async () => {
      const response = await dispatch(getItenerys(tripId));
      if (response?.payload?.status === 200) {
         const popupDataId = category === 3 ? eventPopup?.id : popupData?.id;
         const previousTripIteneraries = response?.payload?.data?.data;
         const isIteneraryPresent = previousTripIteneraries?.some(
            (itinerary) => itinerary?.location?.id === popupDataId
         );
         if (isIteneraryPresent) {
            setTripsModalShow(false);
            toast.error("This location is already present in itineraries");
         } else {
            const data = { tripId: tripId, locationId: popupDataId };
            const _addItenery = async () => {
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  navigate(`trip-detail/${tripId}`);
                  setTripsModalShow(false);
                  toast.success(response_?.payload?.data?.message);
                  setCategory(null);
               }
            };
            _addItenery();
         }
      }
   };

   const countryOnClick = async (country) => {
      setPageLoading(true);
      const res = await dispatch(getHomeData(country?.iso3));
      if (res?.payload?.status === 200) {
         setPageLoading(false);
         setSelectedCountryId(country?.id);
         setIso3(country?.iso3);
         localStorage.setItem("country_id", country?.id);
         localStorage.setItem("iso3", country?.iso3);
      }
   };

   const handleSelectChange = (selectedOption) => {
      setSelectedValue(selectedOption);
      setDepartureCountry(selectedOption?.country_id);
   };

   const options = countriesList?.data?.data?.map((data, index) => ({
      value: data.name,
      label: (
         <div key={index} className="r-img-lael cursor-pointer">
            <img
               src={data.flag}
               alt={data.name}
               style={{ width: "24px", marginRight: "10px" }}
            />
            {data.name}
         </div>
      ),
      country_id: data.id,
   }));

   return (
      <div>
         <ProductDetails
            productDetail={popupData}
            setProductDetailShow={setProductDetailShow}
            prooductDetailShow={prooductDetailShow}
            setTripTypeModalShow={setTripTypeModalShow}
            setIsItenerary={setIsItenerary}
            isFrom={"home"}
         />
         <TripTypeModal
            setTripTypeModalShow={setTripTypeModalShow}
            tripTypeModalShow={tripTypeModalShow}
            setTripType={setTripType}
            tripType={tripType}
            setDesinationModalShow={setDesinationModalShow}
            setYourLocationShow={setYourLocationShow}
            setTripsModalShow={setTripsModalShow}
            selectedCountryId={selectedCountryId}
            availableTrips={userActiveTripsList}
            setIsItenerary={setIsItenerary}
         />
         <DesinationModal
            show={desinationModalShow}
            setShow={setDesinationModalShow}
            setDesinationCountry={setDesinationCountry}
            desitinationCountry={desitinationCountry}
            planTripLoading={planTripLoading}
            _planTrip={_planTrip}
            availableCountriesList={availableCountriesList}
            selectedCountryId={selectedCountryId}
            isItenerary={isItenerary}
            setDepartureCountry={setDepartureCountry}
         />
         <YourLocationModal
            show={yourLocationShow}
            setShow={setYourLocationShow}
            options={options}
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
            departureCountry={departureCountry}
            setDesinationModalShow={setDesinationModalShow}
            isItenerary={isItenerary}
            _planTrip={_planTrip}
            setDepartureCountry={setDepartureCountry}
            setIsItenerary={setIsItenerary}
         />
         <EventPopup
            show={eventShow}
            setShow={setEventShow}
            eventPopup={eventPopup}
            locationId={locationId}
            setTripTypeModalShow={setTripTypeModalShow}
            setIsItenerary={setIsItenerary}
            isFrom={"home"}
         />
         <AvalailableTrips
            show={tripsModalShow}
            setTripsModalShow={setTripsModalShow}
            setTripId={setTripId}
            tripId={tripId}
            addToItenery={addToItenery}
            selectedCountryId={selectedCountryId}
            availableTripsCount
            userActiveTripsList={userActiveTripsList}
         />
         <div
            className="container-fluid main-banner p-0 position-relative d-flex align-items-center justify-content-center"
            style={{
               backgroundImage: `url('${home}')`,
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               backgroundPosition: "center center",
            }}
         >
            <div className="container">
               <div className="documentry-hero-container flex-column d-flex align-items-md-start align-items-center justify-content-center w-md-50 w-100">
                  <h2 className="m-sm-3 mx-4 home-banner-text text-light">
                     Get a personalized itinerary <br /> for your next trip
                  </h2>
                  <button
                     className="contact-us-btn border-0 ms-lg-3"
                     onClick={() => navigate("/contact-us")}
                  >
                     Contact Us
                  </button>
               </div>
            </div>
            <div
               className="container position-absolute slider-mobile-top"
               style={{ top: "350px" }}
            >
               {authData?.isAuthenticated && (
                  <SliderComponent
                     setYourLocationShow={setYourLocationShow}
                     activeTrips={userActiveTripsList?.data?.data}
                  />
               )}
            </div>
         </div>

         <div
            className={`container ${
               !authData?.isAuthenticated ? "pt-lg-5 pt-md-3 pt-2" : "pt-5"
            }  mt-0 d-flex flex-column gap-md-4 gap-3 ${
               authData?.isAuthenticated && "mt-5"
            }`}
         >
            <p
               className="home-text-width"
               style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "32px",
                  paddingBottom: "20px",
               }}
            >
               Choose the country you're travelling to and add interesting
               places to your itinerary
            </p>
            <div className="home_country_slide">
               <div className="d-flex gap-3 home_country_slider  justify-content-md-start justify-content-evenly pt-2">
                  {!countryLoading ? (
                     <>
                        {availableCountriesList?.data?.data?.map(
                           (country, index) => (
                              <label
                                 key={index}
                                 className={
                                    localStorage?.getItem("country_id") ==
                                    country?.id
                                       ? "country-tab cursor-pointer"
                                       : "country-tab-btn cursor-pointer"
                                 }
                              >
                                 <input
                                    type="radio"
                                    name="country"
                                    value={country?.id}
                                    checked={selectedCountryId === country?.id}
                                    onChange={() => {
                                       countryOnClick(country);
                                    }}
                                 />
                                 <img src={country?.flag} alt="" srcSet="" />
                                 <p className="m-0">{country?.name}</p>
                              </label>
                           )
                        )}
                     </>
                  ) : (
                     <>
                        {Array.from({ length: 5 }).map((_, index) => (
                           <Skeleton
                              key={index}
                              height={54}
                              width={190}
                              borderRadius={30}
                           />
                        ))}
                     </>
                  )}
               </div>
            </div>
         </div>
         {pageLoading ? (
            <div className="container mb-4">
               {[
                  { title: "Places", count: 5 },
                  { title: "Food & Drink", count: 5 },
                  { title: "Events", count: 3 },
               ].map((section, sectionIndex) => (
                  <div key={sectionIndex}>
                     <div className="place-header-content">
                        <p className="m-0">{section.title}</p>
                     </div>
                     <div className="d-flex gap-2 place-wrap">
                        {Array.from({ length: section.count }).map((_, index) =>
                           sectionIndex === 2 ? (
                              <EventCardSkeleton key={index} />
                           ) : (
                              <PlaceCardSkeleton key={index} />
                           )
                        )}
                     </div>
                  </div>
               ))}
            </div>
         ) : (
            <div className="container d-flex flex-column pb-md-3 pb-5 mb-md-0 mb-5">
               {homeDataList?.data?.data?.places?.length > 0 && (
                  <HomeSection
                     title="Places"
                     items={homeDataList.data.data.places}
                     onClickCallback={hadlePlaceOnClick}
                     buttonText="Show More"
                     navigateCallback={() => navigate(`/${iso3}/all/places`)}
                  />
               )}
               {homeDataList?.data?.data?.foods_and_drinks?.length > 0 && (
                  <HomeSection
                     title="Food & Drink"
                     items={homeDataList.data.data.foods_and_drinks}
                     onClickCallback={hadlePlaceOnClick}
                     buttonText="Show More"
                     navigateCallback={() =>
                        navigate(`/${iso3}/all/food-drink`)
                     }
                  />
               )}
               {homeDataList?.data?.data?.events?.length > 0 && (
                  <section>
                     <div className="place-header-content">
                        <p className="m-0">Events</p>
                     </div>
                     <div className="place-wrap">
                        {homeDataList?.data?.data?.events?.map(
                           (event, index) => (
                              <div
                                 key={index}
                                 className="card weekend-header cursor-pointer"
                                 onClick={() => {
                                    hadlePlaceOnClick(
                                       event?.id,
                                       event?.category
                                    );
                                    setCategory(3);
                                 }}
                              >
                                 <img src={eventImage} alt="" srcSet="" />
                                 <div className="card-img-overlay">
                                    <div className="viewmodal-img d-flex h-100">
                                       <div className="ms-2 d-flex align-items-start justify-content-between h-100 flex-column">
                                          <div>
                                             <p className="m-0 bio-text">
                                                {event?.name_of_location}
                                             </p>
                                             <p className="m-0 date-text">
                                                {event?.event_date}
                                             </p>
                                          </div>

                                          <div className="d-flex gap-2">
                                             <div className="seoull-content">
                                                <p className="m-0">
                                                   {event?.city?.name}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           )
                        )}
                        <div className="weekend-show">
                           <button
                              className="show-more-btn"
                              onClick={() => navigate(`/${iso3}/all/events`)}
                           >
                              <Icon name="Show_more" />
                              <p className="mt-3">Show More</p>
                           </button>
                        </div>
                     </div>
                  </section>
               )}
            </div>
         )}
         <div className="d-md-none d-sm-block">
            <VerifiedHeaderBottom />
         </div>
      </div>
   );
}
