import React, { useState } from "react";
import OtpInput from "react-otp-input";
import alert from "../../assets/images/icon/alert.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser, loginUser } from "../../redux/slices/authSlices";
import logo from "../../assets/images/logo/logo.png";

export default function OtpVerification() {
   const location = useLocation();

   const dispatch = useDispatch();

   const navigate = useNavigate();

   const [otp, setOtp] = useState("");

   const { otpLoading, loading } = useSelector((state) => state?.userData);

   const [isResendOtp, setIsResendOtp] = useState(false);

   const submitOnClick = async () => {
      const authData = {
         email: location?.state?.email,
         otp: otp,
      };

      const response = await dispatch(verifyUser(authData));
      if (response?.payload) {
         setOtp("");
         setIsResendOtp(false);
      }
      if (response?.payload?.data?.status) {
         navigate("/");
      }
   };

   return (
      <div>
         <div className="container-fluid box-shadow">
            <div className="container d-flex flex-md-row flex-sm-column flex-column align-items-center justify-content-between py-3 gap-sm-3 gap-2">
               <div
                  className="ellipse-image d-flex  align-items-center justify-content-md-start justify-content-center"
                  onClick={() => navigate("/")}
               >
                  <a className="text-decoration-none d-flex gap-2 align-items-center justify-content-center cursor-pointer">
                     <img src={logo} alt="" srcSet="" />
                     <p className="logotext m-0">trundle</p>
                  </a>
               </div>
               <div className="d-flex  align-items-center justify-content-center d-md-block d-none">
                  <div>
                     {location?.pathname === "/login" ? (
                        <ul className="d-flex gap-2 m-0 align-items-center justify-content-center">
                           <li className="list-group">
                              <p className="dont-account text-muted m-0 me-2">
                                 Don’t have an account?
                              </p>
                           </li>
                           <li className="list-group">
                              <button
                                 className="p-button"
                                 onClick={() => navigate("/sign-up")}
                              >
                                 <a className="text-decoration-none fw-bold">
                                    Sign Up
                                 </a>
                              </button>
                           </li>
                        </ul>
                     ) : (
                        <ul className="d-flex gap-2 m-0 align-items-center justify-content-center">
                           <li className="list-group">
                              <p className="dont-account text-muted m-0 me-2">
                                 Already have an account?
                              </p>
                           </li>
                           <li className="list-group">
                              <button
                                 className="p-button"
                                 onClick={() => navigate("/login")}
                              >
                                 <a className="text-decoration-none fw-bolder">
                                    Sign In
                                 </a>
                              </button>
                           </li>
                        </ul>
                     )}
                  </div>
               </div>
            </div>
         </div>
         <div className="my-5">
            <div className="otp-v-header main box-shadow">
               <div className="otp-v-container">
                  <div className="otp-v-content text-md-start text-center px-sm-2">
                     <h3>Code Verification</h3>
                     <p> Code sent to {location?.state?.email} </p>
                  </div>
                  <div className="otp-input-header mx-sm-2">
                     <div className="otp-input  p-0 container my-4">
                        <OtpInput
                           value={otp}
                           onChange={setOtp}
                           numInputs={6}
                           renderSeparator={<span></span>}
                           renderInput={(props) => (
                              <input type="number" {...props} />
                           )}
                        />
                     </div>
                     <div className="resend-otp-btn d-flex align-items-center justify-content-center mt-4">
                        <button
                           onClick={() => {
                              dispatch(
                                 loginUser({ email: location?.state?.email })
                              );
                              setIsResendOtp(true);
                           }}
                        >
                           Resend Code
                           {isResendOtp && otpLoading && (
                              <span
                                 className="spinner-border spinner-border-sm ms-2"
                                 role="status"
                                 aria-hidden="true"
                              ></span>
                           )}
                        </button>
                     </div>
                  </div>
                  <div className="alert-container">
                     <div className="alert-content">
                        <img src={alert} alt="" />
                        <p>
                           Signing up is your acceptance of Trundle’s{" "}
                           <span
                              className="text-primary cursor-pointer"
                              onClick={() => navigate("/terms-conditions")}
                           >
                              Terms & Conditions
                           </span>{" "}
                           and{" "}
                           <span
                              className="text-primary cursor-pointer"
                              onClick={() => navigate("/privacy-policy")}
                           >
                              Privacy Policy
                           </span>{" "}
                        </p>
                     </div>
                     <button
                        className="sub-al-btn"
                        onClick={submitOnClick}
                        disabled={otp?.length !== 6}
                     >
                        {loading ? (
                           <div>
                              <span
                                 className="spinner-border spinner-border-sm me-2"
                                 role="status"
                                 aria-hidden="true"
                              ></span>
                              Loading...
                           </div>
                        ) : (
                           "Submit"
                        )}{" "}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
