import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { postContactUs } from "../redux/slices/userSlices";
import { getCountries } from "../redux/slices/placesSlices";
import FormField from "../components/FormField";
import SelectField from "../components/SelectField";
import contactAsBg from "../assets/images/background/enquiry-bg-img.png";

const travellingPurposeOptions = [
   { value: 1, label: "Culture" },
   { value: 2, label: "Food" },
   { value: 3, label: "Entertainment" },
   { value: 4, label: "Leisure" },
   { value: 5, label: "Shopping" },
   { value: 6, label: "Religious" },
   { value: 7, label: "Nature" },
   { value: 8, label: "Nightlife" },
];

const monthList = [
   { value: 1, label: "January" },
   { value: 2, label: "February" },
   { value: 3, label: "March" },
   { value: 4, label: "April" },
   { value: 5, label: "May" },
   { value: 6, label: "June" },
   { value: 7, label: "July" },
   { value: 8, label: "August" },
   { value: 9, label: "September" },
   { value: 10, label: "October" },
   { value: 11, label: "November" },
   { value: 12, label: "December" },
];

const countriesList = [
   { value: 1, name: "Japan" },
   { value: 2, name: "South Korea" },
   { value: 3, name: "Singapore" },
   { value: 4, name: "Thailand" },
   { value: 5, name: "India" },
   { value: 6, name: "Vietnam" },
   { value: 7, name: "Malaysia" },
];

const radioOptions = [
   { id: 1, name: "Solo" },
   { id: 2, name: "With friends" },
   { id: 3, name: "As a couple" },
   { id: 4, name: "With kids under 18 years old" },
];

export default function ContactUs() {
   // const { countriesList } = useSelector((state) => state.places);

   const [loading, setLoading] = useState(false);

   const dispatch = useDispatch();

   const validationSchema = Yup.object().shape({
      full_name: Yup.string().required("Full name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      // phone_number: Yup.string()
      //    .matches(/^[0-9]*$/, "Invalid phone number")
      //    .required("Phone number is required"),
      // phone_code: Yup.object().required("Phone code is required"),
      travelling_to: Yup.object().required("Travelling to is required"),
      travel_purpose: Yup.array()
         .min(1, "At least one travel purpose must be selected")
         .required("Travelling purpose is required"),
      how_travelling: Yup.string().required(
         "How are you travelling is required"
      ),
      travel_month: Yup.object().required("Travelling month is required"),
   });

   const options = countriesList?.map((data, index) => ({
      value: data.name,
      label: (
         <div key={index} className="r-img-lael cursor-pointer">
            {data.name}
         </div>
      ),
   }));

   // const couuntryCodeList = countriesList?.data?.data?.map((data, index) => ({
   //    value: data.phone_code,
   //    label: (
   //       <div key={index} className="r-img-lael cursor-pointer">
   //          +{data?.phone_code}
   //       </div>
   //    ),
   // }));

   const formik = useFormik({
      initialValues: {
         full_name: "",
         email: "",
         // phone_number: "",
         travelling_to: null,
         travel_purpose: [],
         how_travelling: "",
         travel_month: null,
         // phone_code: null,
      },
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         setLoading(true);
         setSubmitting(false);
         const formData = new FormData();
         const travelPurpose = values?.travel_purpose?.join(",");
         formData.append("full_name", values?.full_name);
         formData.append("email", values?.email);
         formData.append("travelling_to", values?.travelling_to?.value);
         formData.append("travel_purpose", travelPurpose);
         formData.append("how_travelling", values?.how_travelling);
         formData.append("travel_month", values?.travel_month?.label);
         // formData.append("phone_code", `+1${values?.phone_code?.value}`);
         // formData.append("phone_number", values?.phone_number);
         const res = await dispatch(postContactUs(formData));
         if (res?.payload?.status) {
            formik.resetForm();
            setLoading(false);
         }
      },
   });

   useEffect(() => {
      dispatch(getCountries());
   }, [dispatch]);

   return (
      <div className="container-fluid d-flex align-items-center justify-content-center px-0">
         <div
            className="mx-3 d-md-block d-none"
            style={{
               position: "absolute",
               zIndex: -1,
            }}
         >
            <img src={contactAsBg} style={{ width: "100%" }} alt="" />
         </div>
         <div className="row contact-us-container box-shadow my-lg-5 my-md-4 my-sm-3 my-0">
            <p
               className="m-0 text-center"
               style={{
                  fontSize: 21,
                  fontWeight: 600,
               }}
            >
               Let us know details about your travel plans and our team will
               prepare a detailed itinerary for you.
            </p>
            <form
               className="contact-us-form mt-5 flex-column d-flex p-0"
               onSubmit={formik.handleSubmit}
            >
               <FormField
                  name="full_name"
                  label="Full Name"
                  type="text"
                  placeholder="Type your full name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.full_name}
                  touched={formik.touched.full_name}
                  error={formik.errors.full_name}
               />
               <FormField
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Type your email address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  touched={formik.touched.email}
                  error={formik.errors.email}
               />
               {/* <div>
                  <label htmlFor="phone_number" className="contact-form-label">
                     Phone No
                  </label>
                  <div
                     style={{
                        display: "flex",
                        outline: "none",
                        border: "1px solid #eee",
                        borderRadius: "10px",
                     }}
                  >
                     <div
                        style={{
                           width: "20%",
                        }}
                        className="country-code-contact-us"
                     >
                        <Select
                           className="form-label-header form-label-header-outline"
                           options={couuntryCodeList}
                           placeholder="+1"
                           onChange={(data) => {
                              formik.setFieldValue("phone_code", data);
                           }}
                           onBlur={formik.handleBlur}
                           value={formik.values.phone_code}
                        />
                     </div>
                     <input
                        type="text"
                        name="phone_number"
                        className="form-control border-0"
                        placeholder="Type your phone number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_number}
                     />
                  </div>
                  {formik.touched.phone_number &&
                     formik.errors.phone_number && (
                        <div className="error text-danger">
                           {formik.errors.phone_number}
                        </div>
                     )}
                  {formik.touched.phone_code && formik.errors.phone_code && (
                     <div className="error text-danger">
                        {formik.errors.phone_code}
                     </div>
                  )}
               </div> */}
               <div className="position-relative">
                  <SelectField
                     label="Travelling To"
                     name="travelling_to"
                     options={options}
                     value={formik.values.travelling_to}
                     onChange={(fieldName, data) => {
                        formik.setFieldValue(fieldName, data);
                     }}
                     onBlur={formik.handleBlur}
                     error={
                        formik.touched.travelling_to &&
                        formik.errors.travelling_to
                     }
                     placeholder="Select where you are travelling to"
                  />
               </div>
               <div>
                  <label className="contact-form-label">
                     Purpose of Travel (select multiple)
                  </label>
                  <div>
                     {travellingPurposeOptions.map((option, index) => (
                        <label
                           style={{
                              fontSize: 15,
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 10,
                              paddingBottom: 10,
                              cursor: "pointer",
                              border: "1px solid rgb(209 188 251)",
                              borderRadius: 10,
                              background: formik.values.travel_purpose.includes(
                                 option.label
                              )
                                 ? "#f0e9ff"
                                 : "none",
                           }}
                           key={index}
                           className="m-1"
                        >
                           <input
                              type="checkbox"
                              // style={{ display: "none" }}
                              name="travel_purpose"
                              value={option.label}
                              onChange={(e) => {
                                 const newValue = [
                                    ...formik.values.travel_purpose,
                                 ];
                                 if (e.target.checked) {
                                    newValue.push(option.label);
                                 } else {
                                    const index = newValue.indexOf(
                                       option.label
                                    );
                                    if (index !== -1) {
                                       newValue.splice(index, 1);
                                    }
                                 }
                                 formik.setFieldValue(
                                    "travel_purpose",
                                    newValue
                                 );
                              }}
                              onBlur={formik.handleBlur}
                              checked={formik.values.travel_purpose.includes(
                                 option.label
                              )}
                           />
                           <span className="m-2">{option.label}</span>
                        </label>
                     ))}
                  </div>
                  {formik.touched.travel_purpose &&
                     formik.errors.travel_purpose && (
                        <div className="error text-danger">
                           {formik.errors.travel_purpose}
                        </div>
                     )}
               </div>

               <div>
                  <label className="contact-form-label">
                     How are you travelling? (select one)
                  </label>
                  <div>
                     {radioOptions.map((option, index) => (
                        <label
                           style={{
                              fontSize: 15,
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 10,
                              paddingBottom: 10,
                              border: "1px solid rgb(209 188 251)",
                              borderRadius: 10,
                              cursor: "pointer",
                              background:
                                 formik.values.how_travelling === option?.name
                                    ? "#f0e9ff"
                                    : "none",
                           }}
                           key={index}
                           className="m-1"
                        >
                           <input
                              type="radio"
                              name="how_travelling"
                              value={option.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={
                                 formik.values.how_travelling === option.name
                              }
                           />
                           {option.name}
                        </label>
                     ))}
                  </div>
                  {formik.touched.how_travelling &&
                     formik.errors.how_travelling && (
                        <div className="error text-danger">
                           {formik.errors.how_travelling}
                        </div>
                     )}
               </div>
               <div className="position-relative">
                  <SelectField
                     label="Probable Month of Travel"
                     name="travel_month"
                     options={monthList}
                     value={formik.values.travel_month}
                     onChange={(fieldName, data) => {
                        formik.setFieldValue(fieldName, data);
                     }}
                     onBlur={formik.handleBlur}
                     error={
                        formik.touched.travel_month &&
                        formik.errors.travel_month
                     }
                     placeholder="Select travelling month"
                     menuPlacement="top"
                  />
               </div>
               <button
                  className="contact-submit border-0 text-light"
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting || loading}
               >
                  Submit{" "}
                  {loading && (
                     <div
                        className="spinner-border text-light spinner-border-sm ms-3"
                        role="status"
                     />
                  )}
               </button>
            </form>
         </div>
      </div>
   );
}
