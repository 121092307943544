import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getEventsApi = (data) => {
   const params = {};

   if (data?.iso3 !== "") {
      params.iso3 = data?.iso3;
   }

   if (data?.slug !== "" && data?.slug !== "all") {
      params.slug = data?.slug;
   }

   if (data?.page !== undefined) {
      params.page = data?.page + 1;
   }

   if (Array.isArray(data?.type) && data?.type.length > 0) {
      params.type = data?.type.join(",");
   }

   const filteredParams = {};

   for (const key in params) {
      if (params[key] !== undefined && params[key] !== "") {
         filteredParams[key] = params[key];
      }
   }

   const accessToken = localStorage?.getItem("token");

   const config = {
      params: filteredParams,
   };

   if (accessToken) {
      config.headers = {
         Authorization: `Bearer ${accessToken}`,
      };
   }
   return axiosConfig.get(Config.GET_EVENTS_API_URL, config);
};

export const getEventsFiltersApi = (country_id) => {
   return axiosConfig.get(
      Config.GET_EVENTS_FILTER_API_URL + `?iso3=${country_id}`
   );
};
