/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { MdBookmarkAdded, MdDeleteOutline } from "react-icons/md";
import Icon from "../assets/Icons";
import noImage from "../assets/images/icon/no-image.png";
import mapIcon from "../assets/images/icon/plantrip/map-button.svg";
import dotsIcon from "../assets/images/icon/plantrip/3dot-button.svg";
import Image_ from "./Image";

export default function LocationCard({
   _deleteBookmark,
   locationDetails,
   viewOnClick,
   isBookmark,
}) {
   const locationDetail = isBookmark
      ? locationDetails?.location
      : locationDetails;

   return (
      <div className="col-lg-4 col-md-6 col-12 my-2 position-relative">
         <div className="card h-100 border-0 justify-content-between box-shadow">
            {isBookmark && (
               <>
                  <div className="bookmarked-bg d-flex align-items-center justify-content-center position-absolute py-1 px-2 gap-2">
                     <MdBookmarkAdded color="white" />
                     <p className="m-0 text-light">Bookmarked</p>
                  </div>
                  <div
                     className="cursor-pointer position-absolute bookmark-delete-button d-flex align-items-center justify-content-center"
                     onClick={() => _deleteBookmark(locationDetails)}
                  >
                     <MdDeleteOutline size={18} color="red" />
                  </div>
               </>
            )}
            <Image_
               imageSrc={
                  locationDetail.locationimage_set?.[0]
                     ? locationDetail.locationimage_set?.[0]?.image
                     : noImage
               }
               styles={{
                  bordeRadius: "15px",
                  width: "100%",
                  height: "131px",
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "15px",
               }}
            />
            {locationDetail?.locationimage_set?.[0]?.is_representational && (
               <p className="is-representational-bookmark container text-center position-absolute text-light fst-italic">
                  For Representational Purpose
               </p>
            )}
            <div className="row no-gutters h-100">
               <div className="card-body p-4 d-flex flex-column align-items-start justify-content-between">
                  <div>
                     <h5 className="card-title places-card-title">
                        {locationDetail?.name_of_location}
                     </h5>
                     <p className="m-0 text-muted my-1">
                        {locationDetail?.type?.name &&
                           locationDetail?.type?.name}
                        {locationDetail?.type_of_food_drinks &&
                           locationDetail?.type_of_food_drinks}
                     </p>
                     <div className="d-flex">
                        <Icon
                           name={"Location"}
                           className="me-2"
                           width={24}
                           height={24}
                        />
                        <p className="card-text text-muted">
                           {locationDetail?.address}
                        </p>
                     </div>
                     <div className="my-2">
                        {locationDetail?.cost_description && (
                           <div className="d-flex">
                              <Icon
                                 name={"Entry"}
                                 className="me-2"
                                 width={24}
                                 height={24}
                              />
                              <p className="card-text text-muted">
                                 {locationDetail?.cost_description}
                              </p>
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center w-100 bookmarks-bottom-buttons">
                     <button
                        className="places-tile-button w-100 m-0 py-3 me-2"
                        onClick={() => {
                           viewOnClick(locationDetails);
                        }}
                     >
                        View Details
                     </button>
                     {isBookmark && (
                        <div className="d-flex">
                           {locationDetail?.google_map && (
                              <a
                                 href={locationDetail?.google_map}
                                 target="_blank"
                                 className="text-decoration-none"
                                 rel="noreferrer"
                              >
                                 <img src={mapIcon} alt="" srcSet="" />
                              </a>
                           )}

                           <img className="ms-2" src={dotsIcon} alt="" />
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
