import React from "react";
import { Form, Modal } from "react-bootstrap";
import alert from "../assets/images/icon/alert.png";
import Icon from "../assets/Icons";

export default function DesinationModal({
   show,
   setShow,
   availableCountriesList,
   setDesinationCountry,
   desitinationCountry,
   _planTrip,
   planTripLoading,
   selectedCountryId,
   isItenerary,
}) {
   return (
      <Modal
         show={show}
         onHide={() => {
            setShow(false);
         }}
         centered
         className="modal-common-height"
      >
         <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
               <hr className="modal-top-hr_ m-0" />
            </div>
            <div className="my-3 d-flex align-items-center justify-content-between me-2">
               <p className="my-3 modal-title">Where are you travelling to?</p>
               <Icon
                  className="modal-close-btn cursor-pointer d-md-none d-block"
                  onClick={() => {
                     setShow(false);
                  }}
                  name={"Close"}
               />
            </div>
            <p className="mb-2 modal-sub-title">Select one destination</p>
            <div className="d-flex flex-column justify-content-between h-75">
               <Form className="pt-3">
                  <div className="wm-header">
                     {availableCountriesList?.data?.data?.map((data, index) => {
                        if (isItenerary) {
                           if (selectedCountryId == data?.id) {
                              return (
                                 <div key={index}>
                                    <label
                                       className="cursor-pointer label-for-check weekly-content mb-4 d-flex align-items-center justify-content-between"
                                       htmlFor={data?.id}
                                    >
                                       <div className="d-flex">
                                          <img
                                             src={data?.flag}
                                             className="me-3"
                                             alt="Japan"
                                          />
                                          <p className="m-0">{data?.name}</p>
                                       </div>
                                       <input
                                          type="radio"
                                          className="check-with-label"
                                          name="radio-button"
                                          id={data?.id}
                                          onChange={() =>
                                             setDesinationCountry(data?.id)
                                          }
                                       />
                                    </label>
                                 </div>
                              );
                           }
                        } else {
                           return (
                              <div key={index}>
                                 <label
                                    className="cursor-pointer label-for-check weekly-content mb-4 d-flex align-items-center justify-content-between"
                                    htmlFor={data?.id}
                                 >
                                    <div className="d-flex">
                                       <img
                                          src={data?.flag}
                                          className="me-3"
                                          alt="Japan"
                                       />
                                       <p className="m-0">{data?.name}</p>
                                    </div>
                                    <input
                                       type="radio"
                                       className="check-with-label"
                                       name="radio-button"
                                       id={data?.id}
                                       onChange={() =>
                                          setDesinationCountry(data?.id)
                                       }
                                    />
                                 </label>
                              </div>
                           );
                        }
                     })}
                  </div>
               </Form>
               <div className="alert-content">
                  <img src={alert} alt="" />
                  <p className="mb-0">
                     Currently showing all prices in local currencies.
                  </p>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer
            className={`px-2 border-0 d-flex align-items-center justify-content-center ${
               desitinationCountry === null && "disabled-btn"
            }`}
         >
            <button
               type="submit"
               onClick={_planTrip}
               disabled={desitinationCountry === null}
            >
               {planTripLoading ? (
                  <div>
                     <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                     ></span>
                     Loading...
                  </div>
               ) : (
                  "Proceed"
               )}{" "}
            </button>
         </Modal.Footer>
      </Modal>
   );
}
