import React from "react";
import download from "../assets/images/visitingPlaces/download.svg";
import { useNavigate } from "react-router-dom";

export default function Footer() {
   const navigate = useNavigate();

   return (
      <div className="container-fluid p-0 footer-header d-flex align-items-center justify-content-center">
         <div className="container d-flex align-items-center justify-content-between px-3">
            <div className="footer-img cursor-pointer d-flex align-items-center justify-content-center">
               <img
                  style={{ marginRight: "40px" }}
                  src={download}
                  alt=""
                  onClick={() => navigate("/")}
                  srcSet=""
               />
               <p
                  onClick={() => navigate("/privacy-policy")}
                  className="footer-link cursor-pointer text-light"
               >
                  Privacy Policy
               </p>
               <div>
                  <p
                     style={{
                        color: "#FFF",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginRight: "20px",
                        marginLeft: "20px",
                        opacity: 0.3,
                     }}
                  >
                     |
                  </p>
               </div>

               <p
                  className="footer-link cursor-pointer text-light"
                  onClick={() => navigate("/terms-conditions")}
               >
                  Terms and Conditions
               </p>
            </div>
            <div className="footer-content">
               <p className="m-0">
                  Copyright of Trundle Technology LLP,{" "}
                  {new Date().getFullYear()}
               </p>
            </div>
         </div>
      </div>
   );
}
