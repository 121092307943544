import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getAvailableCountriesApi = () => {
   return axiosConfig.get(Config.GET_AVAILABLE_COUNTRIES_API_URL);
};

export const getHomeDataApi = (country_id) => {
   let formData = new FormData();
   formData.append("iso3", country_id);
   const token = localStorage?.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };

   if (token) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
   }

   return axiosConfig.post(
      Config.GET_HOME_DATA_API_URL,
      formData,
      requestOptions
   );
};

export const getLocationApi = (id) => {
   return axiosConfig.post(Config.GET_LOCATION_BY_ID_API_URL, id);
};

export const getUserTripsDetailsApi = () => {
   const token = localStorage?.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.get(Config.GET_USER_TRIP_DETAILS_API_URL, requestOptions);
};

export const getPlacesByIdApi = (data) => {
   const params = {};

   if (data?.iso3 !== "") {
      params.iso3 = data?.iso3;
   }

   if (data?.slug !== "" && data?.slug !== "all") {
      params.slug = data?.slug;
   }

   if (data?.page !== undefined) {
      params.page = data?.page + 1;
   }

   if (Array.isArray(data?.type) && data?.type.length > 0) {
      params.type = data?.type.join(",");
   }

   const filteredParams = {};

   for (const key in params) {
      if (params[key] !== undefined && params[key] !== "") {
         filteredParams[key] = params[key];
      }
   }

   const accessToken = localStorage?.getItem("token");

   const config = {
      params: filteredParams,
   };

   if (accessToken) {
      config.headers = {
         Authorization: `Bearer ${accessToken}`,
      };
   }
   return axiosConfig.get(Config.GET_PLACES_API_URL, config);
};

export const getPlacesFiltersApi = (country_id) => {
   return axiosConfig.get(
      Config.GET_PLACES_FILTERS_API_URL + `?iso3=${country_id}`
   );
};

export const getCountriesApi = () => {
   return axiosConfig.get(Config.GET_COUNTRIES_API_URL);
};
