import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getFoodAndDrinksApi,
   getFoodAndDrinksFiltersApi,
} from "../api/foodAndDrinks.api";

const initialState = {
   foodAndDrinksLoading: false,
   foodAndDrinksList: {},
   foodAndDrinksFilters: {},
   error: null,
};

export const getFoodAndDrinks = createAsyncThunk(
   "get_food_drinks",
   async (data) => {
      try {
         const res = await getFoodAndDrinksApi(data);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const updateIsBookmarkFoodDrink = createAsyncThunk(
   "update_is_bookmark_food_drink",
   async (data) => {
      try {
         return data;
      } catch (err) {
         return err.response;
      }
   }
);

export const getFoodAndDrinksFilters = createAsyncThunk(
   "get_food_and_drinks_filters",
   async (country_id) => {
      try {
         const res = await getFoodAndDrinksFiltersApi(country_id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

const foodAndDrinksSlices = createSlice({
   name: "food_drinks",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getFoodAndDrinks.pending, (state) => {
         state.foodAndDrinksLoading = true;
         state.foodAndDrinksList = {};
         state.error = null;
      });
      builder.addCase(getFoodAndDrinks.fulfilled, (state, { payload }) => {
         state.foodAndDrinksLoading = false;
         state.foodAndDrinksList = payload;
         state.error = null;
      });
      builder.addCase(getFoodAndDrinks.rejected, (state, { payload }) => {
         state.foodAndDrinksLoading = false;
         state.foodAndDrinksList = {};
         state.error = payload;
      });

      builder.addCase(getFoodAndDrinksFilters.pending, (state) => {
         state.loading = true;
         state.foodAndDrinksFilters = {};
         state.error = null;
      });
      builder.addCase(
         getFoodAndDrinksFilters.fulfilled,
         (state, { payload }) => {
            state.loading = false;
            state.foodAndDrinksFilters = payload;
            state.error = null;
         }
      );
      builder.addCase(
         getFoodAndDrinksFilters.rejected,
         (state, { payload }) => {
            state.loading = false;
            state.foodAndDrinksFilters = {};
            state.error = payload;
         }
      );

      builder.addCase(
         updateIsBookmarkFoodDrink.fulfilled,
         (state, { payload }) => {
            state.loading = false;
            const indexToUpdate =
               state.foodAndDrinksList?.data?.data?.results.findIndex(
                  (item) => item.id === payload?.location_id
               );
            if (indexToUpdate !== -1) {
               state.foodAndDrinksList.data.data.results[
                  indexToUpdate
               ].is_bookmarked = true;
            }
            state.error = null;
         }
      );
   },
});

const { reducer } = foodAndDrinksSlices;
export default reducer;
