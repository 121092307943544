import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getAvailableCountriesApi,
   getHomeDataApi,
   getPlacesByIdApi,
   getLocationApi,
   getPlacesFiltersApi,
   getCountriesApi,
   getUserTripsDetailsApi,
} from "../api/places.api";

const initialState = {
   placesLoading: false,
   availableCountriesList: {},
   placesFilters: {},
   homeDataList: {},
   placesList: {},
   locationData: {},
   countriesList: {},
   loading: false,
   userTripsDetails: {},
   error: null,
};

export const getAvailableCountries = createAsyncThunk(
   "get_available_countries",
   async () => {
      try {
         const res = await getAvailableCountriesApi();
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getHomeData = createAsyncThunk(
   "get_home_data",
   async (country_id) => {
      try {
         const res = await getHomeDataApi(country_id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getUserTripsDetails = createAsyncThunk(
   "get_user_trips_details",
   async () => {
      try {
         const res = await getUserTripsDetailsApi();
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const getCountries = createAsyncThunk("get_countries", async () => {
   try {
      const res = await getCountriesApi();
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getPlacesById = createAsyncThunk("get_places", async (data) => {
   try {
      const res = await getPlacesByIdApi(data);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getLocation = createAsyncThunk("get_location", async (id) => {
   try {
      const res = await getLocationApi(id);
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getPlacesFilters = createAsyncThunk(
   "get_places_filters",
   async (country_id) => {
      try {
         const res = await getPlacesFiltersApi(country_id);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const updateIsBookmarkHome = createAsyncThunk(
   "update_is_bookmark",
   async (data) => {
      try {
         return data;
      } catch (err) {
         return err.response;
      }
   }
);

export const updateIsBookmarkPlace = createAsyncThunk(
   "update_is_bookmark_place",
   async (data) => {
      try {
         return data;
      } catch (err) {
         return err.response;
      }
   }
);

const placeSlices = createSlice({
   name: "countries",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getAvailableCountries.pending, (state) => {
         state.loading = true;
         state.availableCountriesList = {};
         state.error = null;
      });
      builder.addCase(getAvailableCountries.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.availableCountriesList = payload;
         state.error = null;
      });
      builder.addCase(getAvailableCountries.rejected, (state, { payload }) => {
         state.loading = false;
         state.availableCountriesList = {};
         state.error = payload;
      });

      builder.addCase(getHomeData.pending, (state) => {
         state.loading = true;
         state.homeDataList = {};
         state.error = null;
      });
      builder.addCase(getHomeData.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.homeDataList = payload;
         state.error = null;
      });
      builder.addCase(getHomeData.rejected, (state, { payload }) => {
         state.loading = false;
         state.homeDataList = {};
         state.error = payload;
      });

      builder.addCase(getPlacesById.pending, (state) => {
         state.placesLoading = true;
         state.placesList = {};
         state.error = null;
      });
      builder.addCase(getPlacesById.fulfilled, (state, { payload }) => {
         state.placesLoading = false;
         state.placesList = payload;
         state.error = null;
      });
      builder.addCase(getPlacesById.rejected, (state, { payload }) => {
         state.placesLoading = false;
         state.placesList = {};
         state.error = payload;
      });

      builder.addCase(getLocation.pending, (state) => {
         state.loading = true;
         state.locationData = {};
         state.error = null;
      });
      builder.addCase(getLocation.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.locationData = payload;
         state.error = null;
      });
      builder.addCase(getLocation.rejected, (state, { payload }) => {
         state.loading = false;
         state.locationData = {};
         state.error = payload;
      });

      builder.addCase(getPlacesFilters.pending, (state) => {
         state.loading = true;
         state.placesFilters = {};
         state.error = null;
      });
      builder.addCase(getPlacesFilters.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.placesFilters = payload;
         state.error = null;
      });
      builder.addCase(getPlacesFilters.rejected, (state, { payload }) => {
         state.loading = false;
         state.placesFilters = {};
         state.error = payload;
      });

      builder.addCase(getCountries.pending, (state) => {
         state.loading = true;
         state.countriesList = {};
         state.error = null;
      });
      builder.addCase(getCountries.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.countriesList = payload;
         state.error = null;
      });
      builder.addCase(getCountries.rejected, (state, { payload }) => {
         state.loading = false;
         state.countriesList = {};
         state.error = payload;
      });

      builder.addCase(getUserTripsDetails.pending, (state) => {
         state.loading = true;
         state.userTripsDetails = {};
         state.error = null;
      });
      builder.addCase(getUserTripsDetails.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userTripsDetails = payload;
         state.error = null;
      });
      builder.addCase(getUserTripsDetails.rejected, (state, { payload }) => {
         state.loading = false;
         state.userTripsDetails = {};
         state.error = payload;
      });

      builder.addCase(updateIsBookmarkHome.fulfilled, (state, { payload }) => {
         state.loading = false;
         if (payload?.category === 1) {
            const indexToUpdate =
               state.homeDataList?.data?.data?.places.findIndex(
                  (item) => item.id === payload?.location_id
               );
            if (indexToUpdate !== -1) {
               state.homeDataList.data.data.places[
                  indexToUpdate
               ].is_bookmarked = true;
            }
         }
         if (payload?.category === 2) {
            const indexToUpdate =
               state.homeDataList?.data?.data?.foods_and_drinks.findIndex(
                  (item) => item.id === payload?.location_id
               );
            if (indexToUpdate !== -1) {
               state.homeDataList.data.data.foods_and_drinks[
                  indexToUpdate
               ].is_bookmarked = true;
            }
         }
         if (payload?.category === 3) {
            const indexToUpdate =
               state.homeDataList?.data?.data?.events.findIndex(
                  (item) => item.id === payload?.location_id
               );
            if (indexToUpdate !== -1) {
               state.homeDataList.data.data.events[
                  indexToUpdate
               ].is_bookmarked = true;
            }
         }
         state.error = null;
      });

      builder.addCase(updateIsBookmarkPlace.fulfilled, (state, { payload }) => {
         state.loading = false;
         const indexToUpdate = state.placesList?.data?.data?.results.findIndex(
            (item) => item.id === payload?.location_id
         );
         if (indexToUpdate !== -1) {
            state.placesList.data.data.results[
               indexToUpdate
            ].is_bookmarked = true;
         }
         state.error = null;
      });
   },
});

const { reducer } = placeSlices;
export default reducer;
