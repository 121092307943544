import filterIcon from "../assets/images/icon/discover_tune.svg";
import Pagination from "./Pagination";
import noPlacesImage from "../assets/images/icon/places.png";
import { Badge } from "react-bootstrap";
import Spinner from "./Spinner";
import Icon from "../assets/Icons";
import { useParams, useNavigate } from "react-router-dom";
import LocationCard from "./LocationCard";

export default function Activity({
   productList,
   filtersList,
   placeType,
   handleSetType,
   selectedItemsPlace,
   onHandleNextPage,
   setFiltersModalShow,
   viewDetailOnClick,
   category,
   loading,
}) {
   const isMoreCount = productList?.data?.data?.total_count > 0;

   const params = useParams();

   const navigate = useNavigate();

   return (
      <div className="container">
         <div>
            {isMoreCount && (
               <div className="row acitivity-subhead my-lg-4 my-md-3 my-2">
                  <div className="col-lg-7 col-md-10 col-12">
                     <div className="col-sm-6 col-12 d-flex align-items-center justify-md-content-center justify-content-sm-between justify-content-around">
                        <div className="d-flex">
                           <Icon
                              name={
                                 category === "places"
                                    ? "Places"
                                    : "Food_Drinks"
                              }
                              className="me-2"
                              width={24}
                              height={24}
                           />
                           <p className="m-0">
                              {category === "places"
                                 ? "Places"
                                 : "Food & Drink"}
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-2 d-flex align-items-center justify-content-end d-md-block d-none">
                     <button
                        className="filter-button float-end position-relative"
                        onClick={() => {
                           setFiltersModalShow(true);
                        }}
                     >
                        <img src={filterIcon} alt="filter" />
                        Filter
                        {selectedItemsPlace?.length > 0 && (
                           <Badge
                              bg="info"
                              className="text-primary rounded-circle filter-batch position-absolute"
                           >
                              {selectedItemsPlace?.length}
                           </Badge>
                        )}
                     </button>
                  </div>
               </div>
            )}
         </div>
         {loading ? (
            <Spinner height={30} />
         ) : (
            <div className="my-3">
               <div className="row my-2">
                  {isMoreCount && (
                     <div className=" col-12 filter-cities">
                        <ul className="d-flex list-unstyled text-decoration-none flex-wrap m-0">
                           <li
                              onClick={() => {
                                 handleSetType("all");
                                 navigate(
                                    `/${params?.country_id}/all/${params?.activity}`
                                 );
                              }}
                              className={
                                 placeType === "all" || params?.city === "all"
                                    ? "active cursor-pointer"
                                    : "cursor-pointer"
                              }
                           >
                              <a className="text-decoration-none cursor-pointer">
                                 All
                              </a>
                           </li>
                           {filtersList?.data?.data?.Cities?.map(
                              (data, index) => (
                                 <li
                                    onClick={() => {
                                       handleSetType(data?.slug);
                                       navigate(
                                          `/${params?.country_id}/${data?.slug}/${params?.activity}`
                                       );
                                    }}
                                    className={
                                       params?.city === data?.slug
                                          ? "active cursor-pointer"
                                          : "cursor-pointer"
                                    }
                                    key={index}
                                 >
                                    <a className="text-decoration-none">
                                       {data?.name}
                                    </a>
                                 </li>
                              )
                           )}
                        </ul>
                     </div>
                  )}
               </div>
               {productList?.data?.data?.results?.length > 0 ? (
                  <div className="mb-md-0 pb-md-0 pb- mb-sm-5 mb-5">
                     <div className="row my-2 places-card">
                        {productList?.data?.data?.results?.map(
                           (data, index) => (
                              <LocationCard
                                 key={index}
                                 locationDetails={data}
                                 isBookmark={false}
                                 viewOnClick={viewDetailOnClick}
                              />
                           )
                        )}
                     </div>
                     {(productList?.data?.data?.next ||
                        productList?.data?.data?.previous) && (
                        <Pagination
                           placesList={productList}
                           onHandleNextPage={onHandleNextPage}
                        />
                     )}
                  </div>
               ) : (
                  <div className="d-flex align-items-center gap-4 justify-content-center flex-column">
                     <img src={noPlacesImage} alt="" />
                     <p className="no-data-text">No places found</p>
                  </div>
               )}
            </div>
         )}
      </div>
   );
}
