import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { toast } from "react-hot-toast";
import {
   DeleteConfirmationModal,
   MobileTopHeader,
   EventPopup,
   AvalailableTrips,
   Pagination,
   YourLocationModal,
   VerifiedHeaderBottom,
   BookmarkActivity,
   ProductDetails,
   TripTypeModal,
} from "../../components";
import { getActiveTrips, planTrip } from "../../redux/slices/tripSlices";
import { deleteBookmark, getBookmarks } from "../../redux/slices/bookmarSlices";
import {
   getAvailableCountries,
   getCountries,
} from "../../redux/slices/placesSlices";
import noEventsImage from "../../assets/images/icon/events.png";
import eventBg from "../../assets/images/background/events-img.svg";
import useAuth from "../../constants/useAuth";
import { addItenery, getItenerys } from "../../redux/slices/itenerySlices";
import Icon from "../../assets/Icons";

export default function Bookmarks() {
   const navigate = useNavigate();

   const [authData] = useAuth();

   const dispatch = useDispatch();

   const [activeTab, setActiveTab] = useState("bookmarks/all");

   const [category, setCategory] = useState();

   const location = useLocation();

   const [prooductDetailShow, setProductDetailShow] = useState(false);

   const [tripTypeModalShow, setTripTypeModalShow] = useState(false);

   const [tripType, setTripType] = useState("new_trip");

   const [departureCountry, setDepartureCountry] = useState(null);

   const [selectedValue, setSelectedValue] = useState(null);

   const [selectedCountryId, setSelectedCountryId] = useState(null);

   const [yourLocationShow, setYourLocationShow] = useState(false);

   const [locationId, setLocationId] = useState(null);

   const [popupData, setPopupData] = useState({});

   const [tripsModalShow, setTripsModalShow] = useState(false);

   const [tripId, setTripId] = useState(null);

   const [isItenerary, setIsItenerary] = useState(false);

   const [eventPopup, setEventPopup] = useState({});

   const [eventShow, setEventShow] = useState(false);

   const { bookmarksList, loading, deleteBookmarkLoading } = useSelector(
      (state) => state?.bookmarks
   );

   const { userActiveTripsList } = useSelector((state) => state.trips);

   const { itenerysList } = useSelector((state) => state.iteneries);

   const { countriesList } = useSelector((state) => state.places);

   const [deleteModalShow, setDeleteModalShow] = useState(false);

   const [deleteData, setDeleteData] = useState(null);

   useEffect(() => {
      const path = location.pathname.slice(1);
      setActiveTab(path);

      let category = null;

      if (path === "bookmarks/all") {
         dispatch(getBookmarks());
      } else if (path === "bookmarks/places") {
         category = 1;
         dispatch(getBookmarks(category));
      } else if (path === "bookmarks/food-drink") {
         category = 2;
         dispatch(getBookmarks(category));
      } else {
         category = 3;
         dispatch(getBookmarks(category));
      }

      setCategory(category);
   }, [dispatch, location]);

   useEffect(() => {
      dispatch(getAvailableCountries());
      dispatch(getCountries());
      if (authData?.isAuthenticated) {
         dispatch(getActiveTrips());
      }
   }, [authData?.isAuthenticated, dispatch]);

   const handleTabChange = (selectedTab) => {
      navigate(`/${selectedTab}`);
      setActiveTab(selectedTab);
   };

   const onHandleNextPage = (selectedPage) => {
      dispatch(getBookmarks({ page: selectedPage, category: category }));
   };

   const _deleteBookmark = async (id) => {
      const response = await dispatch(deleteBookmark(id));
      if (response?.payload) {
         setDeleteModalShow(false);
      }
   };

   const handleSelectChange = (selectedOption) => {
      setSelectedValue(selectedOption);
      setDepartureCountry(selectedOption?.country_id);
   };

   const options = countriesList?.data?.data?.map((data, index) => ({
      value: data.name,
      label: (
         <div key={index} className="r-img-lael">
            <img
               src={data.flag}
               alt={data.name}
               style={{ width: "24px", marginRight: "10px" }}
            />
            {data.name}
         </div>
      ),
      country_id: data.id,
   }));

   const _planTrip = async () => {
      const data = {
         country_id: selectedCountryId,
         departure_country_id: departureCountry,
      };
      const response = await dispatch(planTrip(data));
      if (response?.payload?.data?.status) {
         const addToItenery = async () => {
            const popupDataId = category === 3 ? eventPopup?.id : popupData?.id;
            const data = {
               tripId: response?.payload?.data?.trip_id,
               locationId: popupDataId,
            };
            const response_ = await dispatch(addItenery(data));
            if (response_?.payload?.status) {
               navigate(`/trip-detail/${response?.payload?.data?.trip_id}`);
            }
            setTripsModalShow(false);
            toast.success("Location added successfully");
            setCategory(null);
         };
         addToItenery();
      }
      if (response?.payload?.status === 400) {
         toast.error(response?.payload?.data?.message);
         setIsItenerary(false);
         setSelectedValue(null);
      }
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         setSelectedValue(null);
         dispatch(getActiveTrips());
      }
   };

   const addToItenery = async () => {
      const response = await dispatch(getItenerys(tripId));
      if (response?.payload?.status === 200) {
         const popupDataId = category === 3 ? eventPopup?.id : popupData?.id;
         const previousTripIteneraries = response?.payload?.data?.data;
         const isIteneraryPresent = previousTripIteneraries?.some(
            (itinerary) => itinerary?.location?.id === popupDataId
         );
         if (isIteneraryPresent) {
            setTripsModalShow(false);
            toast.error("This location is already present in itineraries");
         } else {
            const data = { tripId: tripId, locationId: popupDataId };
            const _addItenery = async () => {
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  navigate(`/trip-detail/${tripId}`);
                  setTripsModalShow(false);
                  toast.success(response_?.payload?.data?.message);
                  setCategory(null);
               }
            };
            _addItenery();
         }
      }
   };

   const viewOnClick = (location) => {
      setIsItenerary(true);
      const filteredLocation = bookmarksList?.data?.data?.results?.filter(
         (data) => data?.id === location?.id
      );
      setSelectedCountryId(filteredLocation?.[0]?.location?.country);
      setPopupData(filteredLocation?.[0]?.location);
      setProductDetailShow(true);
      setLocationId(filteredLocation?.[0]?.location?.id);
   };

   return (
      <div>
         <ProductDetails
            productDetail={popupData}
            setProductDetailShow={setProductDetailShow}
            prooductDetailShow={prooductDetailShow}
            setTripTypeModalShow={setTripTypeModalShow}
            isBookmark={true}
            setIsItenerary={setIsItenerary}
         />
         <TripTypeModal
            setTripTypeModalShow={setTripTypeModalShow}
            tripTypeModalShow={tripTypeModalShow}
            setTripType={setTripType}
            tripType={tripType}
            setYourLocationShow={setYourLocationShow}
            setTripsModalShow={setTripsModalShow}
            selectedCountryId={selectedCountryId}
            availableTrips={userActiveTripsList}
            setIsItenerary={setIsItenerary}
         />
         <YourLocationModal
            show={yourLocationShow}
            setShow={setYourLocationShow}
            options={options}
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
            departureCountry={departureCountry}
            setIsItenerary={setIsItenerary}
            _planTrip={_planTrip}
            isItenerary={isItenerary}
         />
         <AvalailableTrips
            show={tripsModalShow}
            setTripsModalShow={setTripsModalShow}
            setTripId={setTripId}
            tripId={tripId}
            addToItenery={addToItenery}
            selectedCountryId={selectedCountryId}
            userActiveTripsList={userActiveTripsList}
         />
         <EventPopup
            show={eventShow}
            setShow={setEventShow}
            eventPopup={eventPopup}
            locationId={locationId}
            setTripTypeModalShow={setTripTypeModalShow}
            setIsItenerary={setIsItenerary}
            isBookmark={true}
         />
         <DeleteConfirmationModal
            show={deleteModalShow}
            setShow={setDeleteModalShow}
            type={"bookmark"}
            yesOnClick={_deleteBookmark}
            data={deleteData}
            setDeleteData={setDeleteData}
            loading={deleteBookmarkLoading}
         />
         <MobileTopHeader title={"Bookmarks"} />
         {/* <div
            className="container d-md-block d-none"
            style={{
               borderBottom: "9.1px solid",
               borderColor: "rgb(220, 219, 221)",
               opacity: "0.47",
            }}
         ></div> */}
         <Tabs
            activeKey={activeTab}
            onSelect={(eventKey) => {
               handleTabChange(eventKey);
            }}
            className="container-fluid activity-header-bookmark border-0 d-flex align-items-center justify-content-lg-center justify-content-lg-center gap-lg-5 gap-md-4 gap-3 justify-content-sm-around justify-content-around text-light p-0 box-shadow"
         >
            <Tab
               eventKey="bookmarks/all"
               className="pb-md-0 pb-3 all"
               title="All"
            >
               <BookmarkActivity
                  loading={loading}
                  bookmarksList={bookmarksList}
                  _deleteBookmark={(data) => {
                     setDeleteModalShow(true);
                     setDeleteData(data);
                  }}
                  onHandleNextPage={onHandleNextPage}
                  viewOnClick={viewOnClick}
               />
            </Tab>
            <Tab
               eventKey="bookmarks/places"
               title={
                  <div className="d-flex align-items-center justify-content-center">
                     <Icon
                        name={`${
                           activeTab === "bookmarks/places"
                              ? "hiking_active"
                              : "hiking_light"
                        }`}
                        className="me-2"
                        width={15}
                        height={22}
                     />
                     <p className="m-0">Places</p>
                  </div>
               }
               className={
                  activeTab === "activities/places"
                     ? "active-tab places"
                     : "places"
               }
            >
               <BookmarkActivity
                  loading={loading}
                  bookmarksList={bookmarksList}
                  _deleteBookmark={(data) => {
                     setDeleteModalShow(true);
                     setDeleteData(data);
                  }}
                  onHandleNextPage={onHandleNextPage}
                  viewOnClick={viewOnClick}
               />
            </Tab>
            <Tab
               eventKey="bookmarks/food-drink"
               title={
                  <div className="d-flex align-items-center justify-content-center">
                     <Icon
                        name={`${
                           activeTab === "bookmarks/food-drink"
                              ? "local_dining_active"
                              : "Food_Light"
                        }`}
                        className="me-2"
                        width={24}
                        height={24}
                     />
                     <p className="m-0">Food & Drink</p>
                  </div>
               }
               className={
                  activeTab === "activities/food-drink"
                     ? "active-tab food-drink"
                     : "food-drink"
               }
            >
               <BookmarkActivity
                  loading={loading}
                  bookmarksList={bookmarksList}
                  _deleteBookmark={(data) => {
                     setDeleteModalShow(true);
                     setDeleteData(data);
                  }}
                  onHandleNextPage={onHandleNextPage}
                  viewOnClick={viewOnClick}
               />
            </Tab>
            <Tab
               eventKey="bookmarks/events"
               title={
                  <div className="d-flex align-items-center justify-content-center">
                     <Icon
                        name={`${
                           activeTab === "bookmarks/events"
                              ? "event_active"
                              : "event_light"
                        }`}
                        className="me-2"
                        width={22}
                        height={22}
                     />
                     <p className="m-0">Events</p>
                  </div>
               }
               className={
                  activeTab === "activities/events"
                     ? "active-tab events"
                     : "events"
               }
            >
               <div className="container events-container mb-5 pb-1">
                  {bookmarksList?.data?.data?.count > 0 ? (
                     <>
                        <div className="row my-lg-4 my-md-3 my-2 row my-lg-3 my-md-3 my-2 row-cols-lg-3 row-cols-md-2 row-cols-1">
                           {bookmarksList?.data?.data?.results?.map(
                              (event, index) => (
                                 <div
                                    className="col mb-lg-3 mb-2 cursor-pointer"
                                    onClick={() => {
                                       setEventShow(true);
                                       setEventPopup(event?.location);
                                       setLocationId(event?.location?.id);
                                       setSelectedCountryId(
                                          event?.location?.country
                                       );
                                    }}
                                    key={index}
                                 >
                                    <div
                                       className="text-light shadow px-3 d-flex align-content-center justify-content-around flex-column"
                                       style={{
                                          backgroundImage: `url('${eventBg}')`,
                                          backgroundPosition: "50%",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          borderRadius: "20px",
                                          height: "137px",
                                          position: "relative",
                                       }}
                                    >
                                       <div>
                                          <p className="m-0 event-name">
                                             {event?.location?.name_of_location}
                                          </p>
                                          <p className="m-0 event-date">
                                             {event?.location?.event_date}
                                          </p>
                                       </div>
                                       <p className="m-0 event-place">
                                          {event?.location?.address}
                                       </p>
                                    </div>
                                 </div>
                              )
                           )}
                        </div>
                        {(bookmarksList?.data?.data?.next ||
                           bookmarksList?.data?.data?.previous) && (
                           <Pagination
                              placesList={bookmarksList}
                              onHandleNextPage={onHandleNextPage}
                           />
                        )}
                     </>
                  ) : (
                     <div>
                        {bookmarksList?.data?.data?.results && (
                           <div className="d-flex align-items-center gap-3 justify-content-center flex-column mt-3">
                              <img src={noEventsImage} alt="" />
                              <p className="no-data-text">No events found</p>
                           </div>
                        )}
                     </div>
                  )}
               </div>{" "}
            </Tab>
         </Tabs>
         <div className="d-md-none d-sm-block">
            <VerifiedHeaderBottom />
         </div>
      </div>
   );
}
