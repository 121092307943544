import React from "react";
import "./about.css";
import TextTransition, { presets } from "react-text-transition";
import { useNavigate } from "react-router-dom";

const TEXTS = [
   "the nostalgists",
   "the adventurers",
   "the globetrotters",
   "the travellers",
   "the wanderer",
   "the hoppers",
   "the seekers",
   "the vagabonds",
];

export default function About() {
   const [index, setIndex] = React.useState(0);

   const navigate = useNavigate();

   React.useEffect(() => {
      const intervalId = setInterval(
         () => setIndex((index) => index + 1),
         1000 // every 1 seconds
      );
      return () => clearTimeout(intervalId);
   }, []);

   return (
      <div className="screen">
         <div className="leftshadeimg">
            <img
               alt="shadow"
               src="http://trundle.world/_next/static/media/shadow.ad370fb5.svg"
               width="769"
               height="902"
               decoding="async"
               data-nimg="1"
               className="shadow_ w-100"
               loading="lazy"
               style={{ color: "transparent" }}
            />
            <img
               alt="leftshade"
               src="http://trundle.world/_next/static/media/leftshade.be548321.svg"
               width="133"
               height="482"
               decoding="async"
               data-nimg="1"
               className="leftshade"
               loading="lazy"
               style={{ color: "transparent" }}
            />
         </div>
         <div>
            <img
               alt="string"
               src="http://trundle.world/_next/static/media/string.f51e920a.svg"
               width="209"
               height="269"
               decoding="async"
               data-nimg="1"
               className="greenstringyimg"
               loading="lazy"
               style={{ color: "transparent" }}
            />
         </div>
         <div className="headerline">
            <img
               alt="logo"
               src="http://trundle.world/_next/static/media/newicon.a2a555e3.svg"
               width="31"
               height="31"
               decoding="async"
               data-nimg="1"
               className="logo"
               loading="lazy"
               style={{ color: "transparent" }}
            />
            <p className="trundle-text">trundle</p>
         </div>
         <div className="textpart">
            <div className="vidtext">
               <p className="gradient-text">
                  A curated recommendation app for travellers.
               </p>
            </div>
            <p className="with-text">
               We’d love to have you as one of our first users. Get early access
               to our app when we launch.
            </p>
            <form className="emailform">
               <span className="emailspan">
                  <input
                     className="emailinput"
                     type="email"
                     required=""
                     placeholder="Enter Email address:"
                     value=""
                  />
                  <span className="vaild-line"></span>
               </span>
               <button className="emailbutton" type="submit">
                  Submit
               </button>
            </form>
         </div>
         <div className="stringandshade">
            <img
               alt="string"
               src="http://trundle.world/_next/static/media/string2.61c291bb.svg"
               width="209"
               height="309"
               decoding="async"
               data-nimg="1"
               className="string2"
               loading="lazy"
               style={{ color: "transparent" }}
            />
            <img
               alt="shade"
               src="http://trundle.world/_next/static/media/shaderight.1e431836.svg"
               width="150"
               height="533"
               decoding="async"
               data-nimg="1"
               className="shaderight"
               loading="lazy"
               style={{ color: "transparent" }}
            />
            <div className="section2">
               <div className="w-full subsectiontext">
                  <h1 className="trundlehelps">With Trundle, travellers can</h1>
                  <div className="icon-part">
                     <img
                        alt="1"
                        src="http://trundle.world/_next/static/media/icon1.6f2168ba.svg"
                        width="34"
                        height="31"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: "transparent" }}
                     />
                     <p className="violet-text">
                        Find recommendations of unexplored places
                     </p>
                  </div>
                  <div className="violet-part-icon">
                     <img
                        alt="2"
                        src="http://trundle.world/_next/static/media/icon2.a5067bd1.svg"
                        width="34"
                        height="31"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: "transparent" }}
                     />
                     <p className="normal-text">
                        Create and update trip itineraries
                     </p>
                  </div>
                  <div className="violet-part-icon">
                     <img
                        alt="3"
                        src="http://trundle.world/_next/static/media/icon3.242c17f2.svg"
                        width="34"
                        height="31"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: "transparent" }}
                     />
                     <p className="normal-text">
                        Get tips to make your travel easy and comfortable
                     </p>
                  </div>
                  <div className="violet-part-icon">
                     <img
                        alt="4"
                        src="http://trundle.world/_next/static/media/icon4.f078d532.svg"
                        width="34"
                        height="31"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: "transparent" }}
                     />
                     <p className="normal-text">And many more features...</p>
                  </div>
               </div>
               <div className="w-full iphonediv">
                  <img
                     alt="iphone img"
                     src="http://trundle.world/_next/static/media/iphone.8bae6afa.svg"
                     width="597"
                     height="633"
                     decoding="async"
                     data-nimg="1"
                     className="iphone"
                     loading="lazy"
                     style={{ color: "transparent" }}
                  />
               </div>
            </div>
            <img
               alt="string"
               src="http://trundle.world/_next/static/media/string3.8ae261fe.svg"
               width="209"
               height="309"
               decoding="async"
               data-nimg="1"
               className="string3"
               loading="lazy"
               style={{ color: "transparent" }}
            />
            <div className="section4">
               <img
                  alt="iphone img"
                  src="http://trundle.world/_next/static/media/stariphone.6f63db6d.png"
                  width="1438"
                  height="608"
                  decoding="async"
                  data-nimg="1"
                  className="stariphone"
                  loading="lazy"
                  style={{ color: "transparent" }}
               />
               <img
                  alt="iphone img"
                  src="http://trundle.world/_next/static/media/stariphonemob.4322221a.svg"
                  width="390"
                  height="494"
                  decoding="async"
                  data-nimg="1"
                  className="stariphonemob"
                  loading="lazy"
                  style={{ color: "transparent" }}
               />
               <div className="normal-changing-text">
                  <p className="anapp">An app for</p>
                  <TextTransition
                     springConfig={presets.wobbly}
                     className="wanderers"
                  >
                     {TEXTS[index % TEXTS.length]}
                  </TextTransition>
               </div>
            </div>
            <div className="founderdiv">
               <img
                  alt="shade"
                  src="http://trundle.world/_next/static/media/sec4left.7faed9b5.svg"
                  width="129"
                  height="308"
                  decoding="async"
                  data-nimg="1"
                  className="sec4left"
                  loading="lazy"
                  style={{ color: "transparent" }}
               />
               <img
                  alt="shade"
                  src="http://trundle.world/_next/static/media/sec4right.c8a725df.svg"
                  width="149"
                  height="363"
                  decoding="async"
                  data-nimg="1"
                  className="sec4right"
                  loading="lazy"
                  style={{ color: "transparent" }}
               />
               <div className="imagesections-grid">
                  <div className="imagesection1">
                     <div className="founder">
                        <img
                           alt="founder"
                           src="http://trundle.world/_next/static/media/founderimg.61ba2fbb.svg"
                           width="178"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="founderimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Prasanna Jeyasankar</p>
                     <p className="occupation">Founder</p>
                     <div className="inlogo">
                        <a
                           target="_blank"
                           href="https://www.linkedin.com/in/prasannajeyasankar/"
                        >
                           <img
                              alt=" founder linkedin"
                              src="http://trundle.world/_next/static/media/inlogo.a15bcfe6.svg"
                              width="34"
                              height="34"
                              decoding="async"
                              data-nimg="1"
                              loading="lazy"
                              style={{ color: "transparent" }}
                           />
                        </a>
                     </div>
                  </div>
                  <div className="imagesection2">
                     <div className="designer">
                        <img
                           alt="designer"
                           src="http://trundle.world/_next/static/media/designerimg.449a23af.svg"
                           width="178"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="designerimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Sharathkumar J</p>
                     <p className="occupation">Lead Designer</p>
                     <div className="inlogo">
                        <a
                           target="_blank"
                           href="https://www.linkedin.com/in/sharathj7/"
                        >
                           <img
                              alt=" designer linkedin"
                              src="http://trundle.world/_next/static/media/inlogo.a15bcfe6.svg"
                              width="34"
                              height="34"
                              decoding="async"
                              data-nimg="1"
                              loading="lazy"
                              style={{ color: "transparent" }}
                           />
                        </a>
                     </div>
                  </div>
                  <div className="imagesection3">
                     <div className="designer">
                        <img
                           alt="Lead Developer"
                           src="http://trundle.world/_next/static/media/leaddeveloper.bb2c67a4.svg"
                           width="177"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="designerimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Nityasha Sinha</p>
                     <p className="occupation">Lead Developer</p>
                     <div className="leaddev-LI">
                        <a
                           target="_blank"
                           href="https://www.linkedin.com/in/nityasha-sinha-78aa68189/"
                        >
                           <img
                              alt=" designer linkedin"
                              src="http://trundle.world/_next/static/media/inlogo.a15bcfe6.svg"
                              width="34"
                              height="34"
                              decoding="async"
                              data-nimg="1"
                              loading="lazy"
                              style={{ color: "transparent" }}
                           />
                        </a>
                     </div>
                  </div>
                  <div className="imagesection4">
                     <div className="designer">
                        <img
                           alt="Developer"
                           src="http://trundle.world/_next/static/media/developer.4c3da8de.svg"
                           width="177"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="designerimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Raakesh M</p>
                     <p className="occupation">Developer</p>
                  </div>
                  <div className="imagesection5">
                     <div className="designer">
                        <img
                           alt="Developer"
                           src="http://trundle.world/_next/static/media/uidesigner.09d418df.svg"
                           width="177"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="designerimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Priyanka Rawat</p>
                     <p className="occupation">UI Designer</p>
                  </div>
                  <div className="imagesection6">
                     <div className="designer">
                        <img
                           alt="Developer"
                           src="http://trundle.world/_next/static/media/operations.90ee7ec2.svg"
                           width="177"
                           height="176"
                           decoding="async"
                           data-nimg="1"
                           className="designerimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <img
                           alt="."
                           src="http://trundle.world/_next/static/media/starimg.1e81f5a9.svg"
                           width="70"
                           height="71"
                           decoding="async"
                           data-nimg="1"
                           className="starimg"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                     <p className="nametext">Marian Peter</p>
                     <p className="occupation">Operations</p>
                  </div>
               </div>
            </div>
            <div className="footerdiv">
               <footer className="footerinnerdiv">
                  <div className="mailline">
                     <img
                        alt="contact"
                        src="http://trundle.world/_next/static/media/mailicon.f54ae2d0.svg"
                        width="20"
                        height="20"
                        decoding="async"
                        data-nimg="1"
                        className="mailicon"
                        loading="lazy"
                        style={{ color: "transparent" }}
                     />
                     <p className="contact">Contact</p>
                     <span className="dashspan">-</span>
                     <a href="mailto:prasanna@trundle.world" className="mail">
                        prasanna@trundle.world
                     </a>
                  </div>
                  <div className="mailline-mob">
                     <div className="icon-and-contact">
                        <img
                           alt="contact"
                           src="http://trundle.world/_next/static/media/mailicon.f54ae2d0.svg"
                           width="20"
                           height="20"
                           decoding="async"
                           data-nimg="1"
                           className="mailicon"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                        <p className="contact">Contact</p>
                     </div>
                     <a href="mailto:prasanna@trundle.world" className="mail">
                        prasanna@trundle.world
                     </a>{" "}
                  </div>
                  <h1 className="Announcements">Announcements</h1>
                  <p className="jan">
                     21st Jan, 2023: Launching MVP before{" "}
                     <span className="march">31st Mar, 2023.</span>
                  </p>
                  <p className="jan">
                     27th Feb, 2023: App UI design and company registration
                     completed.
                  </p>
                  <div className="footerbottom">
                     <p className="copyright">
                        Copyright of Trundle Technology LLP, 2023
                     </p>
                     <div
                        className="footerlogo cursor-pointer"
                        onClick={() => navigate("/")}
                     >
                        <img
                           alt="logo"
                           src="http://trundle.world/_next/static/media/footericon2.864e5b0e.svg"
                           width="290"
                           height="77"
                           decoding="async"
                           data-nimg="1"
                           className="footericon2 w-100"
                           loading="lazy"
                           style={{ color: "transparent" }}
                        />
                     </div>
                  </div>
                  <div className="footerbottom-mob">
                     <div className="footerlogo2">
                        <div
                           className="footerlogo-mob cursor-pointer"
                           onClick={() => navigate("/")}
                        >
                           <img
                              alt="logo"
                              src="http://trundle.world/_next/static/media/footericon2.864e5b0e.svg"
                              width="290"
                              height="77"
                              decoding="async"
                              data-nimg="1"
                              className="footericon2"
                              loading="lazy"
                              style={{ color: "transparent" }}
                           />
                        </div>
                        <div>
                           <p className="copyright-mob">
                              Copyright of Trundle Technology LLP, 2023
                           </p>
                        </div>
                     </div>
                  </div>
               </footer>
            </div>
         </div>
      </div>
   );
}
