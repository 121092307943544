import React from "react";
import Router from "./routes/Router";
import Footer from "./pages/Footer";
import { useLocation } from "react-router-dom";
import VerifiedHeader from "./components/VerifiedHeader";
import useAuth from "./constants/useAuth";

function App() {
   const location = useLocation();
   const isAuthPage =
      location?.pathname === "/login" ||
      location?.pathname === "/sign-up" ||
      location?.pathname === "/otp-verification";
   const [authData] = useAuth();
   return (
      <div className="d-flex flex-column outer-wrapper">
         {!isAuthPage && (
            <div className="trundle-header-top-margin">
               <VerifiedHeader authData={authData} />
            </div>
         )}
         <Router />
         {!isAuthPage && location?.pathname !== "/about" && (
            <footer className="d-md-block d-none mt-auto">
               <Footer />
            </footer>
         )}
      </div>
   );
}

export default App;
