import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

const useAuth = () => {
   const [authData, setAuthData] = useState({
      isAuthenticated: false,
      user: null,
   });

   useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
         const decodedToken = jwtDecode(token);
         if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.removeItem("token");
            setAuthData({ isAuthenticated: false, user: null });
         } else {
            setAuthData({ isAuthenticated: true, user: decodedToken });
         }
      } else {
         setAuthData({ isAuthenticated: false, user: null });
      }
   }, []);

   return [authData, setAuthData];
};

export default useAuth;
