import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Icon from "../assets/Icons";

export default function YourLocationModal({
   show,
   setShow,
   options,
   selectedValue,
   handleSelectChange,
   setDesinationModalShow,
   isItenerary,
   _planTrip,
   setIsItenerary,
}) {
   return (
      <Modal
         show={show}
         onHide={() => {
            setShow(false);
            setIsItenerary(false);
         }}
         keyboard={false}
         centered
         className="modal-common-height"
      >
         <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
               <hr className="modal-top-hr_ m-0" />
            </div>
            <div className="my-3 d-flex align-items-center justify-content-between me-2">
               <p className="modal-title">Your Location</p>
               <Icon
                  className="modal-close-btn cursor-pointer d-md-none d-block"
                  onClick={() => {
                     setShow(false);
                     setIsItenerary(false);
                  }}
                  name={"Close"}
               />
            </div>
            <p className="mb-2 modal-sub-title">Select your country</p>
            <div className="cursor-pointer">
               <Select
                  className="form-label-header form-label-header-outline"
                  options={options}
                  isSearchable={true}
                  placeholder="Ex: United States"
                  value={selectedValue}
                  onChange={handleSelectChange}
               />
            </div>
         </Modal.Body>
         <Modal.Footer
            className={`border-0 d-flex align-items-center justify-content-center ${
               selectedValue === null && "disabled-btn"
            }`}
         >
            <button
               type="submit"
               onClick={() => {
                  setShow(false);
                  setIsItenerary(false);
                  if (isItenerary) {
                     _planTrip();
                  } else {
                     setDesinationModalShow(true);
                  }
               }}
               disabled={selectedValue === null}
            >
               Proceed
            </button>
         </Modal.Footer>
      </Modal>
   );
}
