import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getUserDetailsApi = () => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.get(Config.GET_USER_DETAILS_API_URL, requestOptions);
};

export const getCountiesApi = () => {
   return axiosConfig.get(Config.GET_COUNTRY_LISTS_API_URL);
};

export const updateUserApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
         "Content-Type": "multipart/form-data",
      },
   };
   return axiosConfig.post(
      Config.UPDATE_USER_DETAILS_API_URL,
      data,
      requestOptions
   );
};

export const postContactUsApi = (data) => {
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };
   return axiosConfig.post(
      Config.POST_CONTACT_US_API_URL,
      data,
      requestOptions
   );
};
