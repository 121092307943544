import React from "react";
import Icon from "../assets/Icons";
import Select from "react-select";

const SelectField = ({
   label,
   name,
   options,
   value,
   onChange,
   onBlur,
   error,
   placeholder,
}) => {
   return (
      <div className="position-relative">
         <label htmlFor={name} className="contact-form-label">
            {label}
         </label>
         <Select
            className="form-label-header form-label-header-outline"
            options={options}
            isSearchable={true}
            placeholder={placeholder}
            onChange={(data) => {
               onChange(name, data);
            }}
            onBlur={onBlur}
            value={value}
         />
         <div className="position-absolute dropdown-arrow-position">
            <Icon name="arrow_drop_down" />
         </div>
         {error && <div className="error text-danger">{error}</div>}
      </div>
   );
};

export default SelectField;
