import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import bookMarkIconActive from "../assets/images/icon/bookmark_added.svg";
import bookMarkIcon from "../assets/images/icon/bookmark.svg";
import homeIcon from "../assets/images/icons/home_.svg";
import ProfileDropDown from "../pages/home-page/ProfileDropDown";
import logo from "../assets/images/logo/logo.png";
import useAuth from "../constants/useAuth";
import Icon from "../assets/Icons";

export default function VerifiedHeader() {
   const navigate = useNavigate();

   const location = useLocation();

   const [authData] = useAuth();

   useEffect(() => {}, [authData]);

   const [scrollTop, setScrollTop] = useState(0);

   useEffect(() => {
      const handleScroll = () => {
         const currentScrollTop = window.scrollY;
         setScrollTop(currentScrollTop);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, []);

   const excludedPaths = [
      "events",
      "food-drink",
      "places",
      "all",
      "privacy-policy",
      "terms-conditions",
   ];

   // Extract the last word from the URL path
   const pathParts = location?.pathname.split("/");
   const lastPathPart = pathParts[pathParts.length - 1];

   // Check if the last word is not in the excludedPaths array
   const shouldAddBoxShadow = !excludedPaths.includes(lastPathPart);

   return (
      <div
         className={`container-fluid trundle-header fixed-top
          ${
             location?.pathname === "/privacy-policy" ||
             location?.pathname === "/terms-conditions"
                ? "shadow-0"
                : shouldAddBoxShadow || scrollTop > 50
                ? "box-shadow"
                : ""
          }
            `}
      >
         <div className="container">
            <div className="d-flex align-items-center justify-content-between">
               <div
                  className="ellipse-image d-flex align-items-center justify-content-md-start justify-content-center"
                  onClick={() => navigate("/")}
               >
                  <a className="text-decoration-none cursor-pointer d-flex gap-2 align-items-center justify-content-center">
                     <img src={logo} alt="" srcSet="" />
                     <p className="logotext m-0">trundle</p>
                  </a>
               </div>
               {localStorage?.getItem("token") ? (
                  <div className="header-auth-tab col-md-6 col-12 d-flex align-items-center justify-content-md-end justify-content-center my--2">
                     <ul className="p-0 m-0">
                        <li className="d-flex gap-5">
                           <div
                              className="d-flex flex-column align-items-center justify-content-between"
                              onClick={() => {
                                 navigate("/");
                              }}
                           >
                              {location?.pathname === "/" ? (
                                 <Icon
                                    name={"CameraIndoor"}
                                    className="gap-5"
                                    width={19}
                                    height={19}
                                 />
                              ) : (
                                 <img
                                    className="auth-tab-opacity gap-5"
                                    src={homeIcon}
                                    alt=""
                                 />
                              )}
                              <p
                                 className={
                                    location?.pathname === "/"
                                       ? "m-0 active-color"
                                       : "m-0"
                                 }
                              >
                                 Home
                              </p>
                           </div>
                           <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={() => navigate("/my-trips")}
                           >
                              {location?.pathname === "/my-trips" ||
                              location?.pathname === "/trip-detail" ? (
                                 <Icon
                                    name={"My_trip_Filled"}
                                    className="gap-5"
                                    width={24}
                                    height={24}
                                 />
                              ) : (
                                 <Icon
                                    name={"My_Trip"}
                                    className="auth-tab-opacity"
                                    width={24}
                                    height={24}
                                 />
                              )}
                              <p
                                 className={
                                    location?.pathname === "/my-trips" ||
                                    location?.pathname === "/trip-detail"
                                       ? "m-0 active-color"
                                       : "m-0"
                                 }
                              >
                                 My Trips
                              </p>
                           </div>
                           <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              onClick={() => navigate("/bookmarks/all")}
                           >
                              {location?.pathname === "/bookmarks/all" ||
                              location?.pathname === "/bookmarks/places" ||
                              location?.pathname === "/bookmarks/events" ||
                              location?.pathname === "/bookmarks/food-drink" ? (
                                 <img src={bookMarkIconActive} alt="" />
                              ) : (
                                 <img
                                    src={bookMarkIcon}
                                    className="auth-tab-opacity"
                                    alt=""
                                 />
                              )}
                              <p
                                 className={
                                    location?.pathname === "/bookmarks/all" ||
                                    location?.pathname ===
                                       "/bookmarks/places" ||
                                    location?.pathname ===
                                       "/bookmarks/events" ||
                                    location?.pathname ===
                                       "/bookmarks/food-drink"
                                       ? "m-0 active-color"
                                       : "m-0"
                                 }
                              >
                                 Bookmarks
                              </p>
                           </div>
                           <ProfileDropDown />
                        </li>
                     </ul>
                  </div>
               ) : (
                  <div>
                     <ul className="d-flex gap-2 m-0 align-items-center justify-content-center">
                        <li className="list-group">
                           <button
                              className="help-plan-my-trip-button d-flex align-items-center justify-content-center"
                              onClick={() => navigate("/contact-us")}
                           >
                              <a className="text-decoration-none fw-bold">
                                 Help Plan My Trip
                              </a>
                           </button>
                        </li>
                        <li className="list-group">
                           <button
                              className="p-button"
                              onClick={() => navigate("/sign-up")}
                           >
                              <a className="text-decoration-none fw-bold">
                                 Sign Up
                              </a>
                           </button>
                        </li>
                     </ul>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
}
