import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getTipsApi = (countryId) => {
   return axiosConfig.get(Config.GET_TIPS_API_URL + `?country_id=${countryId}`);
};
export const getEmergencyApi = (countryId) => {
   return axiosConfig.get(
      Config.GET_EMERGENCY_API_URL + `?country_id=${countryId}`
   );
};

export const updateTripApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.UPDATE_TRIP_DATE_API_URL,
      data,
      requestOptions
   );
};

export const getLearnLangaugesApi = (countryId) => {
   return axiosConfig.get(
      Config.GET_LEARN_LANGAUGES_API_URL + `?country_id=${countryId}`
   );
};

export const getTripsApi = (trip_id) => {
   let formData = new FormData();
   formData.append("id", trip_id);
   const token = localStorage.getItem("token");

   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(Config.GET_TRIPS_API_URL, formData, requestOptions);
};

export const getActiveTripsApi = () => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.get(Config.GET_ACTIVE_TRIPS_API_URL, requestOptions);
};

export const deleteTripApi = (id) => {
   let formData = new FormData();
   formData.append("id", id);
   const token = localStorage.getItem("token");

   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };

   return axiosConfig.post(
      Config.DELETE_TRIP_API_URL,
      formData,
      requestOptions
   );
};

export const planTripApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(Config.PLAN_TRIP_API_URL, data, requestOptions);
};

export const addSpendingApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(Config.ADD_SPENDING_API_URL, data, requestOptions);
};

export const removeSpendingApi = (id) => {
   let formData = new FormData();
   formData.append("id", id);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.REMOVE_SPENDING_API_URL,
      formData,
      requestOptions
   );
};

export const updateSpendingApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.UPDATE_SPENDING_API_URL,
      data,
      requestOptions
   );
};

export const addSpendingAmountApi = (data) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.ADD_SPENDING_AMOUNT_API_URL,
      data,
      requestOptions
   );
};

export const getSpendingCategoryApi = (id) => {
   let formData = new FormData();
   formData.append("trip_spending_id", id);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.get(
      Config.GET_SPENDING_CATEGORY_API_URL,
      formData,
      requestOptions
   );
};

export const getSpendingIDApi = (id) => {
   let formData = new FormData();
   formData.append("id", id);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.GET_SPENDING_ID_API_URL,
      formData,
      requestOptions
   );
};

export const getSpendingsApi = (id) => {
   let formData = new FormData();
   formData.append("trip_spending_id", id);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.GET_SPENDINGS_API_URL,
      formData,
      requestOptions
   );
};
