import React from "react";
import Access from "./images/icons/Access.svg";
import AddItinerary from "./images/icons/add-itinerary.svg";
import Camera_alt from "./images/icons/Camera_alt.svg";
import Date_Time from "./images/icons/Date_Time.svg";
import Entry from "./images/icons/Entry.svg";
import Events from "./images/icons/Events.svg";
import Feedback from "./images/icons/Feedback.svg";
import Female from "./images/icons/Female.svg";
import Filter from "./images/icons/Filter.svg";
import Food_Drinks from "./images/icons/Food_Drinks.svg";
import Gallery from "./images/icons/Gallery.svg";
import Help from "./images/icons/Help.svg";
import Information from "./images/icons/Information.svg";
import Log_out from "./images/icons/Log_out.svg";
import Male from "./images/icons/Male.svg";
import Map_Address from "./images/icons/Map_Address.svg";
import Menu from "./images/icons/Menu.svg";
import My_Trip from "./images/icons/My_Trip.svg";
import No_Trip from "./images/icons/No_Trip.svg";
import No_notifications from "./images/icons/No_notifications.svg";
import Other from "./images/icons/Other.svg";
import Places from "./images/icons/Places.svg";
import Profile from "./images/icons/Profile.svg";
import Profile_Filled from "./images/icons/Profile_(Filled).svg";
import Set_trip_date from "./images/icons/Set_trip_date.svg";
import Spendings from "./images/icons/Spendings.svg";
import Timing from "./images/icons/Timing.svg";
import Tips from "./images/icons/Tips.svg";
import Add_itinerary from "./images/icons/add-itinerary.svg";
import Bookmark from "./images/icons/bookmark.svg";
import Bookmark_added_Filled from "./images/icons/bookmark_added(Filled).svg";
import Call from "./images/icons/call.svg";
import DeleteIcon from "./images/icons/delete.svg";
import Edit from "./images/icons/edit.svg";
import Emergency from "./images/icons/emergency.svg";
import Grid_view from "./images/icons/grid_view.svg";
import Header_action from "./images/icons/header-action.svg";
import Home from "./images/icons/home_.svg";
import Location from "./images/icons/location.svg";
import Location_on from "./images/icons/location_on.svg";
import Magic_button from "./images/icons/magic_button.svg";
import My_trip_Filled from "./images/icons/my_trip (Filled ).svg";
import Plan_your_trip from "./images/icons/plan_your_trip.svg";
import Translate from "./images/icons/translate.svg";
import Hiking from "./images/icons/hiking.svg";
import Map from "./images/icons/map.svg";
import CameraIndoor from "./images/icons/camera_indoor.svg";
import Explore from "./images/icons/explore.svg";
import Action from "./images/icons/action.svg";
import Delete from "./images/icons/DeleteAction.svg";
import Avatar from "./images/icons/Avatar.svg";
import Galery from "./images/icons/AvatarAction.svg";
import Vector from "./images/icons/Vector.svg";
import AddCircle from "./images/icons/add_circle.svg";
import Food_Light from "./images/icons/local_dining_light.svg";
import event_light from "./images/icons/event_light.svg";
import event_active from "./images/icons/event_active.svg";
import hiking_light from "./images/icons/hiking_light.svg";
import hiking_active from "./images/icons/hiking_active.svg";
import local_dining_active from "./images/icons/local_dining_active.svg";
import arrow_forward from "./images/icons/arrow_forward.svg";
import Bookmarked from "./images/icons/bookmarked.svg";
import Close from "./images/icons/close.svg";
import Show_more from "./images/icons/Show_more.svg";
import home_events from "./images/icons/home-events.svg";
import arrow_drop_down from "./images/icons/arrow_drop_down.svg";

const iconsMap = {
   arrow_drop_down,
   event_active,
   home_events,
   Show_more,
   Bookmarked,
   Close,
   hiking_active,
   local_dining_active,
   hiking_light,
   arrow_forward,
   Food_Light,
   event_light,
   Access,
   AddCircle,
   Delete,
   Action,
   Avatar,
   Explore,
   Galery,
   AddItinerary,
   Camera_alt,
   Date_Time,
   CameraIndoor,
   Entry,
   Events,
   Hiking,
   Feedback,
   Female,
   Filter,
   Food_Drinks,
   Gallery,
   Help,
   Information,
   Log_out,
   Male,
   Map_Address,
   Menu,
   My_Trip,
   No_Trip,
   Vector,
   No_notifications,
   Other,
   Places,
   Profile,
   Profile_Filled,
   Set_trip_date,
   Spendings,
   Timing,
   Tips,
   Add_itinerary,
   Bookmark,
   Bookmark_added_Filled,
   Call,
   DeleteIcon,
   Edit,
   Emergency,
   Grid_view,
   Header_action,
   Home,
   Location,
   Location_on,
   Magic_button,
   My_trip_Filled,
   Plan_your_trip,
   Translate,
   Map,
};

const Icon = ({ name, ...rest }) => {
   const iconPath = iconsMap[name];
   if (!iconPath) {
      console.warn(`Icon '${name}' not found`);
      return null;
   }
   return <img src={iconPath} alt={name} {...rest} />;
};

export default Icon;
