import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "../../assets/Icons";
import eventIcon from "../../assets/images/icon/activity/events-active.svg";
import filterIcon from "../../assets/images/icon/filter.png";
import Modal from "react-bootstrap/Modal";
import eventBg from "../../assets/images/background/events-img.svg";
import noEventsImage from "../../assets/images/icon/events.png";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getEvents, getEventsFilters } from "../../redux/slices/eventsSlices";
import { getActiveTrips, planTrip } from "../../redux/slices/tripSlices";
import { TiDelete } from "react-icons/ti";
import { useParams } from "react-router-dom";
import {
   getCountries,
   getPlacesById,
   getPlacesFilters,
} from "../../redux/slices/placesSlices";
import {
   getFoodAndDrinks,
   getFoodAndDrinksFilters,
} from "../../redux/slices/foodAndDrinksSlices";
import { addItenery, getItenerys } from "../../redux/slices/itenerySlices";
import useAuth from "../../constants/useAuth";
import MobileTopHeader from "../../components/MobileTopHeader";
import {
   Spinner,
   Pagination,
   ProductDetails,
   TripTypeModal,
   YourLocationModal,
   AvalailableTrips,
   Activity,
   EventPopup,
   Tabs,
} from "../../components";

function ActivitiesTab() {
   const [authData] = useAuth();

   const navigate = useNavigate();

   const location = useLocation();

   const params = useParams();

   const dispatch = useDispatch();

   const [activeTab, setActiveTab] = useState(`${params?.activity}`);

   const [countryId] = useState(params?.country_id);

   const [filtersModalShow, setFiltersModalShow] = useState(false);

   const [eventPopup, setEventPopup] = useState({});

   const [eventShow, setEventShow] = useState(false);

   const [selectedItemsPlace, setSelectedItemsPlace] = useState([]);

   const [selectedItemsFood, setSelectedItemsFood] = useState([]);

   const [selectedItemsEvent, setSelectedItemsEvent] = useState([]);

   const [prooductDetailShow, setProductDetailShow] = useState(false);

   const [tripTypeModalShow, setTripTypeModalShow] = useState(false);

   const [tripType, setTripType] = useState("new_trip");

   const [departureCountry, setDepartureCountry] = useState(null);

   const [selectedValue, setSelectedValue] = useState(null);

   const [yourLocationShow, setYourLocationShow] = useState(false);

   const [popupData, setPopupData] = useState({});

   const [tripsModalShow, setTripsModalShow] = useState(false);

   const [tripId, setTripId] = useState(null);

   const [category, setCategory] = useState(null);

   const [isItenerary, setIsItenerary] = useState(false);

   const { userActiveTripsList } = useSelector((state) => state.trips);

   const { countriesList } = useSelector((state) => state.places);

   const { placesList, placesLoading, placesFilters } = useSelector(
      (state) => state?.places
   );

   const { foodAndDrinksList, foodAndDrinksLoading, foodAndDrinksFilters } =
      useSelector((state) => state?.food_drinks);

   const { eventsList, eventsFilters, eventsLoading } = useSelector(
      (state) => state?.events
   );

   const isPlaces = params?.activity === "places";

   const isFoodDrink = params?.activity === "food-drink";

   const isEvent = params?.activity === "events";

   useEffect(() => {
      setActiveTab(params?.activity);
   }, [location, params?.activity]);

   useEffect(() => {
      const fetchDataForActiveTab = () => {
         switch (activeTab) {
            case "places": {
               const queryParams = {
                  iso3: params?.country_id,
                  slug: params?.city,
                  type: selectedItemsPlace,
               };
               dispatch(getPlacesById(queryParams));
               dispatch(getPlacesFilters(params?.country_id));
               break;
            }
            case "food-drink": {
               const queryParams = {
                  iso3: params?.country_id,
                  slug: params?.city,
                  type: selectedItemsFood,
               };
               dispatch(getFoodAndDrinks(queryParams));
               dispatch(getFoodAndDrinksFilters(params?.country_id));
               break;
            }
            case "events": {
               const queryParams = {
                  iso3: params?.country_id,
                  slug: params?.city,
                  type: selectedItemsEvent,
               };
               dispatch(getEvents(queryParams));
               dispatch(getEventsFilters(params?.country_id));
               break;
            }
            default:
               break;
         }
      };
      if (authData?.isAuthenticated) {
         dispatch(getActiveTrips());
      }
      fetchDataForActiveTab();
   }, [
      activeTab,
      authData?.isAuthenticated,
      dispatch,
      params?.city,
      params?.country_id,
   ]);

   useEffect(() => {
      dispatch(getCountries());
   }, [dispatch]);

   const handleSelectChange = (selectedOption) => {
      setSelectedValue(selectedOption);
      setDepartureCountry(selectedOption?.country_id);
   };

   const handleItemClick = (item) => {
      const activity = params?.activity;
      switch (activity) {
         case "places":
            if (item === "clear_all") {
               setSelectedItemsPlace([]);
            } else {
               if (selectedItemsPlace && Array.isArray(selectedItemsPlace)) {
                  if (selectedItemsPlace.includes(item)) {
                     setSelectedItemsPlace(
                        selectedItemsPlace.filter(
                           (selectedItem) => selectedItem !== item
                        )
                     );
                  } else {
                     setSelectedItemsPlace([...selectedItemsPlace, item]);
                  }
               } else {
                  setSelectedItemsPlace([item]);
               }
            }
            break;
         case "food-drink":
            if (item === "clear_all") {
               setSelectedItemsFood([]);
            } else {
               if (selectedItemsFood && Array.isArray(selectedItemsFood)) {
                  if (selectedItemsFood.includes(item)) {
                     setSelectedItemsFood(
                        selectedItemsFood.filter(
                           (selectedItem) => selectedItem !== item
                        )
                     );
                  } else {
                     setSelectedItemsFood([...selectedItemsFood, item]);
                  }
               } else {
                  setSelectedItemsFood([item]);
               }
            }
            break;
         case "events":
            if (item === "clear_all") {
               setSelectedItemsEvent([]);
            } else {
               if (selectedItemsEvent && Array.isArray(selectedItemsEvent)) {
                  if (selectedItemsEvent.includes(item)) {
                     setSelectedItemsEvent(
                        selectedItemsEvent.filter(
                           (selectedItem) => selectedItem !== item
                        )
                     );
                  } else {
                     setSelectedItemsEvent([...selectedItemsEvent, item]);
                  }
               } else {
                  setSelectedItemsEvent([item]);
               }
            }
            break;
         default:
            break;
      }
   };

   const isItemSelected = (item) => {
      const activity = params?.activity;
      switch (activity) {
         case "places":
            return selectedItemsPlace.includes(item);
         case "food-drink":
            return selectedItemsFood.includes(item);
         case "events":
            return selectedItemsEvent.includes(item);
         default:
            return false;
      }
   };

   const handleSetType = (placeType) => {
      const activity = params?.activity;
      switch (activity) {
         case "places": {
            const params = {
               iso3: countryId,
               slug: placeType,
               type: selectedItemsPlace,
            };
            dispatch(getPlacesById(params));
            break;
         }
         case "food-drink": {
            const params = {
               iso3: countryId,
               slug: placeType,
               type: selectedItemsFood,
            };
            dispatch(getFoodAndDrinks(params));
            break;
         }
         case "events": {
            const params = {
               iso3: countryId,
               slug: placeType,
               type: selectedItemsEvent,
            };
            dispatch(getEvents(params));
            break;
         }
         default:
            break;
      }
   };

   const applyInterestOnClick = () => {
      setFiltersModalShow(false);
      const activity = params?.activity;
      const cityId = params?.city;
      switch (activity) {
         case "places": {
            const params = {
               iso3: countryId,
               slug: cityId,
               type: selectedItemsPlace,
            };
            dispatch(getPlacesById(params));
            break;
         }
         case "food-drink": {
            const params = {
               iso3: countryId,
               slug: cityId,
               type: selectedItemsFood,
            };
            dispatch(getFoodAndDrinks(params));
            break;
         }
         case "events": {
            const params = {
               iso3: countryId,
               slug: cityId,
               type: selectedItemsEvent,
            };
            dispatch(getEvents(params));
            break;
         }
         default:
            break;
      }
   };

   const onHandleNextPage = (selectedPage) => {
      const activity = params?.activity;
      switch (activity) {
         case "places":
            dispatch(getPlacesById({ page: selectedPage, iso3: countryId }));
            break;
         case "food-drink":
            dispatch(getFoodAndDrinks({ page: selectedPage, iso3: countryId }));
            break;
         case "events":
            dispatch(getEvents({ page: selectedPage, iso3: countryId }));
            break;
         default:
            break;
      }
   };

   const options = countriesList?.data?.data?.map((data, index) => ({
      value: data.name,
      label: (
         <div key={index} className="r-img-lael">
            <img
               src={data.flag}
               alt={data.name}
               style={{ width: "24px", marginRight: "10px" }}
            />
            {data.name}
         </div>
      ),
      country_id: data.id,
   }));

   const _planTrip = async () => {
      const countryId =
         category === 3 ? eventPopup?.country : popupData?.country;
      const data = {
         country_id: countryId,
         departure_country_id: departureCountry,
      };
      const response = await dispatch(planTrip(data));
      if (response?.payload?.data?.status) {
         const addToItenery = async () => {
            const popupDataId = category === 3 ? eventPopup?.id : popupData?.id;
            const data = {
               tripId: response?.payload?.data?.trip_id,
               locationId: popupDataId,
            };
            const response_ = await dispatch(addItenery(data));
            if (response_?.payload?.status) {
               navigate(`/trip-detail/${response?.payload?.data?.trip_id}`);
            }
            setTripsModalShow(false);
            toast.success("Location added successfully");
            setCategory(null);
         };
         addToItenery();
      }
      if (response?.payload?.status === 400) {
         toast.error(response?.payload?.data?.message);
         setIsItenerary(false);
         setSelectedValue(null);
      }
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         setSelectedValue(null);
         dispatch(getActiveTrips());
      }
   };

   const addToItenery = async () => {
      const response = await dispatch(getItenerys(tripId));
      if (response?.payload?.status === 200) {
         const popupDataId = category === 3 ? eventPopup?.id : popupData?.id;
         const previousTripIteneraries = response?.payload?.data?.data;
         const isIteneraryPresent = previousTripIteneraries?.some(
            (itinerary) => itinerary?.location?.id === popupDataId
         );
         if (isIteneraryPresent) {
            setTripsModalShow(false);
            toast.error("This location is already present in itineraries");
         } else {
            const data = { tripId: tripId, locationId: popupDataId };
            const _addItenery = async () => {
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  navigate(`/trip-detail/${tripId}`);
                  setTripsModalShow(false);
                  toast.success(response_?.payload?.data?.message);
                  setCategory(null);
               }
            };
            _addItenery();
         }
      }
   };

   const hadleViewOnClick = (data) => {
      setIsItenerary(true);
      if (data?.category === 1 || data?.category === 2) {
         setPopupData(data);
         setProductDetailShow(true);
      } else {
         setEventShow(true);
         setEventPopup(data);
      }
   };
   return (
      <div>
         <ProductDetails
            productDetail={popupData}
            setProductDetailShow={setProductDetailShow}
            prooductDetailShow={prooductDetailShow}
            setTripTypeModalShow={setTripTypeModalShow}
            setIsItenerary={setIsItenerary}
            isFrom={"activity"}
            haveTripDetails={location?.state?.trip_details}
         />
         <TripTypeModal
            setTripTypeModalShow={setTripTypeModalShow}
            tripTypeModalShow={tripTypeModalShow}
            setTripType={setTripType}
            tripType={tripType}
            setYourLocationShow={setYourLocationShow}
            setTripsModalShow={setTripsModalShow}
            availableTrips={userActiveTripsList}
            selectedCountryId={
               category === 3 ? eventPopup?.country : popupData?.country
            }
            setIsItenerary={setIsItenerary}
         />
         <YourLocationModal
            show={yourLocationShow}
            setShow={setYourLocationShow}
            options={options}
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
            departureCountry={departureCountry}
            setSelectedValue={setSelectedValue}
            setIsItenerary={setIsItenerary}
            isItenerary={isItenerary}
            _planTrip={_planTrip}
         />
         <AvalailableTrips
            show={tripsModalShow}
            setTripsModalShow={setTripsModalShow}
            setTripId={setTripId}
            tripId={tripId}
            addToItenery={addToItenery}
            selectedCountryId={
               category === 3 ? eventPopup?.country : popupData?.country
            }
            userActiveTripsList={userActiveTripsList}
         />
         <EventPopup
            show={eventShow}
            setShow={setEventShow}
            eventPopup={eventPopup}
            setTripTypeModalShow={setTripTypeModalShow}
            setIsItenerary={setIsItenerary}
            isFrom={"activity"}
            haveTripDetails={location?.state?.trip_details}
         />
         <MobileTopHeader title={"Explore"} />
         <Tabs />
         {(isPlaces || isFoodDrink) && (
            <Activity
               productList={isPlaces ? placesList : foodAndDrinksList}
               filtersList={isPlaces ? placesFilters : foodAndDrinksFilters}
               selectedItemsPlace={
                  isPlaces ? selectedItemsPlace : selectedItemsFood
               }
               handleSetType={handleSetType}
               onHandleNextPage={onHandleNextPage}
               viewDetailOnClick={hadleViewOnClick}
               setFiltersModalShow={setFiltersModalShow}
               loading={isPlaces ? placesLoading : foodAndDrinksLoading}
               category={isPlaces ? "places" : "food-drink"}
            />
         )}
         {isEvent && (
            <div>
               {eventsLoading ? (
                  <Spinner height={50} />
               ) : (
                  <div className="my-3">
                     {eventsList?.data?.data?.total_count > 0 && (
                        <div className="container">
                           <div className="row acitivity-subhead my-lg-4 my-md-3 my-2">
                              <div className="col-lg-7 col-md-10 col-12">
                                 <div className="col-sm-6 col-12 d-flex align-items-center justify-md-content-center justify-content-sm-between justify-content-around">
                                    <div className="d-flex">
                                       <img
                                          className="m-0 me-2 icon-style"
                                          src={eventIcon}
                                          alt=""
                                       />
                                       <p className="m-0">Events</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-5 col-md-2 d-flex align-items-center justify-content-end d-md-block d-none">
                                 <button
                                    className="filter-button float-end position-relative"
                                    onClick={() => setFiltersModalShow(true)}
                                 >
                                    <img src={filterIcon} alt="filter" />
                                    Filter
                                    {selectedItemsEvent?.length > 0 && (
                                       <Badge
                                          bg="info"
                                          className="text-primary rounded-circle filter-batch position-absolute"
                                       >
                                          {selectedItemsEvent?.length}
                                       </Badge>
                                    )}
                                 </button>
                              </div>
                           </div>
                           <div className="row my-2">
                              <div className="col-12 filter-cities">
                                 <ul className="d-flex list-unstyled text-decoration-none flex-wrap m-0">
                                    <li
                                       onClick={() => {
                                          navigate(
                                             `/${params?.country_id}/all/${params?.activity}`
                                          );
                                       }}
                                       className={
                                          params?.city === "all"
                                             ? "active cursor-pointer"
                                             : "cursor-pointer"
                                       }
                                    >
                                       <a className="text-decoration-none">
                                          All
                                       </a>
                                    </li>
                                    {eventsFilters?.data?.data?.Cities?.map(
                                       (filter, index) => (
                                          <li
                                             onClick={() => {
                                                navigate(
                                                   `/${params?.country_id}/${filter?.slug}/${params?.activity}`
                                                );
                                             }}
                                             className={
                                                params?.city === filter?.slug
                                                   ? "active cursor-pointer"
                                                   : "cursor-pointer"
                                             }
                                             key={index}
                                          >
                                             <a className="text-decoration-none">
                                                {filter?.name}
                                             </a>
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                           </div>
                        </div>
                     )}

                     <div className="filter-button-sm d-md-none d-block d-flex align-items-center justify-content-center">
                        <button
                           onClick={() => setFiltersModalShow(true)}
                           className="border-0 bg-transparent d-flex align-items-center justify-content-center p-3 m-0 position-relative"
                        >
                           <Icon
                              name="Filter"
                              className="me-2"
                              width={22}
                              height={22}
                           />
                           Filter
                           {selectedItemsEvent?.length > 0 && (
                              <Badge
                                 bg="info"
                                 className="text-primary rounded-circle filter-batch position-absolute"
                              >
                                 {selectedItemsEvent?.length}
                              </Badge>
                           )}
                        </button>
                     </div>
                     <div className="container events-container mb-5 pb-1">
                        {eventsList?.data?.data?.count > 0 ? (
                           <>
                              <div className="row my-lg-4 my-md-3 my-2 row my-lg-3 my-md-3 my-2 row-cols-lg-3 row-cols-md-2 row-cols-1">
                                 {eventsList?.data?.data?.results?.map(
                                    (event, index) => (
                                       <div
                                          className="col mb-lg-3 mb-2 cursor-pointer"
                                          onClick={() => {
                                             hadleViewOnClick(event);
                                             setCategory(3);
                                          }}
                                          key={index}
                                       >
                                          <div
                                             className="text-light shadow px-3 d-flex align-content-center justify-content-around flex-column"
                                             style={{
                                                backgroundImage: `url('${eventBg}')`,
                                                backgroundPosition: "50%",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                borderRadius: "20px",
                                                height: "137px",
                                                position: "relative",
                                             }}
                                          >
                                             <div>
                                                <p className="m-0 event-name">
                                                   {event?.name_of_location}
                                                </p>
                                                <p className="m-0 event-date">
                                                   {event?.event_date}
                                                </p>
                                             </div>
                                             <p className="m-0 event-place">
                                                {event?.address}
                                             </p>
                                          </div>
                                       </div>
                                    )
                                 )}
                              </div>
                              {(eventsList?.data?.data?.next ||
                                 eventsList?.data?.data?.previous) && (
                                 <Pagination
                                    placesList={eventsList}
                                    onHandleNextPage={onHandleNextPage}
                                 />
                              )}
                           </>
                        ) : (
                           <div>
                              {eventsList?.data?.data?.results && (
                                 <div className="d-flex align-items-center gap-4 justify-content-center flex-column">
                                    <img src={noEventsImage} alt="" />
                                    <p className="no-data-text">
                                       No events found
                                    </p>
                                 </div>
                              )}
                           </div>
                        )}
                     </div>
                  </div>
               )}
            </div>
         )}
         <Modal
            show={filtersModalShow}
            onHide={() => setFiltersModalShow(false)}
            keyboard={false}
            centered
            className="modal-common-height"
         >
            <Modal.Body>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <hr className="modal-top-hr_ m-0" />
                  <div className="my-3 d-flex align-items-center justify-content-md-center justify-content-between mx-2 w-100">
                     <p className="m-0 text-center modal-title">Filter</p>
                     <Icon
                        className="modal-close-btn cursor-pointer d-md-none d-block"
                        onClick={() => {
                           setFiltersModalShow(false);
                        }}
                        name={"Close"}
                     />
                  </div>
               </div>
               <div className="interests-content">
                  <p className="mb-0 filter-title">Type</p>
               </div>
               <div className="filter-btn-content">
                  {params.activity === "places" && (
                     <div>
                        {placesFilters?.data?.data?.Interests?.map(
                           (data, index) => (
                              <button
                                 key={index}
                                 className="filter-btn-item m-1"
                                 style={{
                                    backgroundColor: isItemSelected(data?.id)
                                       ? "#f0e9ff"
                                       : "transparent",
                                 }}
                                 onClick={() => {
                                    handleItemClick(data?.id);
                                 }}
                              >
                                 {data?.name}
                              </button>
                           )
                        )}
                        <>
                           {selectedItemsPlace?.length > 0 && (
                              <button
                                 className="filter-btn-item rounded border-danger m-1 d-flex align-items-center justify-content-center py-2 pe-1 ps-3"
                                 onClick={() => {
                                    handleItemClick("clear_all");
                                 }}
                              >
                                 <div className="text-danger fw-bold">
                                    Clear all
                                 </div>
                                 <TiDelete size={25} color="red" />
                              </button>
                           )}
                        </>
                     </div>
                  )}
                  {params.activity === "food-drink" && (
                     <div>
                        {foodAndDrinksFilters?.data?.data?.Type?.map(
                           (data, index) => (
                              <button
                                 key={index}
                                 className="filter-btn-item m-1"
                                 style={{
                                    backgroundColor: isItemSelected(data?.id)
                                       ? "#f0e9ff"
                                       : "transparent",
                                 }}
                                 onClick={() => {
                                    handleItemClick(data?.id);
                                 }}
                              >
                                 {data?.name}
                              </button>
                           )
                        )}
                        <>
                           {selectedItemsFood?.length > 0 && (
                              <button
                                 className="filter-btn-item border-danger m-1 d-flex align-items-center justify-content-center py-2 pe-1 ps-3"
                                 onClick={() => {
                                    handleItemClick("clear_all");
                                 }}
                              >
                                 <div className="text-danger fw-bold">
                                    Clear all
                                 </div>
                                 <TiDelete size={25} color="red" />
                              </button>
                           )}
                        </>
                     </div>
                  )}
                  {params.activity === "events" && (
                     <div>
                        {eventsFilters?.data?.data?.Type?.map((data, index) => (
                           <button
                              key={index}
                              className="filter-btn-item m-1"
                              style={{
                                 backgroundColor: isItemSelected(data?.id)
                                    ? "#f0e9ff"
                                    : "transparent",
                              }}
                              onClick={() => {
                                 handleItemClick(data?.id);
                              }}
                           >
                              {data?.name}
                           </button>
                        ))}
                        <>
                           {selectedItemsEvent?.length > 0 && (
                              <button
                                 className="filter-btn-item m-1 rounded border-danger d-flex align-items-center justify-content-center py-2 pe-1 ps-3"
                                 onClick={() => {
                                    handleItemClick("clear_all");
                                 }}
                              >
                                 <div className="text-danger fw-bold">
                                    Clear all
                                 </div>
                                 <TiDelete size={25} color="red" />
                              </button>
                           )}
                        </>
                     </div>
                  )}
               </div>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
               <button onClick={applyInterestOnClick} className="w-100">
                  Apply
               </button>
            </Modal.Footer>
         </Modal>
         {isPlaces ? (
            <div className="filter-button-sm d-md-none d-block d-flex align-items-center justify-content-center">
               <button
                  onClick={() => setFiltersModalShow(true)}
                  className="border-0 bg-transparent d-flex align-items-center justify-content-center p-3 m-0 position-relative"
               >
                  <Icon name="Filter" className="me-2" width={22} height={22} />
                  Filter
                  {selectedItemsPlace?.length > 0 && (
                     <Badge
                        bg="info"
                        className="text-primary rounded-circle filter-batch position-absolute"
                     >
                        {selectedItemsPlace?.length}
                     </Badge>
                  )}
               </button>
            </div>
         ) : (
            <div className="filter-button-sm d-md-none d-block d-flex align-items-center justify-content-center">
               <button
                  onClick={() => setFiltersModalShow(true)}
                  className="border-0 bg-transparent d-flex align-items-center justify-content-center p-3 m-0 position-relative"
               >
                  <Icon name="Filter" className="me-2" width={22} height={22} />
                  Filter
                  {selectedItemsFood?.length > 0 && (
                     <Badge
                        bg="info"
                        className="text-primary rounded-circle filter-batch position-absolute"
                     >
                        {selectedItemsFood?.length}
                     </Badge>
                  )}
               </button>
            </div>
         )}
      </div>
   );
}

export default ActivitiesTab;
