import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

export default function Image_({ imageSrc, styles }) {
   const [isLoaded, setIsLoaded] = useState(false);

   useEffect(() => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
         setIsLoaded(true);
      };

      return () => {
         image.onload = null;
         setIsLoaded(false);
      };
   }, [imageSrc]);
   return (
      <div>
         {isLoaded ? (
            <img src={imageSrc} style={styles} alt="" />
         ) : (
            <Skeleton style={styles} />
         )}
      </div>
   );
}
