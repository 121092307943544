import React from "react";
import { useNavigate } from "react-router-dom";
import headerAction from "../assets/images/icon/header-action.svg";

export default function MobileTopHeader({ title }) {
   const navigate = useNavigate();
   return (
      <div className="container-fluid my-trip-mobile-header-explore d-md-none d-block d-flex align-items-center justify-content-center">
         <p className="m-0 mobile-header-title text-center">{title}</p>
         <img
            className="position-absolute"
            src={headerAction}
            alt=""
            onClick={() => navigate(-1)}
         />
      </div>
   );
}
