import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import "./include/css/slider.css";
import "./include/css/style.css";
import arrowNext from "./include/images/chevron_right.svg";
import arrowPrev from "./include/images/chevron_left.svg";
import Icon from "../../assets/Icons";
import { getReminDays } from "../../constants/common";
import "swiper/css";
import { useNavigate } from "react-router-dom";

const SliderComponent = ({ setYourLocationShow, activeTrips }) => {
   const navigate = useNavigate();

   const swiperRef = useRef(null);

   const [isStart, setIsStart] = useState(true);

   const [isEnd, setIsEnd] = useState();

   const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

   useEffect(() => {
      if (swiperRef.current) {
         swiperRef.current.destroy();
      }

      swiperRef.current = new Swiper(".reminder-slider", {
         slidesPerView: "auto",
         centeredSlides: false,
         spaceBetween: 24,
         loop: false,
         navigation: {
            nextEl: ".reminder-arrow-next",
            prevEl: ".reminder-arrow-prev",
         },
         pagination: {
            el: ".swiper-pagination",
            clickable: true,
         },
      });
      swiperRef.current.on("slideChangeTransitionEnd", handleSlideChange);
   }, [activeTrips]);

   useEffect(() => {
      handleSlideChange();
   }, []);

   const handleSlideChange = () => {
      if (swiperRef.current) {
         const isEndSlide = swiperRef.current.isEnd;
         const isFirstSlide = swiperRef.current.isBeginning;
         setIsEnd(isEndSlide);
         setIsStart(isFirstSlide);
         setCurrentSlideIndex(swiperRef.current.activeIndex);
      }
   };

   const calculateDaysDifference = (departureDate) => {
      const currentDate = new Date();
      const timeDifference = Math.abs(
         new Date(departureDate)?.getTime() - currentDate?.getTime()
      );
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return daysDifference;
   };

   const handleNextSlide = () => {
      if (swiperRef.current) {
         swiperRef.current.slideNext();
      }
   };

   const handlePrevSlide = () => {
      if (swiperRef.current) {
         swiperRef.current.slidePrev();
      }
   };

   return (
      <div className="container">
         <div className="row">
            <div className="col-md-12">
               <div className="reminder-slider-outer">
                  <div className="reminder-slider swiper">
                     <div className="reminder-list swiper-wrapper">
                        {activeTrips?.map((data, index) => {
                           return (
                              <div
                                 key={index}
                                 className="reminder-item swiper-slide"
                              >
                                 <div
                                    className="reminder-box bg-light box-shadow cursor-pointer"
                                    onClick={() =>
                                       navigate(`/trip-detail/${data?.id}`, {
                                          state: { trip: data },
                                       })
                                    }
                                 >
                                    <div className="rb-left">
                                       <div className="rb-left-image">
                                          <img
                                             src={data?.country?.flag}
                                             alt=""
                                          />
                                       </div>
                                       {data?.departure_date ? (
                                          <div className="rb-left-text">
                                             <h6>
                                                {calculateDaysDifference(
                                                   data?.departure_date
                                                )}{" "}
                                                days to go
                                             </h6>
                                             <p>
                                                for your {data?.country?.name}{" "}
                                                trip
                                             </p>
                                          </div>
                                       ) : (
                                          <div className="rb-left-text">
                                             <h6>
                                                Trip to {data?.country?.name}
                                             </h6>
                                          </div>
                                       )}
                                    </div>
                                    {data?.departure_date && (
                                       <div className="rb-right">
                                          <div className="rb-date-box">
                                             <p>
                                                {getReminDays(
                                                   data?.departure_date,
                                                   data?.return_date
                                                )}
                                             </p>
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              </div>
                           );
                        })}
                        <div
                           className="reminder-item swiper-slide cursor-pointer"
                           index={activeTrips?.length + 1}
                           onClick={() => setYourLocationShow(true)}
                        >
                           <div className="reminder-box bg-light box-shadow">
                              <div className="rb-left">
                                 <div className="rb-left-text">
                                    <h6>Plan your trip</h6>
                                    <p className="">
                                       Build your itinerary effortlessly
                                    </p>
                                 </div>
                              </div>
                              <div className="rb-right cursor-pointer">
                                 <div>
                                    <Icon name={"Plan_your_trip"} />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {!isEnd && (
                        <div
                           className="reminder-arrow reminder-arrow-next cursor-pointer"
                           onClick={handleNextSlide}
                        >
                           <img src={arrowNext} alt="" />
                        </div>
                     )}
                     {!isStart && (
                        <div
                           className="reminder-arrow reminder-arrow-prev cursor-pointer"
                           onClick={handlePrevSlide}
                        >
                           <img src={arrowPrev} alt="" />
                        </div>
                     )}
                  </div>
                  <div className="container d-flex align-items-center justify-content-center">
                     <div className="swiper-pagination d-flex swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal position-absolute">
                        {activeTrips?.map((_, index) => (
                           <span
                              key={index}
                              style={{ width: "8px", height: "8px" }}
                              className={`swiper-pagination-bullet me-2 ${
                                 index === currentSlideIndex
                                    ? "swiper-pagination-bullet-active"
                                    : ""
                              }`}
                              tabIndex="0"
                              role="button"
                              aria-label={`Go to slide ${index + 1}`}
                              aria-current={index === currentSlideIndex}
                              onClick={() => {
                                 if (swiperRef.current) {
                                    swiperRef.current.slideTo(index);
                                 }
                              }}
                           ></span>
                        ))}
                        <span
                           key={activeTrips?.length}
                           style={{ width: "8px", height: "8px" }}
                           className={`swiper-pagination-bullet ${
                              activeTrips?.length === currentSlideIndex
                                 ? "swiper-pagination-bullet-active"
                                 : ""
                           }`}
                           tabIndex="0"
                           role="button"
                           aria-label={`Go to slide ${activeTrips?.length + 1}`}
                           aria-current={
                              activeTrips?.length === currentSlideIndex
                           }
                           onClick={() => {
                              if (swiperRef.current) {
                                 swiperRef.current.slideTo(activeTrips?.length);
                              }
                           }}
                        ></span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SliderComponent;
