import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import tripLogo from "../../assets/images/icon/trip-active.svg";
import cancelIcon from "../../assets/images/icon/free_cancellation.png";
import { deleteTrip, getActiveTrips } from "../../redux/slices/tripSlices";
import noImage from "../../assets/images/background/noTrip.png";
import { MdDeleteOutline } from "react-icons/md";
import useAuth from "../../constants/useAuth";
import { getReminDays } from "../../constants/common";
import {
   VerifiedHeaderBottom,
   Spinner,
   MobileTopHeader,
   DeleteConfirmationModal,
} from "../../components";

export default function MyTrips() {
   const [authData] = useAuth();

   const navigate = useNavigate();

   const dispatch = useDispatch();

   const { userActiveTripsList, loading, deletetripLoading } = useSelector(
      (state) => state.trips
   );

   const [deleteModalShow, setDeleteModalShow] = useState(false);

   const [deleteData, setDeleteData] = useState(null);

   useEffect(() => {
      if (authData?.isAuthenticated) {
         dispatch(getActiveTrips());
      }
   }, [authData?.isAuthenticated, dispatch]);

   const deleteTripOnClick = async (id) => {
      try {
         // Step 1: Retrieve the current trips from local storage
         const tripsString = localStorage.getItem("trips");
         const trips = JSON.parse(tripsString) || [];

         // Step 2: Find the trip with the specified id and remove it
         const updatedTrips = trips.filter((trip) => trip.trip_id !== id);

         // Step 3: Update the local storage with the modified trips array
         localStorage.setItem("trips", JSON.stringify(updatedTrips));

         // Assuming you are using Redux, dispatch the deleteTrip action
         const response = await dispatch(deleteTrip(id));

         if (response?.payload) {
            setDeleteModalShow(false);
         }
      } catch (error) {
         // Handle any errors that may occur during the process
         console.error("Error deleting trip:", error);
         // You can show an error message to the user if necessary.
         // toast.error('Failed to delete trip.');
      }
   };

   return (
      <>
         <div style={{ paddingTop: "1px" }}>
            <MobileTopHeader title={"My Trips"} />
            <div className="d-md-none d-sm-block">
               <VerifiedHeaderBottom />
            </div>
            <DeleteConfirmationModal
               show={deleteModalShow}
               setShow={setDeleteModalShow}
               type={"trip"}
               yesOnClick={deleteTripOnClick}
               data={deleteData}
               setDeleteData={setDeleteData}
               loading={deletetripLoading}
            />
            <div className="container my-trip my-md-5 my-3">
               <div className="d-md-flex gap-3 align-items-center justify-content-start d-md-block d-none">
                  <img src={tripLogo} alt="" />
                  <p className="mb-2">My Trips</p>
               </div>
               {loading ? (
                  <Spinner height={60} />
               ) : (
                  <div>
                     {!userActiveTripsList?.data?.data?.length > 0 ? (
                        <div className="d-flex align-items-center justify-content-center flex-column my-5 gap-4">
                           <img src={noImage} alt="" />
                           <p className="no-data-text m-0">No trips found</p>
                           <p className="no-data-text-child m-0">
                              Plan your first trip today.
                           </p>
                           <button
                              className="d-flex border-0 align-items-center justify-content-center gap-2 plan-trip-button"
                              onClick={() => navigate("/")}
                           >
                              <p className="m-0 text-light">+</p>
                              <p className="m-0 text-light">Plan A Trip</p>
                           </button>
                        </div>
                     ) : (
                        <div className="padding-top-mobile">
                           {userActiveTripsList?.data?.data?.map(
                              (trip, index) => (
                                 <div
                                    key={index}
                                    className="d-flex row align-items-center justify-content-center"
                                 >
                                    <div
                                       className="cursor-pointer d-md-flex col-md-9 col-10 align-items-center justify-content-center trip box-shadow my-2"
                                       onClick={() =>
                                          navigate(`/trip-detail/${trip?.id}`, {
                                             state: { trip: trip },
                                          })
                                       }
                                    >
                                       <div className="col-md-6 col-12 d-flex gap-2 align-items-center  justify-content-start">
                                          <img
                                             src={trip?.country?.flag}
                                             alt=""
                                          />
                                          <p className="m-0">
                                             Trip to {trip?.country?.name}
                                          </p>
                                       </div>
                                       <div className="col-md-6 col-12 d-flex align-items-center justify-content-md-end mt-md-0 mt-sm-3 mt-3 justify-content-start">
                                          <button className="d-flex align-items-center w-100 justify-content-center gap-2 bg-transparent">
                                             <img src={cancelIcon} alt="" />
                                             {trip?.departure_date ? (
                                                <div>
                                                   {getReminDays(
                                                      trip?.departure_date,
                                                      trip?.return_date
                                                   )}
                                                </div>
                                             ) : (
                                                "Set your trip date for better assistance"
                                             )}
                                          </button>
                                       </div>
                                    </div>
                                    <div className="col-md-3 col-2">
                                       <MdDeleteOutline
                                          onClick={() => {
                                             setDeleteModalShow(true);
                                             setDeleteData(trip);
                                          }}
                                          className="cursor-pointer"
                                          size={25}
                                          color="red"
                                       />
                                    </div>
                                 </div>
                              )
                           )}
                        </div>
                     )}
                  </div>
               )}
            </div>
         </div>
      </>
   );
}
