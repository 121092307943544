export default class Config {
   static BASE_API_URL = "https://api.trundle.world/api-v1";
   static GET_OTP_API_URL = this.BASE_API_URL + "/account/resend-otp";
   static SIGNUP_API_URL = this.BASE_API_URL + "/account/register";
   static LOGIN_API_URL = this.BASE_API_URL + "/account/resend-otp";
   static VERIFY_OTP_API_URL = this.BASE_API_URL + "/account/verify-otp";
   static GET_USER_DETAILS_API_URL = this.BASE_API_URL + "/account/get-user";
   static GET_LOCATION_BY_ID_API_URL =
      this.BASE_API_URL + "/location/get-location";
   static GET_USER_TRIP_DETAILS_API_URL =
      this.BASE_API_URL + "/itinerary/get-trip-itineraries";
   static GET_HOME_DATA_API_URL = this.BASE_API_URL + "/location/all";
   static GET_PLACES_API_URL = this.BASE_API_URL + "/location/get-places";
   static GET_FOOD_DRINKS_API_URL =
      this.BASE_API_URL + "/location/get-foods-drinks";
   static GET_EVENTS_API_URL = this.BASE_API_URL + "/location/get-events";
   static GET_EVENTS_FILTER_API_URL =
      this.BASE_API_URL + "/location/get-events-filter";
   static GET_FOOD_AND_DRINKS_FILTERS_API_URL =
      this.BASE_API_URL + "/location/get-foods-drinks-filter";
   static GET_PLACES_FILTERS_API_URL =
      this.BASE_API_URL + "/location/get-places-filter";
   static GET_COUNTRIES_API_URL = this.BASE_API_URL + "/account/get-countries";
   static GET_AVAILABLE_COUNTRIES_API_URL =
      this.BASE_API_URL + "/account/get-available-countries";
   static UPDATE_USER_DETAILS_API_URL =
      this.BASE_API_URL + "/account/update-profile";
   static GET_COUNTRY_LISTS_API_URL =
      this.BASE_API_URL + "/account/get-countries";
   static ADD_BOOKMARK_API_URL = this.BASE_API_URL + "/itinerary/add-bookmark";
   static GET_BOOKMARK_API_URL = this.BASE_API_URL + "/itinerary/get-bookmarks";
   static DELETE_BOOKMARK_API_URL =
      this.BASE_API_URL + "/itinerary/remove-bookmark";
   static ADD_ITENERY_API_URL = this.BASE_API_URL + "/itinerary/add-itinerary";
   static GET_ITENERY_API_URL =
      this.BASE_API_URL + "/itinerary/trip-itineraries";
   static DELETE_ITENERY_API_URL =
      this.BASE_API_URL + "/itinerary/remove-itinerary";
   static GET_TIPS_API_URL = this.BASE_API_URL + "/trip/get-tips";
   static GET_EMERGENCY_API_URL = this.BASE_API_URL + "/trip/get-emergency";
   static UPDATE_TRIP_DATE_API_URL = this.BASE_API_URL + "/trip/update-trip";
   static GET_LEARN_LANGAUGES_API_URL =
      this.BASE_API_URL + "/trip/get-learn-languages";
   static GET_TRIPS_API_URL = this.BASE_API_URL + "/trip/get-trip";
   static GET_ACTIVE_TRIPS_API_URL = this.BASE_API_URL + "/trip/user-trips";
   static PLAN_TRIP_API_URL = this.BASE_API_URL + "/trip/plan-trip";
   static ADD_SPENDING_API_URL = this.BASE_API_URL + "/trip/add-spending";
   static REMOVE_SPENDING_API_URL = this.BASE_API_URL + "/trip/remove-spending";
   static UPDATE_SPENDING_API_URL = this.BASE_API_URL + "/trip/update-spending";
   static ADD_SPENDING_AMOUNT_API_URL =
      this.BASE_API_URL + "/trip/update-spending-budget";
   static GET_SPENDING_CATEGORY_API_URL =
      this.BASE_API_URL + "/trip/get-spending-categories";
   static GET_SPENDING_ID_API_URL =
      this.BASE_API_URL + "/trip/get-trip-spending";
   static GET_SPENDINGS_API_URL = this.BASE_API_URL + "/trip/get-spending";
   static POST_CONTACT_US_API_URL = this.BASE_API_URL + "/support/enquiry";
   static DELETE_TRIP_API_URL = this.BASE_API_URL + "/trip/cancel-trip";
}
