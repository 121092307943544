import { combineReducers } from "redux";
import userData from "./slices/authSlices";
import userDetails from "./slices/userSlices";
import placesSlices from "./slices/placesSlices";
import foodAndDrinksSlices from "./slices/foodAndDrinksSlices";
import eventsSlices from "./slices/eventsSlices";
import itenerySlices from "./slices/itenerySlices";
import bookmarkSlices from "./slices/bookmarSlices";
import tripsSlices from "./slices/tripSlices";

const rootReducer = combineReducers({
   userData: userData,
   userDetails: userDetails,
   places: placesSlices,
   food_drinks: foodAndDrinksSlices,
   events: eventsSlices,
   iteneries: itenerySlices,
   bookmarks: bookmarkSlices,
   trips: tripsSlices,
});

export default rootReducer;
