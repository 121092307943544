import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getUserDetailsApi,
   getCountiesApi,
   updateUserApi,
   postContactUsApi,
} from "../api/user.api";
import { toast } from "react-hot-toast";

const initialState = {
   loading: false,
   updateLoading: false,
   userDetails: {},
   countriesList: {},
   updateUserRes: {},
   postContactUsRes: {},
   postContactLoading: false,
   error: null,
};

export const getUserDetails = createAsyncThunk("get_user_details", async () => {
   try {
      const res = await getUserDetailsApi();
      return res;
   } catch (err) {
      return err.response;
   }
});

export const getCountriesList = createAsyncThunk(
   "get_country_list",
   async () => {
      try {
         const res = await getCountiesApi();
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const updateUser = createAsyncThunk("update_user", async (data) => {
   try {
      const res = await updateUserApi(data);
      if (res?.data?.status) {
         toast.success(res?.data?.message);
      }
      return res;
   } catch (err) {
      return err.response;
   }
});

export const postContactUs = createAsyncThunk(
   "post_contact_us",
   async (data) => {
      try {
         const res = await postContactUsApi(data);
         if (res?.data?.status) {
            toast.success(res?.data?.message);
         }
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

const userSlice = createSlice({
   name: "user_details",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getUserDetails.pending, (state) => {
         state.loading = true;
         state.userDetails = {};
         state.error = null;
      });
      builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userDetails = payload;
         state.error = null;
      });
      builder.addCase(getUserDetails.rejected, (state, { payload }) => {
         state.loading = false;
         state.userDetails = {};
         state.error = payload;
      });

      builder.addCase(getCountriesList.pending, (state) => {
         state.loading = true;
         state.countriesList = {};
         state.error = null;
      });
      builder.addCase(getCountriesList.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.countriesList = payload;
         state.error = null;
      });
      builder.addCase(getCountriesList.rejected, (state, { payload }) => {
         state.loading = false;
         state.countriesList = {};
         state.error = payload;
      });

      builder.addCase(updateUser.pending, (state) => {
         state.updateLoading = true;
         state.updateUserRes = {};
         state.error = null;
      });
      builder.addCase(updateUser.fulfilled, (state, { payload }) => {
         state.updateLoading = false;
         state.updateUserRes = payload;
         state.error = null;
      });
      builder.addCase(updateUser.rejected, (state, { payload }) => {
         state.updateLoading = false;
         state.updateUserRes = {};
         state.error = payload;
      });

      builder.addCase(postContactUs.pending, (state) => {
         state.postContactLoading = true;
         state.postContactUsRes = {};
         state.error = null;
      });
      builder.addCase(postContactUs.fulfilled, (state, { payload }) => {
         state.postContactLoading = false;
         state.postContactUsRes = payload;
         state.error = null;
      });
      builder.addCase(postContactUs.rejected, (state, { payload }) => {
         state.postContactLoading = false;
         state.postContactUsRes = {};
         state.error = payload;
      });
   },
});

const { reducer } = userSlice;
export default reducer;
