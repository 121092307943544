import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getBookmarksApi = (category) => {
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   let url = Config.GET_BOOKMARK_API_URL;
   let params = {};

   if (category) {
      if (typeof category === "object") {
         const { page, category: categoryValue } = category;
         params = {
            page: page + 1,
            category: categoryValue,
         };
      } else {
         params = {
            category: category,
         };
      }
   }
   return axiosConfig.get(url, {
      ...requestOptions,
      params: params,
   });
};

export const addBookmarkApi = (locationId) => {
   let formData = new FormData();
   formData.append("location_id", locationId);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.ADD_BOOKMARK_API_URL,
      formData,
      requestOptions
   );
};

export const deleteBookmarkApi = (id) => {
   const token = localStorage.getItem("token");
   let formData = new FormData();
   formData.append("bookmark_id", id);
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.DELETE_BOOKMARK_API_URL,
      formData,
      requestOptions
   );
};
