import React, { useEffect, useState } from "react";
import hikingIcon from "../../assets/images/icon/hiking.svg";
import locationIcon from "../../assets/images/icon/location.png";
import alertPlanTripImage from "../../assets/images/background/alert-plantrip.svg";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import noImage from "../../assets/images/icon/no-image.png";
import { useNavigate } from "react-router-dom";
import ProductDetails from "../../components/ProductDetails";
import { addItenery } from "../../redux/slices/itenerySlices";
import { toast } from "react-hot-toast";
import Icon from "../../assets/Icons";
import MobileTopHeader from "../../components/MobileTopHeader";
import { MdDeleteOutline } from "react-icons/md";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

export default function TripPlan() {
   const dispatch = useDispatch();

   const location = useLocation();

   const navigate = useNavigate();

   const [prooductDetailShow, setProductDetailShow] = useState(false);

   const [popupData, setPopupData] = useState({});

   const [isSavedItenerary] = useState(true);

   const [trip, setTrip] = useState({});

   const [isItenerary, setIsItenerary] = useState(false);

   const [deleteModalShow, setDeleteModalShow] = useState(false);

   const [deleteData, setDeleteData] = useState(null);

   useEffect(() => {
      let storedTrips = JSON.parse(localStorage.getItem("trips")) || [];
      const filteredTrips = storedTrips.filter(
         (trip) => trip.trip_id === location?.state?.trip_id
      );
      storedTrips = filteredTrips;
      setTrip(storedTrips[0]);
   }, [location?.state?.trip_id]);

   const viewDetailsOnClick = (id) => {
      setProductDetailShow(true);
      const filtered = trip?.itineraries?.filter((data) => data?.id === id);
      setPopupData(filtered?.[0]);
   };

   const saveItenerary = async () => {
      const objectIds = trip?.itineraries.map((obj) => parseInt(obj.id));
      let formData = new FormData();
      formData.append("trip_id", parseInt(trip?.trip_id));
      formData.append("location_id", objectIds?.join(","));
      const response = await dispatch(addItenery(formData));
      if (response?.payload?.data?.status) {
         toast.success(response?.payload?.data?.message);
         let storedTrips = JSON.parse(localStorage.getItem("trips")) || [];
         storedTrips.forEach((storedTrip) => {
            if (storedTrip.trip_id === trip?.trip_id) {
               storedTrip.itineraries = [];
            }
         });
         setTrip({});
         localStorage.setItem("trips", JSON.stringify(storedTrips));
         navigate("/");
      }
   };

   function deleteOnClick(idToDelete) {
      // Step 1: Retrieve the trips array from local storage
      const tripsString = localStorage.getItem("trips");
      if (!tripsString) {
         console.log("Trip data not found in local storage.");
         return;
      }
      try {
         // Parse the trips data from the string stored in local storage
         const trips = JSON.parse(tripsString);
         // Step 2: Find the trip with the specified trip_id
         const trip_id = location?.state?.trip_id;
         const tripIndex = trips.findIndex((trip) => trip.trip_id === trip_id);
         if (tripIndex === -1) {
            console.log("Trip not found with trip_id:", trip_id);
            return;
         }
         // Get the itineraries array from the found trip
         const itineraries = trips[tripIndex]?.itineraries;
         // Step 3: Find and delete the item in the itineraries array
         const indexToDelete = itineraries?.findIndex(
            (location) => location.id === idToDelete
         );
         if (indexToDelete !== -1) {
            itineraries?.splice(indexToDelete, 1);
            setDeleteModalShow(false);
            // Update the trip object in the state with the modified itineraries array
            const updatedTrip = {
               ...trips[tripIndex],
               itineraries: [...itineraries],
            };
            // Update the trips array in the state with the modified trip object
            const updatedTrips = [...trips];
            updatedTrips[tripIndex] = updatedTrip;
            // Step 4: Save the updated trips object back to local storage
            const updatedTripsString = JSON.stringify(updatedTrips);
            localStorage.setItem("trips", updatedTripsString);
            // Log the updated itineraries array
            setTrip((prevTrip) => ({
               ...prevTrip,
               itineraries: [...itineraries],
            }));
         } else {
            console.log("Location not found with id:", idToDelete);
         }
      } catch (error) {
         console.error("Error parsing or updating trip data:", error);
      }
   }

   return (
      <div className="position-relative">
         <MobileTopHeader title={"Itineraries"} />
         <DeleteConfirmationModal
            show={deleteModalShow}
            setShow={setDeleteModalShow}
            type={"itinerary"}
            yesOnClick={deleteOnClick}
            data={deleteData}
            setDeleteData={setDeleteData}
         />
         <div className="container mt-5 trip-plan-container pt-lg-5 pt-md-3">
            <div className="row">
               <div className="col-12 d-flex align-items-center justify-content-start">
                  <img className="me-2" src={hikingIcon} alt="logo" />
                  <p className="plan-trip-title m-0">Plan to trip</p>
               </div>
               <div className="mb-md-5">
                  {!trip?.itineraries?.length > 0 ? (
                     <div className="col-12 mt-md-4 mt-3 d-flex align-items-center justify-content-start">
                        <img src={alertPlanTripImage} alt="" />
                     </div>
                  ) : (
                     <div>
                        {trip?.itineraries?.map((data, index) => (
                           <div key={index} className="col-12 my-3 d-flex">
                              <div className="status d-flex flex-column align-items-center justify-content-center">
                                 <div className="dots"></div>
                                 <div className="line"></div>
                              </div>
                              <div className="card trip-card ms-md-4 ms-sm-3 ms-2 border-0">
                                 <div className="media itenery-trip-width shadow">
                                    <img
                                       src={
                                          data?.locationimage_set?.[0]
                                             ? data?.locationimage_set?.[0]
                                                  ?.image
                                             : noImage
                                       }
                                       className="mr-3 w-100"
                                       alt="Image"
                                    />
                                    <div className="media-body p-4 row">
                                       <div className="col-md-6 col-12 d-flex flex-column">
                                          <h5 className="card-title">
                                             {data?.name_of_location}
                                          </h5>
                                          <p className="card-text text-muted m-0">
                                             {data?.address}
                                          </p>
                                          {data?.cost_description && (
                                             <p className="entry mb-0 mt-2">
                                                {data?.cost_description}
                                             </p>
                                          )}
                                       </div>
                                       <div className="col-md-6 col-12 d-flex align-items-center justify-content-between px-0">
                                          <button
                                             className="m-1"
                                             onClick={() =>
                                                viewDetailsOnClick(data?.id)
                                             }
                                          >
                                             View Details
                                          </button>
                                          <a
                                             href={data?.google_map}
                                             target="_blank"
                                          >
                                             <img
                                                className="m-1"
                                                src={locationIcon}
                                                alt="icon"
                                             />
                                          </a>
                                          <div
                                             onClick={() => {
                                                setDeleteData(data);
                                                setDeleteModalShow(true);
                                             }}
                                             className="cursor-pointer bookmark-delete-button d-flex align-items-center justify-content-center"
                                          >
                                             <MdDeleteOutline
                                                className=""
                                                size={18}
                                                color="red"
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        ))}
                     </div>
                  )}
               </div>
            </div>
         </div>
         <ProductDetails
            prooductDetailShow={prooductDetailShow}
            setProductDetailShow={setProductDetailShow}
            productDetail={popupData}
            isSavedItenerary={isSavedItenerary}
            setIsItenerary={setIsItenerary}
         />
         <div className="position-relative">
            <div className="container-fluid d-flex align-items-center justify-content-center py-md-4 py-3 bg-light shadow plan-trip-fluid fixed-bottom my-md-5 my-0">
               <button
                  disabled={!trip?.itineraries?.length > 0}
                  onClick={saveItenerary}
               >
                  Save Itinerary
               </button>
               <div className="container floating-icon position-absolute">
                  <button className="bg-transparent border-0 cursor-none">
                     <Icon
                        className="cursor-pointer"
                        onClick={() => navigate("/activities/places")}
                        name={"AddItinerary"}
                     />
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
