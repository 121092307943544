import axios from "axios";
import Config from "../constants/Config";

const instance = axios.create({
   baseURL: Config.BASE_API_URL,
   headers: {
      "Content-Type": "application/json",
   },
});

export default instance;
