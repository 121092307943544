import React from "react";
import noImage from "../assets/images/icon/no-image.png";
import Icon from "../assets/Icons";
import Image from "./Image";

export default function HomeSection({
   title,
   items,
   onClickCallback,
   navigateCallback,
}) {
   return (
      <section>
         <div className="place-header-content">
            <p className="m-0">{title}</p>
         </div>
         <div className="place-wrap">
            {items?.map((item, index) => (
               <div
                  key={index}
                  className="place-card box-shadow"
                  onClick={() => {
                     onClickCallback(item.id, item.category);
                  }}
               >
                  <div className="position-relative">
                     <Image
                        imageSrc={
                           item.locationimage_set?.[0]?.image
                              ? item.locationimage_set[0].image
                              : noImage
                        }
                        styles={{
                           borderRadius: "15px",
                           height: "128px",
                           width: "192px",
                           objectFit: "cover",
                           objectPosition: "center",
                        }}
                     />
                     {item.locationimage_set?.[0]?.is_representational && (
                        <p className="is-representational-home container text-center position-absolute text-light fst-italic">
                           For Representational Purpose
                        </p>
                     )}
                  </div>
                  <div className="p-3 d-flex flex-column home-card justify-content-between">
                     <div>
                        <p className="todoroki-content m-0">
                           {item.name_of_location}
                        </p>
                        <p className="nature-content">
                           {item.type?.name || item.type_of_food_drinks}
                        </p>
                     </div>
                     <div>
                        <p className="seoul-content">{item.city?.name}</p>
                     </div>
                  </div>
               </div>
            ))}
            <div className="show-more-content">
               <button className="show-more-btn" onClick={navigateCallback}>
                  <Icon name="Show_more" />
                  <p className="mt-3">Show More</p>
               </button>
            </div>
         </div>
      </section>
   );
}
