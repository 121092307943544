import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   getBookmarksApi,
   addBookmarkApi,
   deleteBookmarkApi,
} from "../api/bookmarks.api";
import toast from "react-hot-toast";

const initialState = {
   loading: false,
   bookmarksList: {},
   deleteBookmarkLoading: false,
   error: null,
};

export const getBookmarks = createAsyncThunk(
   "get_bookmarks",
   async (category) => {
      try {
         const res = await getBookmarksApi(category);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

export const deleteBookmark = createAsyncThunk(
   "delete_bookmarks",
   async (id) => {
      try {
         const res = await deleteBookmarkApi(id);
         toast.success(res?.data?.message);
         return id;
      } catch (err) {
         return err.response;
      }
   }
);

export const addBookmark = createAsyncThunk(
   "add_bookmarks",
   async (locationId) => {
      try {
         const res = await addBookmarkApi(locationId);
         return res;
      } catch (err) {
         return err.response;
      }
   }
);

const bookmarkSlices = createSlice({
   name: "bookmarks",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getBookmarks.pending, (state) => {
            state.loading = true;
            state.bookmarksList = {};
            state.error = null;
         })
         .addCase(getBookmarks.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.bookmarksList = payload;
            state.error = null;
         })
         .addCase(getBookmarks.rejected, (state, { payload }) => {
            state.loading = false;
            state.bookmarksList = {};
            state.error = payload;
         })
         .addCase(addBookmark.pending, (state) => {
            state.loading = true;
            state.bookmarksList = {};
            state.error = null;
         })
         .addCase(addBookmark.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.bookmarksList = payload;
            state.error = null;
         })
         .addCase(addBookmark.rejected, (state, { payload }) => {
            state.loading = false;
            state.bookmarksList = {};
            state.error = payload;
         })
         .addCase(deleteBookmark.pending, (state) => {
            state.deleteBookmarkLoading = true;
            state.error = null;
         })
         .addCase(deleteBookmark.fulfilled, (state, { payload }) => {
            state.deleteBookmarkLoading = false;
            state.error = null;
            state.bookmarksList.data.data.results =
               state.bookmarksList.data.data.results.filter(
                  (bookmark) => bookmark.id !== payload
               );
         })
         .addCase(deleteBookmark.rejected, (state, { payload }) => {
            state.deleteBookmarkLoading = false;
            state.error = payload;
         });
   },
});

const { reducer } = bookmarkSlices;
export default reducer;
