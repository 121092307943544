import React, { useEffect } from "react";
import arrow from "../../assets/images/icon/arrow.svg";
import { useNavigate } from "react-router-dom";
import headerAction from "../../assets/images/icon/header-action.svg";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "../../redux/slices/userSlices";
import noImage from "../../assets/images/noImage.webp";
import useAuth from "../../constants/useAuth";
import Icon from "../../assets/Icons";
import VerifiedHeaderBottom from "../../components/VerifiedHeaderBottom";

export default function UpdateProfileMobile() {
   const navigate = useNavigate();

   const [authData, setAuthData] = useAuth();

   const { loading, userDetails } = useSelector((state) => state.userDetails);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getUserDetails());
   }, [dispatch]);
   return (
      <div>
         <div className="d-md-none d-sm-block">
            <VerifiedHeaderBottom />
         </div>
         <div className="profile-menu-mobile">
            <div className="sub-content-1">
               {loading ? (
                  <>
                     <div className="content-wrap">
                        <p className="text-light fw-bolder mt-2 pb-2">
                           Profile
                        </p>
                        <div
                           className="spinner-border text-light"
                           role="status"
                        >
                           <span className="sr-only"></span>
                        </div>
                     </div>
                  </>
               ) : (
                  <div className="content-wrap">
                     <p className="text-light fw-bolder pb-2 mt-2">Profile</p>
                     <img
                        className="mb-3"
                        src={
                           userDetails?.data?.data?.photo
                              ? userDetails?.data?.data?.photo
                              : noImage
                        }
                        alt="userProfile"
                     />
                     <p className="username">
                        {userDetails?.data?.data?.first_name}{" "}
                        {userDetails?.data?.data?.first_last_name}
                     </p>
                     <p className="email m-0">
                        {userDetails?.data?.data?.email}
                     </p>
                     <div
                        className="button-wrap"
                        onClick={() => navigate("/update-profile-form")}
                     >
                        <button className="secondary-button w-100">
                           Edit Profile
                        </button>
                     </div>
                  </div>
               )}
            </div>
            <div className="sub-content-2">
               <div className="content-wrap d-flex align-items-center justify-content-center flex-column gap-4">
                  <button
                     className="bg-transparent w-100 d-flex align-items-center justify-content-between border-0 gap-2"
                     onClick={() => navigate("/help")}
                  >
                     <div className="d-flex align-items-center justify-content-center gap-2">
                        <Icon name={"Help"} className="gap-5" />
                        <p className="m-0">Help</p>
                     </div>
                     <div className="arrow">
                        <img src={arrow} alt="arrow" />
                     </div>
                  </button>
                  <button
                     className="bg-transparent w-100 d-flex align-items-center justify-content-between border-0 gap-2"
                     onClick={() => {
                        navigate("/privacy-policy");
                     }}
                  >
                     <div className="d-flex align-items-center justify-content-center gap-2">
                        <Icon name={"Log_out"} className="gap-5" />
                        <p className="m-0">Legal</p>
                     </div>
                     <div className="arrow">
                        <img src={arrow} alt="arrow" />
                     </div>
                  </button>
                  <button
                     className="bg-transparent w-100 d-flex align-items-center justify-content-between border-0 gap-2"
                     onClick={() => {
                        localStorage?.removeItem("token");
                        setAuthData({
                           isAuthenticated: false,
                           user: null,
                        });
                        navigate("/login");
                     }}
                  >
                     <div className="d-flex align-items-center justify-content-center gap-2">
                        <Icon name={"Log_out"} className="gap-5" />
                        <p className="m-0">Log out</p>
                     </div>
                     <div className="arrow">
                        <img src={arrow} alt="arrow" />
                     </div>
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
