import React from "react";

const FormField = ({
   name,
   label,
   type,
   placeholder,
   onChange,
   onBlur,
   value,
   touched,
   error,
}) => (
   <div>
      <label htmlFor={name} className="contact-form-label">
         {label}
      </label>
      <input
         type={type}
         name={name}
         className="form-control"
         placeholder={placeholder}
         onChange={onChange}
         onBlur={onBlur}
         value={value}
      />
      {touched && error && <div className="error text-danger">{error}</div>}
   </div>
);

export default FormField;
