import ClipLoader from "react-spinners/ClipLoader";

function Spinner(props) {
   const color = "#6c27ff";

   const override = {
      display: "block",
      margin: "0 auto",
   };
   const sweetLoadingCss = {
      display: "flex",
      height: props?.height ? `${props?.height}vh` : "90vh",
      justifyContent: "center",
      alignItems: "center",
   };

   return (
      <div className="sweet-loading" style={sweetLoadingCss}>
         <ClipLoader
            color={color}
            loading={true}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
         />
      </div>
   );
}

export default Spinner;
