import Config from "../../constants/Config";
import axiosConfig from "../axiosConfig";

export const getIteneryApi = (trip_id) => {
   let formData = new FormData();
   formData.append("trip_id", trip_id);
   const token = localStorage.getItem("token");

   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.GET_ITENERY_API_URL,
      formData,
      requestOptions
   );
};

export const addIteneryApi = (data) => {
   let formData = new FormData();
   formData.append("trip_id", parseInt(data?.tripId));
   formData.append("location_id", parseInt(data?.locationId));
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.ADD_ITENERY_API_URL,
      formData,
      requestOptions
   );
};

export const deleteIteneryApi = (id) => {
   let formData = new FormData();
   formData.append("itinerary_id", id);
   const token = localStorage.getItem("token");
   const requestOptions = {
      headers: {
         "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${token}`,
      },
   };
   return axiosConfig.post(
      Config.DELETE_ITENERY_API_URL,
      formData,
      requestOptions
   );
};
