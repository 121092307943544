import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import locationIcon from "../assets/images/icon/activity/location.svg";
import calanderIcon from "../assets/images/icon/activity/calendar.svg";
import bookmarkIcon from "../assets/images/icon/activity/bookmark.svg";
import noImage from "../assets/images/icon/no-image.png";
import { useDispatch } from "react-redux";
import { addBookmark } from "../redux/slices/bookmarSlices";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useAuth from "../constants/useAuth";
import Icon from "../assets/Icons";
import { updateIsBookmarkHome } from "../redux/slices/placesSlices";
import { updateIsBookmarkEvent } from "../redux/slices/eventsSlices";
import { addItenery, getItenerys } from "../redux/slices/itenerySlices";

export default function EventPopup({
   show,
   setShow,
   eventPopup,
   setTripTypeModalShow,
   setIsItenerary,
   isBookmark,
   isFrom,
   haveTripDetails,
}) {
   const [authData] = useAuth();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [loading, setLoading] = useState(false);

   const addToItenery = async () => {
      setLoading(true);
      const response = await dispatch(getItenerys(haveTripDetails?.id));
      if (response?.payload?.status === 200) {
         const popupDataId = eventPopup?.id;
         const previousTripIteneraries = response?.payload?.data?.data;
         const isIteneraryPresent = previousTripIteneraries?.some(
            (itinerary) => itinerary?.location?.id === popupDataId
         );
         if (isIteneraryPresent) {
            setLoading(false);
            setShow(false);
            toast.error("This location is already present in itineraries");
         } else {
            const data = {
               tripId: haveTripDetails?.id,
               locationId: popupDataId,
            };
            const _addItenery = async () => {
               const response_ = await dispatch(addItenery(data));
               if (response_?.payload?.status) {
                  setLoading(false);
                  navigate(`/trip-detail/${haveTripDetails?.id}`);
                  setShow(false);
                  toast.success(response_?.payload?.data?.message);
                  // setCategory(null);
               }
            };
            _addItenery();
         }
      }
   };

   const bookMarkOnClick = async () => {
      const response = await dispatch(addBookmark(eventPopup?.id));
      if (response?.payload?.data?.status) {
         if (isFrom === "home") {
            dispatch(
               updateIsBookmarkHome({
                  location_id: eventPopup?.id,
                  category: eventPopup?.category,
               })
            );
         } else if (isFrom === "activity") {
            dispatch(
               updateIsBookmarkEvent({
                  location_id: eventPopup?.id,
                  category: eventPopup?.category,
               })
            );
         }
         toast.success(response?.payload?.data?.message);
         setShow(false);
      }
      if (!response?.payload?.data?.status) {
         toast.error(response?.payload?.data?.message);
         setShow(false);
      }
   };
   return (
      <Modal
         show={show}
         onHide={() => {
            setShow(false);
            setIsItenerary(false);
         }}
         keyboard={false}
         centered
         className="view-details-modal"
      >
         <Modal.Body className="p-0">
            <Icon
               className="position-absolute modal-close-btn cursor-pointer  d-md-none d-block"
               onClick={() => {
                  setShow(false);
                  setIsItenerary(false);
               }}
               name={"Close"}
            />
            <img
               className="product-image"
               src={
                  eventPopup?.locationimage_set?.[0]
                     ? eventPopup?.locationimage_set?.[0]?.image
                     : noImage
               }
               alt=""
            />
            {eventPopup?.locationimage_set?.[0]?.is_representational && (
               <p className="is-representational container text-center position-absolute text-light fst-italic">
                  For Representational Purpose
               </p>
            )}
            <div className="gotokugi-temple-header px-4 py-3">
               {!isBookmark && (
                  <>
                     {!eventPopup?.is_bookmarked ? (
                        <img
                           className="bookmark-icon-event"
                           onClick={() => {
                              if (authData?.isAuthenticated) {
                                 bookMarkOnClick();
                              } else {
                                 navigate("/login");
                              }
                           }}
                           src={bookmarkIcon}
                           alt=""
                        />
                     ) : (
                        <Icon
                           className="bookmark-icon-event cursor-none"
                           name={"Bookmarked"}
                        />
                     )}
                  </>
               )}
               <div className="gotokugi-temple-container position-relative">
                  <div className="gotokugi-temple-content">
                     <p className="goto-temple-p m-0">
                        {eventPopup?.name_of_location}
                     </p>
                     <p className="text-muted m-0">
                        {eventPopup?.type_of_event}
                     </p>
                  </div>
                  <div className="location-calender">
                     <div className="location-div">
                        <div className="location-calender-subdiv">
                           <img src={locationIcon} alt="" />
                        </div>
                        <div>
                           <p className="location-title-text m-0">
                              {eventPopup?.city?.name}
                           </p>
                           <p className="location-address-text m-0">
                              {eventPopup?.address}
                           </p>
                        </div>
                     </div>
                     <div className="calender-div ">
                        <div className="location-calender-subdiv">
                           <img src={calanderIcon} alt="" />
                        </div>
                        <div>
                           <p className="location-title-text m-0">
                              Date &amp; time
                           </p>
                           <p className="location-address-text m-0">
                              {eventPopup?.open_timings}
                           </p>
                        </div>
                     </div>
                  </div>
                  <p className="description-text">{eventPopup?.description}</p>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer className="d-flex align-items-center justify-content-center border-0 py-0">
            <button
               disabled={loading}
               onClick={() => {
                  if (haveTripDetails) {
                     addToItenery();
                  } else {
                     setShow(false);
                     setIsItenerary(true);
                     if (authData?.isAuthenticated) {
                        setTripTypeModalShow(true);
                     } else {
                        navigate("/login");
                     }
                  }
               }}
            >
               Proceed
               {loading && (
                  <span
                     className="spinner-border spinner-border-sm ms-2"
                     role="status"
                     aria-hidden="true"
                  />
               )}
            </button>
         </Modal.Footer>
      </Modal>
   );
}
